import React, { Component } from 'react';
//import { Link } from "react-router-dom";
import { compose } from 'recompose';
import { AuthUserContext } from '../Session';
import withStyles from '@material-ui/core/styles/withStyles';
import * as ROUTES from '../../constants/routes';

import { withFirebase } from '../Firebase';
import LoadingProgress from '../Modules/LoadingProgress';

import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import {IsMobileDevice} from '../Modules/IsMobileDevice';

import Card from '@material-ui/core/Card';

import Stripe from 'stripe';

// MUI stuff
// MUI stuff
import Grid from "@material-ui/core/Grid";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import { Link } from "react-router-dom";
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import WidgetsIcon from '@material-ui/icons/Widgets';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
//import logo from "../../images/rockyai-logo-image-only.png";

//import Card from '@material-ui/core/Card';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { SetAgentStatus } from '../Routine/SetAgentStatus';
import RockyIcon from '../Modules/RockyIcon';
import ForumIcon from '@material-ui/icons/Forum';

// Modules for parameter change

import SettingContentBase from '../Admin/Modules/SettingContentBase';
import SettingColorPicker from '../Admin/Modules/SettingColorPicker';
import SettingContentActive from '../Admin/Modules/SettingContentActive';
import SettingContentNumber from '../Admin/Modules/SettingContentNumber';
import SettingContentArray from '../Admin/Modules/SettingContentArray';
//import SettingContentImage from '../Admin/Modules/SettingContentImage';
import SettingContentUploadImage from '../Admin/Modules/SettingContentUploadImage';
import SettingContentRichText from '../Admin/Modules/SettingContentRichText';
//import SettingContentArraySelect from '../Admin/Modules/SettingContentArraySelect';
import SettingContentArrayCheckbox from '../Admin/Modules/SettingContentArrayCheckbox';
import SettingContentSelectCombi from '../Admin/Modules/SettingContentSelectCombi';

import SettingContentSelect from '../Admin/Modules/SettingContentSelect';
import SettingContentMapSelect from '../Admin/Modules/SettingContentMapSelect';

const styles = theme => ({
	desktopPadding: {
	 	minHeight: 40,
	},
	buttonCenter: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-around",
		alignItems: "center",
		marginBottom: 15,
    },
});


const clientSecret = 'sk_live_A1l2VRa04mPPRE5wLHK3TWTx009Vxltsqq'
//const clientSecret = 'pk_live_UmgRVYFa2moTOxDNrpK6DpYy00xV16Ocp8'
const stripePromise = new Stripe(clientSecret)


class ManageShop extends Component {
    static contextType = AuthUserContext;
    _isMounted = false;

    constructor(props) {
        super(props);
        
        this.state = {
            open: false,
        };
    } 

    componentDidMount() {
        this._isMounted = true;
        this.loadShopProducts();
        //this.loadStripeProducts()
        //console.log(this.state)
    }
    componentWillUnmount(){
        this._isMounted = false;
    }
    
    loadShopProducts(){
        this.unsubscribe = this.props.firebase
        .collectionByName('shopArticles')
        .where('groupId', 'in', ['REMOTECOACH', "ROCKY"])
        .onSnapshot(snapshot => {
            let contentsProduction = {};
            let contentsTest = {};
            var content = {}
            const test = true;
            snapshot.forEach(doc =>{
                content = doc.data();
                console.log('content', content)
                if(this.context.roles?.includes('ADMIN') && this._isMounted){
                    if(test || this.context.programs?.includes(content.program) || content.program===undefined){
                        if(content.production === false){
                            contentsTest[doc.id] = { ...content, cid: doc.id }
                        }
                        else{
                            contentsProduction[doc.id] = { ...content, cid: doc.id };
                        }
                    } // end if true
                } // end of if ADMIN
                else if(this.context.roles?.includes('COACHADMIN') && this._isMounted ){
                    if(this.context.programs?.includes(content.program) && content.program !=='default'){
                        if(content.production === false){
                            contentsTest[doc.id] = { ...content, cid: doc.id }
                        }
                        else{
                            contentsProduction[doc.id] = { ...content, cid: doc.id };
                        }
                    }//end if program / o default
                } // end of if ADMIN
            });//end of snapshot
            if(this._isMounted){
                this.setState({
                    contentsProduction,
                    contentsTest,
                    loading: false,
                });
            }
            console.log('total', contentsProduction)
        });
    }

    loadStripeProducts = async () => {
        console.log('load stripeConnector')
        const product = await stripePromise.products.retrieve('prod_P97C1ZbVheXCcx');
        console.log('products', product, stripePromise)
    }

    addNewProduct = async () => {
        try {
            this.stripeConnector?.products?.create({
                name: 'test',
                description: 'tester',
                //images: [productImage],
                metadata: {
                    price: '99',
                },
            })
            .then(message => {
                console.log('response', message)
            })
            console.log('Product created:');
        } 
        catch (error) {
            console.error('Error creating product:', error);
        }
    }

    handleOpen = (contentId) => {
        this.setState({ openDialog: true, cid: contentId});
    };
    
    handleClose = () => {
        this.setState({ openDialog: false, contentId: ""});
    };
    
    render() {
        const { theme } = this.props;
        //console.log(this.state.reflectionInTimeRange,this.state.reflectionStatus )
        return (
            <div style={{margin:20}}>
                <Button
                    onClick={() => this.loadStripeProducts()}
                >
                    test
                </Button>
                <h4 style={theme.CardContent.titleText}>
                    Shop Live
                </h4>
                {this.renderShopItemDialogue()}
                {Object.keys(this.state.contentsProduction || {})?.length > 0
                &&
                    <GridList cellHeight={'auto'} cols={1} spacing={10} style={{overflow: 'hidden'}}>
                        {Object.keys((this.state.contentsProduction || {}))?.map((shopId, index) => {
                            const shopDict = this.state.contentsProduction[shopId]
                            return(
                                <div key={index+"main"}>
                                    {this.renderShopPreview(shopDict, shopId, index)}
                                </div>
                            )
                        })}
                    </GridList>
                }

                <h4 style={theme.CardContent.titleText}>
                    Shop Test
                </h4>
                {Object.keys(this.state.contentsTest || {})?.length > 0
                &&
                    <GridList cellHeight={'auto'} cols={1} spacing={10} style={{overflow: 'hidden'}}>
                        {Object.keys((this.state.contentsTest || {}))?.map((shopId, index) => {
                            const shopDict = this.state.contentsTest[shopId]
                            return(
                                <div key={index+"test"}>
                                    {this.renderShopPreview(shopDict, shopId, index)}
                                </div>
                            )
                        })}
                    </GridList>
                }
            </div>
        )
    }

    renderShopPreview(shopDict, shopId, index){
        const {theme} = this.props;
        return(
            <GridListTile 
                key={index} 
                style={{
                    ...theme.cardJournalDayHighlight, 
                    minWidth: 200,
                    listStyleType: 'none',

                }} 
                onClick={() => {this.handleOpen(shopId)}}
            >
                <div style={{
                    borderRadius: theme.borderRadius, padding: 16, marginBottom: 40, backgroundColor: shopDict.active ? theme.palette.secondary.main : 'darkgrey', textAlign: 'center', color: 'white'
                }}>
                    {shopDict.title}
                </div>
                <div style={{
                    padding: 16
                }}>
                    {shopDict.cid}
                    <br/>
                    {shopDict.stripeLinkId}
                </div>
            </GridListTile>
        )
    }

    renderShopItemDialogue(){
        const {theme} = this.props;
        const {loading, contentsProduction, contentsTest} = this.state;
        let content = {}
        if(this.state.cid?.length > 2){
            if(this.state.cid in contentsProduction && contentsProduction[this.state.cid] !== undefined){
                content = contentsProduction[this.state.cid] 
            }
            else if(contentsTest?.[this.state.cid]){
                content = contentsTest[this.state.cid] 
                //console.log('contentsTest',contentsTest, content)
            }
        }
        return(
            <Dialog
                open={this.state.openDialog}
                onClose={this.handleClose}
            >
                <DialogTitle >
                    <Grid container spacing={0} direction='row' justify="space-between" >
                        <Grid item >
                            <IconButton onClick={this.handleClose}>
                                <ArrowBackIcon />
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <span style={theme.dialogTitle.titleText}>
                                Manage Shop Items
                            </span>
                        </Grid>
                        <Grid item >
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent >
                    {this.state.openDialog && this.state?.cid?.length > 2
                    &&
                        <div>
                            <h5 style={styles.subtitle}>Content ({this.state.cid})</h5>
                            {loading && 
                                <LoadingProgress 
                                    defaultSet={this.props.defaultSet}
                                    type={'LoadingPageAnimated'} 
                                    loadingText={"many, many, data"} 
                                    firebaseConnectionSpeed={this.state.firebaseConnectionSpeed} 
                                    allowRefresh={true}
                                />
                            }
                    
                            {this.state.errorMessage !== '' && 
                                <h4 style={{color :'red'}}>{this.state.errorMessage}</h4>
                            }
                            {content?.cid?.length > 2
                            &&
                                <div>
                                    <p>General Setting</p>
                                    <SettingContentBase 
                                        dbUpdateLink={this.props.firebase.documentIdByCollection('shopArticles', this.state.cid)}
                                        emptyOption={true}
                                        title={"Name & Description"} 
                                        content={content} 
                                        params={['title', 'description']}
                                        labels={["Description", "Details"]}
                                    />
                                    <SettingContentSelect 
                                        dbUpdateLink={this.props.firebase.documentIdByCollection('shopArticles', this.state.cid)}
                                        title={"Billing type (internal)"} 
                                        content={content} 
                                        params={['billing']}
                                        selectOptions={['payed once','billed monthly','billed annually','billed every 3 months']}
                                    />
                                    <SettingContentUploadImage
                                        dbUpdateLink={this.props.firebase.documentIdByCollection('shopArticles', this.state.cid)}
                                        title={"Main Image (for single image use)"} 
                                        previewImageSize={true}
                                        content={content} 
                                        params={['imageUrl']}
                                        uploadDatabase={this.props.firebase.storageImages('Shop/'+this.state.cid)}
                                    />
                                    <p>In-App Settings</p>
                                    <SettingContentActive 
                                        dbUpdateLink={this.props.firebase.documentIdByCollection('shopArticles', this.state.cid)}
                                        title={"Active"} 
                                        content={content} 
                                        params={['active']}
                                        labels={["Published in the app"]}
                                    />
                                    <SettingContentActive 
                                        dbUpdateLink={this.props.firebase.documentIdByCollection('shopArticles', this.state.cid)}
                                        title={"Production / Livemode in Stripe"} 
                                        content={content} 
                                        access={false}
                                        params={['production']}
                                        labels={["Live mode in Stripe"]}
                                        infoText={["This setting cannot be overwritten. It depends on your Stripe Live vs. Test Mode secret key. It can be only tested by creator accounts."]}
                                    />
                                    <SettingContentSelect 
                                        dbUpdateLink={this.props.firebase.documentIdByCollection('shopArticles', this.state.cid)}
                                        title={"Position in Selection"} 
                                        content={content} 
                                        params={['position']}
                                        selectOptions={[1, 2, 3, 4, 5, 6]}
                                    />
                                    <SettingContentBase 
                                        dbUpdateLink={this.props.firebase.documentIdByCollection('shopArticles', this.state.cid)}
                                        emptyOption={true}
                                        title={"Default Price in USD and promotion discount text"} 
                                        content={content} 
                                        params={['price', 'promotion', 'details', 'comparison']}
                                    />
                                    <SettingContentArrayCheckbox
                                        dbUpdateLink={this.props.firebase.documentIdByCollection('shopArticles', this.state.cid)}
                                        title={"Is this item exclusive for a user group? (Optional)"} 
                                        content={content} 
                                        params={['requirements']}
                                        selectOptions={['default', 'executive', 'professional', 'seeker', 'student', 'teenager']}
                                        selectOptionsText={['Question for every situation', 'Executive/Entrepreneur', 'Professional/Employed', 'Available/Job-seeker', 'Student (Uni)', 'K-12/Teenager' ]}
                                    />
                                    <p>Stripe Settings</p>
                                    <SettingContentBase 
                                        dbUpdateLink={this.props.firebase.documentIdByCollection('shopArticles', this.state.cid)}
                                        title={"Stripe IDs"} 
                                        content={content} 
                                        params={['stripeProductId', 'stripeLinkId', 'id']}
                                        labels={['Stripe Product Id', 'Stripe Link Id', 'Stripe Item Id']}
                                    />
                                    <SettingContentBase 
                                        dbUpdateLink={this.props.firebase.documentIdByCollection('shopArticles', this.state.cid)}
                                        title={"Metadata coachcode, Meta storeid"} 
                                        content={content} 
                                        access={false}
                                        params={['groupId','storeId']}
                                    />
                                    {false 
                                    &&
                                        <>
                                            <SettingContentActive 
                                                dbUpdateLink={this.props.firebase.documentIdByCollection('shopArticles', this.state.cid)}
                                                title={"Premium Feature Access"} 
                                                content={content} 
                                                params={['premium']}
                                            />
                                            <SettingContentSelect 
                                                dbUpdateLink={this.props.firebase.documentIdByCollection('shopArticles', this.state.cid)}
                                                title={"Buyer category"} 
                                                content={content} 
                                                params={['category']}
                                                selectOptions={['enduser','coach']}
                                            />
                                            <SettingContentSelect 
                                                dbUpdateLink={this.props.firebase.documentIdByCollection('shopArticles', this.state.cid)}
                                                title={"Billing indication (user)"} 
                                                content={content} 
                                                params={['type']}
                                                selectOptions={['MONTHLY', 'YEARLY', 'PURCHASE', 'SEATS', '90 DAYS']}
                                            />
                                            <SettingContentSelect 
                                                dbUpdateLink={this.props.firebase.documentIdByCollection('shopArticles', this.state.cid)}
                                                title={"Button text"} 
                                                content={content} 
                                                params={['button']}
                                                selectOptions={['Subscribe Monthly', 'Subscribe Annually', 'Join Monthly', 'Join Annually', 'Buy Chats', 'Buy Seats', 'Allow to Link', 'Subscribe for 90 Days', 'Join for 90 Days', 'Subscribe for 90 Days', 'Join for 12 Months']}
                                            />
                                            <SettingContentSelect 
                                                dbUpdateLink={this.props.firebase.documentIdByCollection('shopArticles', this.state.cid)}
                                                title={"Store"} 
                                                content={content} 
                                                params={['store']}
                                                selectOptions={['Mobile','Stripe', 'Paytm', 'Bluesnap', 'accountLinking']}
                                            />
                                        </>
                                    }
                                </div>
                            }
                        </div>
                    }
                </DialogContent>
            </Dialog> 
        )
    }
}

export default compose(
    withFirebase,
    withStyles(styles, { withTheme: true})
)(ManageShop);
