import React, { Component } from 'react';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
//import {IdeasSection} from '../Charts/MindfulnessScore';

import Typist from 'react-typist';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionActions';
//import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import '../Routine/TypingIndicator.css'

import SnackbarInfo from '../Modules/SnackbarInfo';

import InfiniteContent from '../Content/InfiniteContent';
//import Button from '@material-ui/core/Button';
import DialogueStages from '../Routine/DialogueStages'; 

//import FormControlLabel from '@material-ui/core/FormControlLabel';
import InfoIcon from '@material-ui/icons/Info';
import CachedIcon from '@material-ui/icons/Cached';
import ReplayIcon from '@material-ui/icons/Replay';

import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import DialogueHistory from '../Dialogues/DialogueHistory';
import ThumbsUpDownIcon from '@material-ui/icons/ThumbsUpDown';
import logoRocky from "../../images/rockyai-logo-image-only.png";

import {ConvertTimestampToDateString} from '../Modules/ConvertTimestampToDateString';
import ContentSuggestCarousel from '../Content/ContentSuggestCarousel';

//// AWS Polly
import {S3, Polly} from 'aws-sdk';
import {Howl} from 'howler';
//import { maxWidth } from '@material-ui/system';
//import { ChangeHistory } from '@material-ui/icons';
//import {PollyClient} from "@aws-sdk/client-polly";
//import {S3Client, HeadObjectCommand} from '@aws-sdk/client-s3';
//import * as AWS from "@aws-sdk/client-s3";
/***
import { Polly, PollyClient, DeleteLexiconCommand , StartSpeechSynthesisTaskCommand} from "@aws-sdk/client-polly";
import { fromCognitoIdentity } from "@aws-sdk/credential-providers"; // ES6 import

import {CreateObjectId16TS} from '../Modules/CreateObjectId16TS';
import Stream from 'stream';

    "aws-sdk": "^2.998.0",
    "@aws-sdk/client-polly": "^3.36.0",
    "@aws-sdk/client-s3": "^3.36.0",
    "@aws-sdk/client-polly": "^3.34.0",
    "@aws-sdk/credential-providers": "^3.34.0",
    "speaker": "^0.5.3",
    "stream": "0.0.2",
    "aws-sdk": "^2.998.0",
 */

const ignoreTexts = ['skip', 'next', 'Repeat', 'Change Question', 'Retry Question'];

const styles = theme => ({
    botActive: {
        //color: theme.palette.black,
        color: theme.palette.white,
        display: 'inline-block',
        fontSize: 18,
        overflow: 'auto',
        //fontWeight: 'bold'
    },
    cardLoading: {
        //backgroundColor: theme.palette.themeLightGrey,
        //backgroundColor: theme.palette.themeDarkBlue,
        backgroundColor: theme.palette.darkMode? theme.palette.backPrimary : theme.palette.backTertiary,
        color: theme.palette.white,
        borderTopRightRadius: 15,
        borderBottomRightRadius: 15,
        borderBottomLeftRadius: 15,
        marginTop: 2,
        //borderTopLeftRadius: 15,
        borderTopRightRadius: 15,
        borderBottomRightRadius: 15,
        borderBottomLeftRadius: 15,
        marginBottom: 2,
        display: 'inline-block',
        maxWidth: '90%',
    },
    bot: {
        //color: theme.palette.black,
        color: theme.palette.white,
        textAlign: 'left',
        display: 'inline-block',
        fontSize: 18,
        overflow: 'auto',
        whiteSpace: 'pre-wrap'
    },
    user: {
        //color: 'white',
        color: theme.palette.themeLightGrey,
        textAlign: 'left',
        display: 'inline-block',
        fontSize: 18,
        overflow: 'auto',
        whiteSpace: 'pre-wrap'
    },
    cardBot: {
        //backgroundColor: theme.palette.themeLightGrey,
        //backgroundColor: theme.palette.themeDarkBlue,
        backgroundColor: theme.palette.darkMode? theme.palette.backPrimary : theme.palette.backTertiary,
        //color: theme.palette.white,
        maxWidth: '90%',
        marginTop: 2,
        //borderTopLeftRadius: 15,
        borderTopRightRadius: 15,
        borderBottomRightRadius: 15,
        borderBottomLeftRadius: 15,
        marginBottom: 2,
        display: 'inline-block',
    },
    cardBotSpecial: {
        backgroundColor: theme.palette.darkMode? theme.palette.backPrimary : theme.palette.backTertiary,
        maxWidth: '100%',
        marginTop: 2,
        //borderTopLeftRadius: 15,
        borderTopRightRadius: 15,
        borderBottomRightRadius: 15,
        borderBottomLeftRadius: 15,
        marginBottom: 2,
        display: 'inline-block',
    },
    cardUser: {
        backgroundColor: theme.palette.secondary.main,
        //backgroundColor: theme.palette.backTertiary+'22',
        //backgroundColor: 'transparent',
        //border: '1px solid',
        //borderColor: theme.palette.backTertiary,
        marginTop: 2,
        //borderRadius: 15,
        borderTopLeftRadius: 15,
        borderTopRightRadius: 15,
        borderBottomLeftRadius: 15,
        marginBottom: 2,
        display: 'inline-block',
        textAlign: 'left',
        marginRight : 0,
        maxWidth: '90%',
    },
    cardPadding: {
        color: theme.palette.white,
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 20,
        paddingRight: 20,
        "&:last-child": {
            paddingBottom: 10
        },
    },
    expansionPanel : {
        background: 'transparent',
        border: 'none',
        margin: 0,
        padding: 0,
        boxShadow: 'none',
    },
    logoCaption: {
        marginBottom: 4,
        marginTop: 0,
        marginLeft: 0,
        marginRight: 6,
        maxHeight: 30,
        maxWidth: 30,
        borderRadius: 6
    }
})

const pollyVoicePool = {
    default : {
        pollyVoiceId: "Arthur",
        pollyVoiceSet: "en-GB/Arthur/",
        name: "Chap"
    },
    Champ : {
        pollyVoiceId: "Stephen",
        pollyVoiceSet: "en-US/Stephen/",
        name: "Champ"
    },
    Matthew : {
        pollyVoiceId: "Matthew",
        pollyVoiceSet: "en-US/Matthew/",
        name: "Champ"
    },
    Maven : {
        pollyVoiceId: "Ruth",
        pollyVoiceSet: "en-US/Ruth/",
        name: "Maven"
    },
    Sheila : {
        pollyVoiceId: "Olivia",
        pollyVoiceSet: "en-AU/Olivia/",
        name: "Sheila"
    },
    Chap : {
        pollyVoiceId: "Arthur",
        pollyVoiceSet: "en-GB/Arthur/",
        name: "Chap"
    },
    Belle : {
        pollyVoiceId: "Niamh",
        pollyVoiceSet: "en-IE/Niamh/",
        name: "Belle"
    },
    Yaar : {
        pollyVoiceId: "Kajal",
        pollyVoiceSet: "en-IN/Kajal/",
        name: "Yaar"
    }
}

class ChatBlock extends Component {
    _isMounted = false;
    _howlIndex = 0;
    _howlers = [];
    _typingBotFinishTypingNow = false;
    pollyVoiceId = pollyVoicePool.default.pollyVoiceId;
    pollyVoiceSet = pollyVoicePool.default.pollyVoiceSet;

    constructor(props) {
        super(props);
        if(this.props.allowAudio){
            //console.log('open props')
            this.setAWSs3();
            this.releaseAudioHowl();
        }
        //this.createAudioContext();
        if(this.props.user?.features?.voiceId?.length > 0){
            this.setVoiceId(this.props.user.features.voiceId);
        }
        var muteAudio = false;
        if (this.props.allowAudio!==true) {
            //console.log('open mute 1')
            muteAudio = true;
        }
        else if (this.props.user === undefined) {
            //console.log('open mute 3')
            muteAudio = true;
        }
        else if (this.props.user?.muteAudio === true){
            //console.log('open mute 2')
            muteAudio = true;
        }
        var typingBotFinishTypingNow = false;
        if(this.props.user?.dialogueSettings?.features?.fastmode === true || this.props.typingBotFinishTypingNow === true){
            typingBotFinishTypingNow = true;
        }
        var expanded = false;
        if(this.props.autoHideHistory !== undefined && this.props.autoHideHistory === false){
            expanded = true;
        }
        //console.log('props start chat', expanded, this.props.autoHideHistory, this.props)
        this.state= {
            expanded: expanded,
            messages: null,
            textLoading: "...",
            muteAudio,
            showContentSelection: (this.props.showContentSelection || false),
            typingBotFinishTypingNow: (typingBotFinishTypingNow),
            lastTextBlockingState: 'loading',
            showFeedback: 0,
            showTipsItems: [],
            spokenTextSamples: []
        }
    }

    setVoiceId(newVoiceId){
        //console.log('change VoiceId', newVoiceId)
        if(newVoiceId?.length > 2 && pollyVoicePool[newVoiceId]?.name === newVoiceId){
            this.pollyVoiceId = pollyVoicePool[newVoiceId].pollyVoiceId;
            this.pollyVoiceSet = pollyVoicePool[newVoiceId].pollyVoiceSet;
            //console.log('set VoiceId', newVoiceId)
        }
    }

    setAWSs3(){
        try{
            //this.AWS_Polly = new AWS.Polly();
                    /***
             */
            var region = "eu-central-1";
            //var endpoint = 'polly.app.rocky.ai.s3.amazonaws.com';
            var bucket = 'polly.app.rocky.ai';
            //// AWS IAM 
            var accessKeyId = 'AKIA4FGQ7JWOTA2IMEHG';
            var secretAccessKey = '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';
    
            this.AWS_Polly = new Polly({ 
            //this.AWS_Polly = new AWS.Polly({ 
            //this.AWS_Polly = new PollyClient({ 
                region: region,
                signatureVersion: 'v4',
                credentials:{
                    accessKeyId: accessKeyId,
                    secretAccessKey: secretAccessKey
                }
            });
            this.AWS_S3 = new S3( {
            //this.AWS_S3 = new AWS.S3( {
            //this.AWS_S3 = new S3Client( {
                bucket: bucket,
                signatureVersion: 'v4',
                region: region,
                credentials:{
                    accessKeyId: accessKeyId,
                    secretAccessKey: secretAccessKey
                }
            } );
        }
        catch (e) {
            console.log('error AWS', e)
        } 
    }

    componentDidMount() {
        this._isMounted = true;
        let spokenTextSamples = this.props.spokenTextSamples || [];
        this.setState({spokenTextSamples});
        if(this.props.typingBotFinishTypingNow !== undefined && this.props.typingBotFinishTypingNow !== this._typingBotFinishTypingNow){
            if(this.props.user?.dialogueSettings?.features?.fastmode === true && this._isMounted){
                //console.log('fastmode active at start')
                this._typingBotFinishTypingNow = true;
                this.setState({typingBotFinishTypingNow: true});
            }
            else if(this._isMounted){
                this._typingBotFinishTypingNow = this.props.typingBotFinishTypingNow;
                this.setState({typingBotFinishTypingNow: this.props.typingBotFinishTypingNow});
            }
            //console.log('update',this._typingBotFinishTypingNow, this.props.typingBotFinishTypingNow )
        }
        if(this.props.allowAudio && this.audioContext !== undefined && this._typingBotFinishTypingNow !== true){
            this.audioContext.resume().then(() => {
                console.log('fired resum')
            });
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
        if(this.audioContext !== undefined){
            //console.log('close audio context')
            //this.audioContext.close();
        }
        if(this.props.allowAudio){
            this.releaseAudioHowl();
        }
        //if(this.unsubscribeMessages!==undefined) this.unsubscribeMessages();
    }

    componentDidUpdate(prevProps) {
        if(this._isMounted ){
            try{
                if((this.props.chatMode === 'Coach' && this.props.messages!== null && this.props.messages!==undefined && this.props.messages.length>=1)
                    && (
                        !(this.state.messageList?.length > 0) 
                        || this.props.chatMode !== prevProps.chatMode 
                        //|| !(prevProps.messages?.length>0) 
                        || (this.props.messages.length !== (prevProps.messages?.length || 0)) 
                    )
                ){
                    var messageList = [];
                    //console.log('chatLoading', this.props)
                    if(this.props.chatMode === 'Coach'){
                        this.props.messages.map(messageItem => {
                            //// check if need to filter message mentor modes
                            if(messageItem?.utteranceType?.includes('mentor')){
                                var newMessageItem = Object.assign({}, messageItem);
                                var newText = []
                                var newUtter = []
                                var newType = []
                                messageItem.utteranceType.map( (messageType, mIndex) => {
                                    if(messageType === 'mentor'){
                                        if(this.state.mentormMessagesAvailable !== true){
                                            this.setState({mentorMessagesAvailable : true})
                                        }
                                    }
                                    else{
                                        newText.push(messageItem.text[mIndex])
                                        newUtter.push(messageItem.utteranceClass[mIndex])
                                        newType.push(messageItem.utteranceType[mIndex])
                                    }
                                })
                                newMessageItem['text'] = newText;
                                newMessageItem['utteranceClass'] = newUtter;
                                newMessageItem['utteranceType'] = newType;
                                messageList.push(newMessageItem);
                            }
                            else{
                                messageList.push(messageItem);
                            }
                        })
                        this.setState({messageList})
                    }
                    else{
                        var originialMessages = Object.assign({}, this.props.messages);
                        this.setState({messageList: originialMessages})
                    }
                }
            }
            catch(e){
                console.log('error update message', e)
            }
            if (prevProps.messages!==undefined && this.props.messages!==undefined  && (prevProps.messages.length !== this.props.messages.length) && this._isMounted) {
                var spokenTextSamples = this.state.spokenTextSamples || [];
                if(prevProps.messages.length > 1){
                    //console.log('prevProps.messages', prevProps.messages)
                    var usedTexts = prevProps.messages[prevProps.messages.length -1].text || [];
                    spokenTextSamples = spokenTextSamples.concat(usedTexts);
                    if(spokenTextSamples.length > 14){
                        spokenTextSamples = spokenTextSamples.slice(spokenTextSamples.length-8 ,spokenTextSamples.length)
                    }
                }
                var expanded = false;
                if(this.props.autoHideHistory === false){
                    if(this.state.expandedOverwrite){
                        expanded = this.state.expanded;
                    }
                    else{
                        expanded = true;
                    }
                }
                //console.log('props start update', expanded, this.props)
                this.setState({
                    allTextPresented: false,
                    expanded: expanded,
                    showFeedback: 0,
                    showTipsItems: [],
                    spokenTextSamples,
                    skipTurnsFlags: {"t0": false},
                    latestBlockNumber: -1,
                    contentToHide: undefined
                })
                //console.log('change propspspspsp')
            }
            if (this.props.user!==undefined && prevProps.user!==undefined && prevProps.user.muteAudio!==undefined && this.props.user.muteAudio!==undefined  && (prevProps.user.muteAudio !== this.props.user.muteAudio) && this._isMounted) {
                this.setState({
                    muteAudio: this.props.user.muteAudio
                })
                //console.log('changes status mute', this.props.user.muteAudio)
            }
            if (this.props.typingBotFinishTypingNow!==undefined && this.props.typingBotFinishTypingNow !== prevProps.typingBotFinishTypingNow && this._isMounted) {
                this._typingBotFinishTypingNow = this.props.typingBotFinishTypingNow;
                //console.log('this.props.typingBotFinishTypingNow', this.props.typingBotFinishTypingNow, this._typingBotFinishTypingNow)
                if(this.props.typingBotFinishTypingNow === true){
                    this.setState({
                        typingBotFinishTypingNow: true,
                    })
                    if(this.props.allowAudio){
                        this.releaseAudioHowl();
                    }
                }
                else if(this.props.typingBotFinishTypingNow === false){
                    this.setState({
                        typingBotFinishTypingNow: false,
                    })
                }
            }
            if(this.props.showContentSelection !== undefined && this.props.showContentSelection !== this.state.showContentSelection){
                //console.log('update state content', this.props.showContentSelection, this.props.contentSelection)
                this.setState({showContentSelection: this.props.showContentSelection})
            }
            if(this.props.user?.features?.voiceId?.length > 1 && this.props.user.features.voiceId !== prevProps?.user?.features?.voiceId){
                this.setVoiceId(this.props.user.features.voiceId);
            }
        }
        else{
            console.log('chatend', this.props)
        }
    }

    addSpokenSentenceList(text) {
        if(this._isMounted){
            let spokenTextSamples = (this.state.spokenTextSamples || this.props.spokenTextSamples || []);
            spokenTextSamples.push(text);
            //console.log('addibng spoken tex', text, text.length)
            //console.log('addibng spoken tex', text, spokenTextSamples, this.props.spokenTextSamples);
            if(spokenTextSamples.length > 15){
                spokenTextSamples = spokenTextSamples.slice(-14) 
            }
            this.setState({ spokenTextSamples });
            if(this.props.addSpokenSentenceList !== undefined){
                this.props.addSpokenSentenceList(spokenTextSamples);
            }
        }
    }

    render () {
        //console.log('status', this.props)
        const {classes, theme} = this.props;
        //console.log('style', classes, styles, theme)
        var messageList = [];
        ///// Handle the filtering  
        if(this.props.chatMode === 'Coach'){
            messageList = this.state.messageList || [];
        } 
        else if (this.props.messages!== null && this.props.messages!==undefined && this.props.messages.length>=1){
            messageList = [... this.props.messages]
        }
        if(this.props.variant === "chatLoading"){
            //console.log('chatLoading', this.props)
            return (
                <div key={'chatLoading'}>
                    <Card raised className={classes.cardLoading} >
                        <CardContent className={classes.cardPadding}>
                            <div className="typing-indicator">
                                <span style={{backgroundColor: theme.palette.secondary.light}}></span>
                                <span style={{backgroundColor: theme.palette.secondary.light}}></span> 
                                <span style={{backgroundColor: theme.palette.secondary.light}}></span>
                            </div>
                        </CardContent>
                    </Card>
                </div>
            )
        }
        else if(messageList.length>=1){
            if(this.props.variant === "chatEnd"){
                return (
                    <div key={'chatEnd'}>
                        {messageList && messageList.length > 2 &&
                            <>
                            {
                                this.turnHistoryBlocks(messageList.length-1)
                            }
                            </>
                        }
                        {this.props.showAvatar &&
                            this.returnAvatar()
                        }
                        { messageList && messageList.length > 2 && messageList[messageList.length-1].isCoach === true && 
                            this.turnSingleBlock(messageList.slice(-1), true, true)
                        }
                    </div>
                )
            }
            else if(this.props.variant === "chatIntro"){
                //console.log(this.props.messages.length, 'coach', this.props.messages[this.props.messages.length-1].isCoach )
                return (
                    <div key={'chatIntro'}>
                        {this.props.showAvatar &&
                            this.returnAvatar()
                        }
                        { messageList && messageList.map((m, index) => {
                            //console.log(messages)
                            if (messageList.length - 1 === index && m.isCoach) {
                                return this.turnAnimatedBlocks(m)
                            }
                            else{
                                return "";
                            }
                        })}
                    </div>

                )
            }
            else if(this.props.variant === "chatLastMessage"){
                //console.log('chatmain last', messageList.slice(-1))
                return (
                    <div key={'chatLastMessage'}>
                        {messageList && messageList.length >= 1
                            &&
                                this.turnSingleBlock(messageList.slice(-1))
                        }
                    </div>

                )
            }
            else if(this.props.variant === "chatLastTwoMessages"){
                //console.log('chatmain last', messageList.slice(-1))
                return (
                    <div key={'chatLastTwoMessages'}>
                        {messageList && messageList.length >= 2
                            ?   this.turnSingleBlock(messageList.slice(messageList.length-2, messageList.length-1))
                            :   messageList && messageList.length >= 1
                                ?   this.turnSingleBlock(messageList.slice(-1))
                                :   ''
                        }
                        {messageList && messageList.map((m, index) => {
                            //console.log('messageds in', m)
                            if (messageList.length - 1 === index && m.isCoach) {
                                return this.turnAnimatedBlocks(m, index)
                            }
                            else{
                                return "";
                            }
                        })}
                    </div>

                )
            }
            else if(this.props.noAnimation === true){
                return (
                    <div key={'noAnimation'}>
                        {messageList && messageList.length >= 3 &&
                            this.turnHistoryBlocks(messageList.length-2)
                        }
                        { messageList && messageList.length >= 2 && 
                            this.turnSingleBlock(messageList.slice(-2))
                        }
                        { messageList && messageList.length === 1 && 
                            this.turnSingleBlock(messageList)
                        }
                    </div>
                )
            }
            else if(this.props.variant === "chatMain"){
                return (
                    <div key={'chatMain'}>
                        {this.state.mentorMessagesAvailable && this.props.chatMode !== 'Mentor' && this.state.allTextPresented
                            && 
                            <SnackbarInfo 
                                openAuto = {true}
                                alertText = 'Want more answers? Switch to the Mentor mode tab.'
                                alertName = 'chatmentor'
                                userId = {this.context.uid}
                            />
                        }
                        {messageList && messageList.length > 2 && 
                            <>
                                {messageList[messageList.length-1].isCoach === true
                                    ?   this.turnHistoryBlocks(messageList.length-2)
                                    :   this.turnHistoryBlocks(messageList.length-1)
                                }
                            </>
                        }
                        {messageList && messageList.length >=2 && messageList[messageList.length-2].isCoach !== true
                            ?   this.turnSingleBlock(messageList.slice(messageList.length-2, messageList.length-1))
                            :   messageList && messageList.length >=1 && messageList[messageList.length-1].isCoach !== true 
                                ?   this.turnSingleBlock(messageList.slice(-1))
                                :   ''
                        }
                        <br/>
                        {(this.state.latestBlockNumber >= 0 || this.state.allTextPresented) && this.props.user !== undefined && this.props.showRecommendations === true 
                            && this.props.recommendationSelection?.length > 0
                            &&
                            <div style={{paddingBottom: 20, paddingTop: 0, marginLeft: 0, width: '90%', position: 'relative'}}>
                                <div style={{fontSize: 12, top:-10, textAlign: 'left', color: theme.palette.secondary.light, position: 'absolute'}}>
                                    Tips
                                </div>
                                <ContentSuggestCarousel
                                    user={this.props.user}
                                    contentSelection={this.props.recommendationSelection || undefined}
                                    leadershipCategories={this.props.leadershipCategories}
                                    leadershipQualities={this.props.leadershipQualities}
                                    messageSubmit = {(text) => {this.submitInspiration(text)}}
                                    closeAfterActionAdded = {true}
                                />
                            </div>
                        }
                        {this.props.showAvatar &&
                            this.returnAvatar()
                        }
                        {this.props.hideResponse!== true && messageList && messageList.map((m, index) => {
                            //console.log('messageds in', m)
                            if (messageList.length - 1 === index && m.isCoach) {
                                return this.turnAnimatedBlocks(m, index)
                            }
                            else{
                                return "";
                            }
                        })}
                    </div>
                )
            }
            else{
                return (
                    <div key={'any'}>
                        {messageList && messageList.length > 2 &&
                            this.turnHistoryBlocks(messageList.length-1)
                        }
                        { messageList && messageList.map((m, index) => {
                            //console.log('iscoach', m)
                            if (messageList.length - 1 === index && m.isCoach) {
                                return this.turnAnimatedBlocks(m, index)
                            } 
                            else{
                                return "";
                            }
                        })}
                    </div>
                )

            }
        }
        else{
            return ""
        }
    }

    returnAvatar() {
        const {classes, theme} = this.props;
        return(
            <div style={{display:"flex", alignItems:"center", verticalAlign:'middle'}}>
                <img
                    height={30}
                    width={30}
                    src={this.props.defaultSet?.programLogo?.length > 10 ? this.props.defaultSet?.programLogo : logoRocky}
                    alt="Coach"
                    className={classes.logoCaption}
                >
                </img>
                <strong style={{color: theme.palette.secondary.light}}>
                    {this.props.defaultSet?.programAvatarName?.length > 2 
                        ?   this.props.defaultSet.programAvatarName
                        :   'Rocky'
                    }
                </strong>
            </div>
        )
    }

    turnAnimatedBlocks(m, position) {
        const {theme} = this.props;
        var textArray = m.text;
        const key = m.uid;
        const {classes} = this.props;
        textArray = textArray.filter(element => {
            return (element !== undefined && element !== null && element !== '');
        }); 
        var textLengthOffset = [0];
        var textLengthSpeech = [0];
        var speechText = [""];
        //console.log('textArray for message', m)
        //console.log(this._isMounted, 'props state', this.props, this.state)
        //console.log('loadibg keys', this._typingBotFinishTypingNow, this.state.typingBotFinishTypingNow)
        /////////////////////////
        var stageInformationCheckIndex = -1;
        var utteranceClasses = m?.utteranceClass || [];
        if(m?.text?.length > 0){
            // fill in empty classes in the front
            for(let i = 0; i < ( m?.text?.length - m?.utteranceClass?.length ); i++){
                utteranceClasses.unshift('')
            }
        }
        if(utteranceClasses?.length > 0){
            utteranceClasses.forEach((utteranceClass, indexU) => {
                //console.log('utteranceClass', index , position, stageInformationCheckIndex, (utteranceClasses.length), utteranceClasses)
                if(stageInformationCheckIndex >= 1) return false;
                else{
                    if(utteranceClass.includes('_check')) stageInformationCheckIndex = indexU;
                    else if(utteranceClass.includes('_inform')) stageInformationCheckIndex = indexU;
                    else if(utteranceClass.includes('_confirm')) stageInformationCheckIndex = indexU;
                    else if(utteranceClass.includes('ask_more')) stageInformationCheckIndex = indexU;
                    else if(utteranceClass.includes('ask_further')) stageInformationCheckIndex = indexU;
                    //else if(indexU === (utteranceClasses.length-1)) stageInformationCheckIndex = indexU;
                    //console.log('utteranceClass', utteranceClasses.length, utteranceClass, stageInformationCheckIndex)
                }
            })
        }
        //console.log('stageInformationCheckIndex', stageInformationCheckIndex , (utteranceClasses), textArray)
        //////////////////////// 
        return (
            <div key={key+'_'+position}>
                {textArray.map((message, index) => {
                    /***
                    if(this._typingBotFinishTypingNow === true){
                        textLengthOffset[(index+1)] = 0 ;
                    }
                    else  */
                    if(this.state.muteAudio !== true && message.length < 400){
                        textLengthOffset[(index+1)] = textLengthOffset[index]+message.length*60+1000+500 ;
                        textLengthSpeech[index] = message.length;
                        speechText[index] = "";
                        //console.log('added slow')
                    }
                    else if(message.length >= 400){
                        speechText[index] = ""+message;
                        if (speechText[index].length >= 400){
                            speechText[index] = speechText[index].substr(0, 400);
                            var indexPunctuation = speechText[index].lastIndexOf(". ");
                            if(indexPunctuation > 60){
                                speechText[index] = speechText[index].substr(0, indexPunctuation);
                            }
                        }
                        if(this.state.muteAudio !== true){
                            textLengthOffset[(index+1)] = textLengthOffset[index]+speechText[index].length*60+1000+500 ;
                        }
                        else{
                            textLengthOffset[(index+1)] = textLengthOffset[index]+speechText[index].length*20+1000 ;
                        }
                        textLengthSpeech[index] = speechText[index].length;
                        //textLengthOffset[(index+1)] = textLengthOffset[index]+message.length*20+1000 ;
                        //console.log('added fasteext')
                    }
                    else{
                        textLengthOffset[(index+1)] = textLengthOffset[index]+message.length*30+1000 ;
                        textLengthSpeech[index] = message.length;
                        speechText[index] = "";
                        //console.log('added faster')
                    }
                    //console.log('position time offset', message, index)
                    return(
                        <div key={"turnout_"+index}>
                        {message !== undefined && message.length > 1
                            ?
                                index < (textArray.length - 1)
                                ?
                                    <div key={"turnin_"+index} style={{position: 'relative', padding:0, margin: 0}}
                                        onClick={() => {
                                            if(this.state.showFeedback !== (index+1) && this._isMounted) {
                                                this.setState({showFeedback: index+1, showTipsItems: [index+1].concat(this.state.showTipsItems || [])})
                                            }
                                            //console.log('click f   ', index+1)
                                        }}
                                    >
                                        {(this.state?.skipTurnsFlags?.["t"+index] === true || this._typingBotFinishTypingNow === true || this.state.typingBotFinishTypingNow || index < this.state.latestBlockNumber)
                                            ?   
                                                <div key={key+'finished_pre'}>
                                                    {true && 
                                                        //this.props.allowInteraction && stageInformationCheckIndex >=0 && (index === (this.state.latestBlockNumber) || this.state.latestBlockNumber > textArray.length ) 
                                                        this.props.allowInteraction && stageInformationCheckIndex >=0 && (index === (stageInformationCheckIndex) ) 
                                                        ///// Dialogue stages moved back to routine on top
                                                        ?
                                                            <div style={{maxWidth: "90%"}}>
                                                                <DialogueStages
                                                                    chatPaddingProfile = {true}
                                                                    showCompleted = {false}
                                                                    background = {"transparent"}
                                                                    marginBottom = {10}
                                                                    stages = {this.props.dialogue?.dialogueStage || undefined}
                                                                    message = {(message) => {this.setState({ dialogueStagesMessage: message})}}
                                                                />
                                                            </div>
                                                        :
                                                            <div></div>
                                                    }
                                                    <Card className={classes.cardBot} >
                                                        <CardContent className={classes.cardPadding}>
                                                            <span className={classes.bot}>{message}</span>
                                                        </CardContent>
                                                    </Card>
                                                </div>
                                            :
                                                <Typist
                                                    key={key+'pre'}
                                                    avgTypingDelay={this.state.skipTurnsFlags?.["t"+index] === true ? 0 : 10}
                                                    stdTypingDelay={10}
                                                    cursor={{ show: false }}
                                                    onLineTyped={(line, lineIdx) => {
                                                        if (lineIdx === 0 && this._isMounted){
                                                            if(this.state.latestBlockNumber === undefined || index > this.state.latestBlockNumber){
                                                                this.setState({latestBlockNumber: index})
                                                            }
                                                            //console.log('typerd', line, lineIdx)
                                                            if(this._typingBotFinishTypingNow !== true){
                                                                this.setState({allTextPresented: false})
                                                                if(this.props.showTextInputHandler !== undefined && this.props.showTextInputHandler !== false){
                                                                    this.props.showTextInputHandler();
                                                                }
                                                            }
                                                            if(this.state.lastTextBlockingState!=='loading'){
                                                                this.setState({lastTextBlockingState:'loading'})
                                                            }
                                                            if(this.props.typingMoveOnHandler!== undefined){
                                                                this.props.typingMoveOnHandler();
                                                            }
                                                            var spokenTextSamples = this.state.spokenTextSamples || [];
                                                            if (!spokenTextSamples.includes(message) && this.props.allowAudio && lineIdx === 0 && this.state.muteAudio!== true  && this._typingBotFinishTypingNow !== true && this.props.typingBotFinishTypingNow !== true) {
                                                                if(message.length < 400){
                                                                    this.textToSpeech(message, true);
                                                                }
                                                                else if(speechText[index]?.length > 2){
                                                                    this.textToSpeech(speechText[index], true);
                                                                    this.addSpokenSentenceList(message);
                                                                }
                                                                else{
                                                                    this.addSpokenSentenceList(message);
                                                                }
                                                            }
                                                            else if (spokenTextSamples.includes(message)){
                                                                //console.log('already included 44', message, spokenTextSamples);
                                                            }
                                                            else{
                                                                this.addSpokenSentenceList(message);
                                                            }
                                                        }
                                                    }}
                                                    onCharacterTyped={(char, chrIdx) => {
                                                        //console.log('char change', char, chrIdx, textLengthSpeech[index], index);
                                                        if(textLengthSpeech[index] > 30 && chrIdx >= textLengthSpeech[index]){
                                                            //console.log('char state', char, "t"+index);
                                                            var skipTurnsFlags = this.state.skipTurnsFlags || {};
                                                            skipTurnsFlags["t"+index] = true;
                                                            this.setState({skipTurnsFlags},
                                                                () => {
                                                                    if(this.props.typingMoveOnHandler!== undefined){
                                                                        this.props.typingMoveOnHandler();
                                                                    }
                                                                }
                                                            )
                                                        }
                                                        else if (chrIdx> 0 && chrIdx% 80 === 0){
                                                            //console.log('line change', chrIdx);
                                                            if(this.props.typingMoveOnHandler!== undefined){
                                                                this.props.typingMoveOnHandler();
                                                            }
                                                        }
                                                    }}
                                                    onTypingDone={ () => {
                                                        if(this._isMounted){
                                                            if(this.state.latestBlockNumber === undefined || index >= this.state.latestBlockNumber){
                                                                this.setState({latestBlockFinished: index})
                                                            }
                                                        }
                                                    }}
                                                >
                                                    <Typist.Delay ms={(textLengthOffset[index])} />
                                                    {true && 
                                                        //this.props.allowInteraction && stageInformationCheckIndex >=0 && (index === (this.state.latestBlockNumber) || this.state.latestBlockNumber > textArray.length ) 
                                                        this.props.allowInteraction && stageInformationCheckIndex >=0 && (index === (stageInformationCheckIndex) ) 
                                                        ///// Dialogue stages moved back to routine on top
                                                        ?
                                                            <div style={{maxWidth: "90%"}}>
                                                                <DialogueStages
                                                                    chatPaddingProfile = {true}
                                                                    showCompleted = {false}
                                                                    background = {"transparent"}
                                                                    marginBottom = {10}
                                                                    stages = {this.props.dialogue?.dialogueStage || undefined}
                                                                    message = {(message) => {this.setState({ dialogueStagesMessage: message})}}
                                                                />
                                                            </div>
                                                        :
                                                            <div></div>
                                                    }
                                                    <Card className={classes.cardBot} 
                                                            onClick={() => {
                                                                if(this.state.showFeedback !== (index+1) && this._isMounted) {
                                                                    this.setState({showFeedback: index+1, showTipsItems: [index+1].concat(this.state.showTipsItems || [])})
                                                                }
                                                                //console.log('click z  ', index+1)
                                                            }} 
                                                        >
                                                        <CardContent className={classes.cardPadding}>
                                                            <span className={classes.bot}>{message}</span>
                                                        </CardContent>
                                                    </Card>
                                                    <br/>
                                                    {false && ((index === 0))
                                                        && this.props.user !== undefined && this.props.showRecommendations === true 
                                                        && this.props.recommendationSelection?.length > 0
                                                        &&
                                                        <div style={{paddingBottom: 20, paddingTop: 20, marginLeft: 0, width: '90%', position: 'relative'}}>
                                                            <div style={{fontSize: 12, top:-10, textAlign: 'left', color: theme.palette.secondary.light, position: 'absolute'}}>
                                                                Tips
                                                            </div>
                                                            <ContentSuggestCarousel
                                                                user={this.props.user}
                                                                contentSelection={this.props.recommendationSelection || undefined}
                                                                leadershipCategories={this.props.leadershipCategories}
                                                                leadershipQualities={this.props.leadershipQualities}
                                                                messageSubmit = {(text) => {this.submitInspiration(text)}}
                                                                closeAfterActionAdded = {true}
                                                                contentOpened = {
                                                                    this.props.contentOpened === undefined
                                                                        ?  undefined
                                                                        :  (id) => {this.props.contentOpened(id)}
                                                                    }
                                                                />
                                                        </div>
                                                    }
                                                </Typist>
                                        }
                                        <div style={{padding:0, margin: 0}}>
                                            {this.props.allowInteraction && (this.state.allTextPresented || this._typingBotFinishTypingNow === true || this.state.typingBotFinishTypingNow) 
                                                ?
                                                    <div style={{padding:0, margin: 0}}>
                                                        {this.renderInteraction(m, index, false, true)}
                                                    </div>
                                                :   this.props.allowFeedback && this.props.variant==="chatMain" && (this.state.allTextPresented || this._typingBotFinishTypingNow === true || this.state.typingBotFinishTypingNow)
                                                    ?   
                                                        <div style={{padding:0, margin: 0}}>
                                                            {this.renderInteraction(m, index, false, true)}
                                                        </div>
                                                    :   ''
                                            }
                                        </div>
                                    </div>
                                : 
                                    <div style={{position: 'relative'}}
                                        onClick={() => {
                                                if(this.state.showFeedback !== (index+1) && this._isMounted){ 
                                                    this.setState({showFeedback: index+1, showTipsItems: [index+1].concat(this.state.showTipsItems || [])})
                                                }
                                                //console.log('click', index+1)
                                            }}
                                        >
                                        {this.props.allowInteraction
                                            ?
                                                <div style={{
                                                    display: (this.state.latestBlockFinished===(textArray.length - 2) ||  this.state.allTextPresented || this._typingBotFinishTypingNow === true || this.state.typingBotFinishTypingNow) ? 'inherit' : 'none'
                                                    }}
                                                >
                                                    {this.renderLastItemContent(m, index, true)}
                                                </div>
                                            :   this.props.allowFeedback && this.props.variant==="chatMain" 
                                                ?   
                                                    <div style={{
                                                        display: (this.state.latestBlockFinished===(textArray.length - 2) || this.state.allTextPresented || this._typingBotFinishTypingNow === true || this.state.typingBotFinishTypingNow) ? 'inherit' : 'none'
                                                        }}
                                                    >
                                                        {this.renderLastItemContent(m, index, true)}
                                                    </div>
                                                :   ''
                                        }
                                        {((this._typingBotFinishTypingNow === true || this.state.typingBotFinishTypingNow || index < this.state.latestBlockNumber) )
                                            //&& this.state.lastTextBlockingState !== 'running' && this.state.lastTextBlockingState !== 'finished'
                                            ?   
                                                <div key={key+'finished_last_quick'}>
                                                    <Card 
                                                        //raised 
                                                        className={classes.cardBot} >
                                                        <CardContent className={classes.cardPadding}>
                                                            <span className={classes.botActive}>
                                                            <span style={{ display:'inline-flex'}}>{message}
                                                            <Typist
                                                                key={key+'donepre'}
                                                                avgTypingDelay={0}
                                                                stdTypingDelay={0}
                                                                style={{marginTop: 2, display:'inline-flex'}}
                                                                onLineTyped={(line, lineIdx) => {
                                                                    if (lineIdx === 0 && this._isMounted){
                                                                        if(this.state.latestBlockNumber === undefined || index > this.state.latestBlockNumber){
                                                                            this.setState({latestBlockNumber: index})
                                                                        }
                                                                        //console.log('final typed', line, lineIdx)
                                                                        if(this.state.allTextPresented !== true){
                                                                            this.setState({allTextPresented: true});
                                                                        }
                                                                        if(this.props.showTextInputHandler !== undefined){
                                                                            this.props.showTextInputHandler();
                                                                        }
                                                                        if(this.props.typingDoneHandler!== undefined){
                                                                            //console.log('finished typed earlier')
                                                                            this.props.typingDoneHandler();
                                                                        }
                                                                    }
                                                                }}
                                                                onCharacterTyped={(char, chrIdx) => {
                                                                    if (chrIdx> 0 && chrIdx% 80 === 0){
                                                                        //console.log('line change', chrIdx);
                                                                        if(this.props.typingMoveOnHandler!== undefined){
                                                                            this.props.typingMoveOnHandler();
                                                                        }
                                                                    }
                                                                }}
                                                                onTypingDone={ () => {
                                                                    if(this._isMounted){
                                                                        //console.log('finished typed')
                                                                        if(!this.state.allTextPresented){
                                                                            this.setState({allTextPresented: true});
                                                                        }
                                                                        if(this.props.typingDoneHandler!== undefined){
                                                                            this.props.typingDoneHandler();
                                                                        }
                                                                    }
                                                                }}
                                                                cursor={{show: false}}
                                                                >
                                                                &nbsp;
                                                            </Typist>
                                                            </span>
                                                            </span>
                                                        </CardContent>
                                                    </Card>
                                                    <br/>
                                                </div>
                                            :
                                                <Typist
                                                    key={key+'slow'}
                                                    avgTypingDelay={this._typingBotFinishTypingNow === true ? 0 : 10}
                                                    stdTypingDelay={this._typingBotFinishTypingNow === true ? 0 : 10}
                                                    onLineTyped={(line, lineIdx) => {
                                                        if (lineIdx === 0 && this._isMounted){
                                                            if(this.state.latestBlockNumber === undefined || index > this.state.latestBlockNumber){
                                                                this.setState({latestBlockNumber: index})
                                                            }
                                                            if(this.state.allTextPresented !== true){
                                                                //this.setState({allTextPresented: true});
                                                            }
                                                            if(this.state.lastTextBlockingState!=='running'){
                                                                this.setState({lastTextBlockingState:'running'})
                                                            }
                                                            if(this.props.showTextInputHandler !== undefined){
                                                                this.props.showTextInputHandler();
                                                            }
                                                            var spokenTextSamples = this.state.spokenTextSamples || [];
                                                            if (!spokenTextSamples.includes(message) && this.props.allowAudio && lineIdx === 0 && this.state.muteAudio!== true  && this._typingBotFinishTypingNow !== true && this.props.typingBotFinishTypingNow !== true) {
                                                                if(message.length < 400 ){
                                                                    this.textToSpeech(message, true);
                                                                }
                                                                else if(speechText[index]?.length > 2){
                                                                    this.textToSpeech(speechText[index], true);
                                                                    this.addSpokenSentenceList(message);
                                                                }
                                                                else{
                                                                    this.addSpokenSentenceList(message);
                                                                }
                                                            }
                                                            else if (spokenTextSamples.includes(message)){
                                                                //console.log('already included 44', message, spokenTextSamples);
                                                            }
                                                            else{
                                                                this.addSpokenSentenceList(message);
                                                            }
                                                        }
                                                    }}
                                                    onCharacterTyped={(char, chrIdx) => {
                                                        if (chrIdx> 0 && chrIdx% 80 === 0){
                                                            //console.log('line change', chrIdx);
                                                            if(this.props.typingMoveOnHandler!== undefined){
                                                                this.props.typingMoveOnHandler();
                                                            }
                                                        }
                                                    }}
                                                    style={{marginTop: 2}}
                                                    onTypingDone={ () => {
                                                        if(this._isMounted){
                                                            if(this.state.allTextPresented !== true){
                                                                this.setState({allTextPresented: true});
                                                            }
                                                            if(this.props.typingDoneHandler!== undefined){
                                                                //console.log('finished type ;last')
                                                                this.props.typingDoneHandler();
                                                            }
                                                            this.setState({lastTextBlockingState:'finished'});
                                                        }
                                                    }}
                                                    cursor={{ show: false }}
                                                >
                                                    {this.state.lastTextBlockingState === 'running' 
                                                    ?   <div key={"loadingcard"}></div>
                                                    :
                                                        <div key={"loadingcard"}>
                                                            <Card className={classes.cardLoading} >
                                                                <CardContent className={classes.cardPadding}>
                                                                    <div className="typing-indicator">
                                                                        <span style={{backgroundColor: theme.palette.secondary.light}}></span>
                                                                        <span style={{backgroundColor: theme.palette.secondary.light}}></span> 
                                                                        <span style={{backgroundColor: theme.palette.secondary.light}}></span>
                                                                    </div>
                                                                </CardContent>
                                                            </Card>
                                                            <br/>
                                                        </div>
                                                    }
                                                    <Typist.Delay ms={(textLengthOffset[index])} />
                                                    <Card //raised 
                                                        className={classes.cardBot} >
                                                        <CardContent className={classes.cardPadding}>
                                                            <span className={classes.botActive}>{message}</span>
                                                        </CardContent>
                                                    </Card>
                                                </Typist>
                                        }
                                        {this.props.allowInteraction && (this.state.allTextPresented || this._typingBotFinishTypingNow === true || this.state.typingBotFinishTypingNow) 
                                            ?
                                                <>
                                                    {this.renderInteraction(m, index, true)}
                                                </>
                                            :   this.props.allowFeedback && this.props.variant==="chatMain" && (this.state.allTextPresented || this._typingBotFinishTypingNow === true || this.state.typingBotFinishTypingNow)
                                                ?   
                                                    <>
                                                        {this.renderInteraction(m, index, true)}
                                                    </>
                                                :   ""
                                        }
                                    </div>
                            :   ""
                        }
                        </div>
                    )
                })}
                {this.state.allTextPresented && this.props.user !== undefined && this.props.contentSelection !== undefined 
                    && this.props.contentSelection.length > 0 && this.props.showContentSelection !== false
                    &&
                        <div style={{width: '90%', paddingBottom: 0, paddingTop: 20, marginLeft: 0, position: 'relative'}}>
                            <div style={{fontSize: 12, top:-10, textAlign: 'left', color: theme.palette.secondary.light, position: 'absolute'}}>
                                Tips
                            </div>
                            <ContentSuggestCarousel
                                user={this.props.user}
                                contentSelection={this.props.contentSelection}
                                leadershipCategories={this.props.leadershipCategories}
                                leadershipQualities={this.props.leadershipQualities}
                                messageSubmit = {(text) => {this.submitInspiration(text)}}
                                closeAfterActionAdded = {true}
                                contentOpened = {
                                    this.props.contentOpened === undefined
                                        ?  undefined
                                        :  (id) => {this.props.contentOpened(id)}
                                    }
                                />
                        </div>
                }
            </div>
        );
    }

    renderFeedback(m, index, lastItem=false) {
        const {theme} = this.props;
        //console.log('this feedback', this.state.showFeedback)
        if(this.state.showFeedback === (index+1)){
            return(
                <React.Fragment key={'feedback_'+index}>
                    <div>
                        <DialogueHistory 
                            messages={[m]}
                            index={index}
                            variant='showFeedbackButtons'
                            feedbackHandler={() => {
                                if(this._isMounted) this.setState({showFeedback: 0})
                                //console.log('feedback', this.state);
                            }}
                            showFavorite={this.props.showFavorite || false}
                            showComment={false}
                            switchHandler={
                                this.props.switchHandler !== undefined && lastItem
                                    ?   this.props.switchHandler 
                                    :   undefined
                            }
                            tipsHandler={
                                false && this.props.tipsHandler !== undefined && lastItem
                                    ?   this.props.tipsHandler 
                                    :   undefined
                            }
                            settingsHandler={
                                this.props.settingsHandler !== undefined && lastItem
                                    ?   this.props.settingsHandler 
                                    :   undefined
                            }
                        />
                    </div>
                </React.Fragment>
            )
        }
        else if(m.voteFeedback?.[index]?.length > 1){
            return(
                <React.Fragment key={'feedback_'+index}>
                    <div style={{position: 'absolute', right: 0, bottom: 'calc(0% + 20px)'}}>
                        <EmojiObjectsIcon style={{color: theme.palette.themeAlertGreen}}/>
                    </div>
                </React.Fragment>
            )
        }
        else if( (lastItem && this.state.showFeedback === 0 && m.voteFeedback === undefined && m.voteUser === undefined)){
            return(
                <React.Fragment key={'feedback_'+index}>
                    <div style={{position: 'absolute', right: 0, bottom: 'calc(0% + 20px)'}}>
                        <ThumbsUpDownIcon style={{color: theme.palette.secondary.main}}/>
                    </div>
                </React.Fragment>
            )
        }
        else{
            return(
                <React.Fragment key={'feedback_'+index}>
                    {m.voteUser?.[index] === 'up'
                        && 
                        <div style={{position: 'absolute', right: 0, bottom: 'calc(0% + 20px)'}}>
                            <ThumbUpIcon style={{color: theme.palette.themeAlertGreen}}/>
                        </div>
                    }
                    {m.voteUser?.[index] === 'down'
                        && 
                        <div style={{position: 'absolute', right: 0, bottom: 'calc(0% + 20px)'}}>
                            <ThumbDownIcon style={{color: theme.palette.themeGold}}/>
                        </div>
                    }
                </React.Fragment>
            )
        }
    }

    renderLastItemContent(m, index, lastItem=false){
        const {theme} = this.props;
        var utteranceClass = "";
        if(m?.utteranceClass?.[index]?.length > 0 && m?.utteranceClass?.length === m?.text?.length){
            utteranceClass = m.utteranceClass[index];
        }
        else if(m?.utteranceClass?.length > 0){
            var listUC = m.utteranceClass;
            for(let i = 0; i < ( m?.text?.length - m?.utteranceClass?.length ); i++){
                listUC.unshift('')
            }
            if(listUC[index]?.length > 0){
                utteranceClass = listUC[index];
            }
            //console.log('list', listUC)
        }
        var intentClassSelected = "";
        if(this.props.showExtraTips === true ){
            if(m?.intentsList?.[index]?.length >= 1 && m?.intentsList?.length === m?.text?.length){
                var intentClass = m.intentsList[index];
                ////// select only selected intentions
                if(intentClass.includes('conclusions_')) intentClassSelected = intentClass;
                else if(intentClass.includes('intention_')) intentClassSelected = intentClass;
                else if(intentClass.includes('question_')) intentClassSelected = intentClass;
                else if(intentClass.includes('activity_')) intentClassSelected = intentClass;
                else if(intentClass.includes('challenge_')) intentClassSelected = intentClass;
                else if(intentClass.includes('failure')) intentClassSelected = intentClass;
                if(utteranceClass.includes('affirm')) intentClassSelected = '';
                else if(utteranceClass.includes('inform')) intentClassSelected = '';
                if(lastItem){
                    utteranceClass = (m.utteranceClass?.[m.utteranceClass.length-1] || "");
                }
                if(m?.utteranceClass?.length === 0) intentClassSelected = '';
                else if(utteranceClass.includes('_check')) intentClassSelected = '';
                else if(utteranceClass.includes('_inform')) intentClassSelected = '';
                else if(utteranceClass.includes('_affirm')) intentClassSelected = '';
                else if(utteranceClass.includes('_confirm')) intentClassSelected = '';
                else if(utteranceClass.includes('ask_more')) intentClassSelected = '';
                else if(utteranceClass.includes('ask_further')) intentClassSelected = '';
                //console.log('props', m,  utteranceClass, m.text)
            }
        }
        //console.log('content,', index, intentClassSelected)
        if(!this.state.showTipsItems?.includes(index+1) && (lastItem && this.state.showFeedback !== 0 && this.state.showFeedback !== (m.text.length))){
            return ""
        }
        else if(!this.state.showTipsItems?.includes(index+1) && !lastItem && this.props.leadershipQualities !== undefined && ((intentClassSelected !== "" && this.state.showFeedback !== (index+1)) || ( lastItem && this.state.showFeedback === undefined))){
            return ""
        }
        else if(intentClassSelected?.length > 2 && this.props.leadershipQualities !== undefined && this.state['content_'+index+'_'+m.uid] !== 0 && (this.state.showFeedback === (index+1) || this.state.showFeedback === 0 || this.state.showTipsItems?.includes(index+1) )){
            return(
                <div>
                    {intentClassSelected?.length > 0 && this.state['content_'+index+'_'+m.uid] > 0 
                    &&
                        <div style={{width: "90%", minHeight: 80, padding:0, margin:0, display: this.state.showFeedback < 1 ? 'none' : 'block' }}>
                            <div style={{fontSize: 12, textAlign: 'left', color: theme.palette.secondary.light, position: 'absolute'}}>
                                Ideas
                            </div>
                            <div>
                                <InfiniteContent  
                                    paddingTop= {10}
                                    paddingBottom = {10}
                                    user = {this.props.user}
                                    //screenWidth = {IsMobileDevice() ? 300 : 800}
                                    defaultSet={this.props.defaultSet || undefined}
                                    scrollIntoViewDisabled = {true}
                                    visualLimit = {3}
                                    noQuotes = {true}
                                    showMinimal = {true}
                                    showCarousel = {true}//{this.state.showFeedback === 0}
                                    raised = {true}
                                    contentToHide = {this.state.contentToHide || undefined}
                                    filterIntent = {intentClassSelected}
                                    filterQuality = {this.props.filterQuality?.length > 2 ? this.props.filterQuality : this.props.user?.currentLeadershipQuality }
                                    filterTodayQuality = {this.props.filterTodayQuality?.length > 2 ? this.props.filterTodayQuality : undefined}
                                    filterCategory = {this.props.filterCategory?.length > 2 ? this.props.filterCategory : this.props.user?.currentCategory}
                                    showActions = {true}
                                    messageSubmit = {(text) => {
                                        this.submitInspiration(text);
                                    }}
                                    itemsSubmit = { (items) => {
                                        if (this.props.itemsSubmit !== undefined){
                                            this.props.itemsSubmit(items)
                                        }
                                        this.state.showTipsItems?.includes(index+1)
                                        items?.map(item => {
                                            var contentToHide = this.state.contentToHide || [];
                                            var showTipsItems = this.state.showTipsItems || [];
                                            //console.log('items 1', items, contentToHide)
                                            if(!contentToHide.includes(item.id)){
                                                contentToHide.push(item.id)
                                                //console.log('items 1x', items, contentToHide)
                                                if(!showTipsItems.includes(index+1)){
                                                    showTipsItems.push(index+1)
                                                }
                                                this.setState({contentToHide, showFeedback: index+1, showTipsItems})
                                            }
                                        })
                                    }}
                                    resultCounter = {(counter) => this.setState({['content_'+index+'_'+m.uid] : counter})}
                                    hideLimitPrompt={true}
                                    leadershipCategories={this.props.leadershipCategories}
                                    leadershipQualities={this.props.leadershipQualities}
                                    rerender={true}
                                />
                            </div>
                        </div>
                    }
                </div>
            )
        }
    }

    renderInfoButton(intentClassSelected, index, lastItem=false){
        const {theme} = this.props;
        if(false && intentClassSelected?.length > 0){
            return(
                <div style={{position: 'absolute',  right: 0, bottom: 'calc(0% + 20px)', fontSize: 12, textAlign: 'right', color: theme.palette.secondary.main}}>
                    Tips
                </div>
            )
        }
        else if(lastItem){
            return(
                <div style={{position: 'absolute', right: 0, bottom: 'calc(0% + 20px)'}}>
                    <CachedIcon style={{color: theme.palette.secondary.main}}/>
                </div>
            )
        }
        else if (true && intentClassSelected?.length > 0){
            return(
                <div style={{position: 'absolute', right: 0, bottom: 'calc(0% + 20px)'}}>
                    <InfoIcon style={{color: theme.palette.secondary.main}}/>
                </div>
            )
        }
        else {
            return(
                <div style={{position: 'absolute', right: 0, bottom: 'calc(0% + 20px)'}}>
                    <InfoIcon style={{color: theme.palette.secondary.main}}/>
                </div>
            )
        }
    }

    renderInteraction(m, index, lastItem=false, showContent=false) {
        const {theme} = this.props;
        var utteranceClass = "";
        if(m?.utteranceClass?.[index]?.length > 0 && m?.utteranceClass?.length === m?.text?.length){
            utteranceClass = m.utteranceClass[index];
        }
        else if(m?.utteranceClass?.length > 0){
            var listUC = m.utteranceClass;
            for(let i = 0; i < ( m?.text?.length - m?.utteranceClass?.length ); i++){
                listUC.unshift('')
            }
            if(listUC[index]?.length > 0){
                utteranceClass = listUC[index];
            }
            //console.log('list', listUC)
        }
        var intentClassSelected = "";
        if(this.props.showExtraTips === true ){
            if(m?.intentsList?.[index]?.length >= 1 && m?.intentsList?.length === m?.text?.length){
                var intentClass = m.intentsList[index];
                ////// select only selected intentions
                if(intentClass.includes('conclusions_')) intentClassSelected = intentClass;
                else if(intentClass.includes('intention_')) intentClassSelected = intentClass;
                else if(intentClass.includes('question_')) intentClassSelected = intentClass;
                else if(intentClass.includes('activity_')) intentClassSelected = intentClass;
                else if(intentClass.includes('challenge_')) intentClassSelected = intentClass;
                else if(intentClass.includes('failure')) intentClassSelected = intentClass;
                if(utteranceClass.includes('affirm')) intentClassSelected = '';
                else if(utteranceClass.includes('inform')) intentClassSelected = '';
                if(lastItem){
                    utteranceClass = (m.utteranceClass?.[m.utteranceClass.length-1] || "");
                }
                if(m?.utteranceClass?.length === 0) intentClassSelected = '';
                else if(utteranceClass.includes('_check')) intentClassSelected = '';
                else if(utteranceClass.includes('_inform')) intentClassSelected = '';
                else if(utteranceClass.includes('_affirm')) intentClassSelected = '';
                else if(utteranceClass.includes('_confirm')) intentClassSelected = '';
                else if(utteranceClass.includes('ask_more')) intentClassSelected = '';
                else if(utteranceClass.includes('ask_further')) intentClassSelected = '';
                //console.log('props', m,  utteranceClass, m.text)
            }
        }
        //console.log('render buttons', this.state.showTipsItems, (m.text.length), index)
        if(!(this.state.showTipsItems?.includes(index+1)) && (lastItem && this.state.showFeedback !== 0 && this.state.showFeedback !== (m.text.length))){
            return(
                <React.Fragment key={'feedback_'+index}>
                    {this.renderInfoButton(intentClassSelected, index, lastItem)}
                </React.Fragment>
            )
        }
        else if(!(this.state.showTipsItems?.includes(index+1)) && !lastItem && this.props.leadershipQualities !== undefined && ((intentClassSelected !== "" && this.state.showFeedback !== (index+1)) || ( lastItem && this.state.showFeedback === undefined))){
            //console.log('message interaction', m, index, utteranceClass, this.state.showFeedback)
            return(
                <React.Fragment key={'feedback_'+index}>
                    {this.renderInfoButton(intentClassSelected, index, lastItem)}
                </React.Fragment>
            )
        }
        else if(this.props.leadershipQualities !== undefined && this.state['content_'+index+'_'+m.uid] !== 0 && (this.state.showTipsItems?.includes(index+1) || this.state.showFeedback === (index+1) || this.state.showFeedback === 0 ) && intentClassSelected !== "" ){
            return(
                <React.Fragment key={'feedback_'+index}>
                    {this.state.showFeedback === 0 && !(this.state.showTipsItems?.includes(index+1)) &&
                        <>
                            {this.renderInfoButton(intentClassSelected, index, lastItem)}
                        </>
                    }
                    {lastItem && this.state.showFeedback === (m.text.length) && 
                        <DialogueHistory 
                            messages={[m]}
                            index={index}
                            variant='showFeedbackButtons'
                            feedbackHandler={() => {
                                if(this._isMounted) this.setState({showFeedback: 0})
                                //console.log('feedback', this.state);
                            }}
                            showFavorite={this.props.showFavorite || false}
                            showComment={false}
                            switchHandler={
                                this.props.switchHandler !== undefined && lastItem
                                    ?   this.props.switchHandler 
                                    :   undefined
                            }
                            tipsHandler={
                                false && this.props.tipsHandler !== undefined && lastItem
                                    ?   this.props.tipsHandler 
                                    :   undefined
                            }
                            settingsHandler={
                                this.props.settingsHandler !== undefined && lastItem
                                    ?   this.props.settingsHandler 
                                    :   undefined
                            }
                        />
                    }
                    {!lastItem && (showContent || this.state.showTipsItems?.includes(index+1)) && intentClassSelected?.length > 0 && 
                        <div style={{width: "90%", minHeight: 80}}>
                            <div style={{fontSize: 12, textAlign: 'left', color: theme.palette.secondary.light, position: 'absolute'}}>
                                Tips
                            </div>
                            <InfiniteContent 
                                paddingTop= {10}
                                paddingBottom= {10}
                                user = {this.props.user}
                                //screenWidth = {IsMobileDevice() ? 300 : 800}
                                defaultSet={this.props.defaultSet || undefined}
                                scrollIntoViewDisabled = {true}
                                visualLimit = {3}
                                noQuotes = {true}
                                showMinimal = {true}
                                showCarousel = {true}//{this.state.showFeedback === 0}
                                raised = {true}
                                filterIntent = {intentClassSelected}
                                contentToHide = {this.state.contentToHide || undefined}
                                filterQuality = {intentClassSelected?.length > 1 ? undefined: this.props.filterQuality?.length > 2 ? this.props.filterQuality : this.props.user?.currentLeadershipQuality }
                                filterTodayQuality = {this.props.filterTodayQuality?.length > 2 ? this.props.filterTodayQuality : undefined}
                                filterCategory = {intentClassSelected?.length > 1 ? undefined : this.props.filterCategory?.length > 2 ? this.props.filterCategory : this.props.user?.currentCategory}
                                showActions = {true}
                                messageSubmit = {(text) => {
                                    this.submitInspiration(text);
                                }}
                                itemsSubmit = {(items) => {
                                    if (this.props.itemsSubmit !== undefined){
                                        this.props.itemsSubmit(items)
                                    }
                                    items?.map(item => {
                                        var contentToHide = this.state.contentToHide || [];
                                        var showTipsItems = this.state.showTipsItems || [];
                                        //console.log('items 2', items, contentToHide)
                                        if(!contentToHide?.includes(item.id)){
                                            contentToHide.push(item.id)
                                            if(!showTipsItems.includes(index+1)){
                                                showTipsItems.push(index+1)
                                            }
                                            //console.log('items 2x', items, contentToHide)
                                            this.setState({contentToHide: contentToHide})
                                        }
                                    })
                                }}
                                resultCounter = {(counter) => { if(this._isMounted) {
                                    this.setState({['content_'+index+'_'+m.uid] : counter})
                                }}}
                                hideLimitPrompt={true}
                                leadershipCategories={this.props.leadershipCategories}
                                leadershipQualities={this.props.leadershipQualities}
                                rerender={true}
                            />
                        </div>
                    }
                    {//// gnore the infotext
                    false && lastItem && (this.state.showFeedback === (m.text.length)) && this.state.allTextPresented && this.props.currentExplanation?.length > 10 && this.props.currentExplanation !== 'EXPLANATION OF QUESTION'
                        &&
                            <div style={{paddingTop: 10, paddingRight: 20, paddingBottom: 10}}>
                                <span style={theme.textSupport}>
                                    <InfoIcon style={theme.inlineIconLeft}/>
                                    {!this.state.showFeedback && this.props.currentExplanation?.length > 100 ? this.props.currentExplanation.substring(0, 97) + "..." : this.props.currentExplanation}
                                </span>
                            </div>
                    }
                </React.Fragment>
            )
        }
        else if(this.state.showFeedback === (index+1)){
            return(
                <React.Fragment key={'feedback_'+index}>
                    <div>
                        <DialogueHistory 
                            messages={[m]}
                            index={index}
                            variant='showFeedbackButtons'
                            feedbackHandler={() => {
                                if(this._isMounted) this.setState({showFeedback: 0})
                                //console.log('feedback', this.state);
                            }}
                            showFavorite={this.props.showFavorite || false}
                            showComment={false}
                            switchHandler={
                                this.props.switchHandler !== undefined && lastItem
                                    ?   this.props.switchHandler 
                                    :   undefined
                            }
                            tipsHandler={
                                false && this.props.tipsHandler !== undefined && lastItem
                                    ?   this.props.tipsHandler 
                                    :   undefined
                            }
                            settingsHandler={
                                this.props.settingsHandler !== undefined && lastItem
                                    ?   this.props.settingsHandler 
                                    :   undefined
                            }
                        />
                    </div>
                </React.Fragment>
            )
        }
    }

    turnHistoryBlocks(untilIndex=0) {
        const {classes, theme} = this.props;
        return (
            <div>
                {this.props.messages!==null && this.props.messages.length > 0 &&
                    <Accordion 
                        className={classes.expansionPanel} 
                        key="fullchat"  id="fullchat" 
                        //TransitionProps={{ mountOnEnter: true }}
                        expanded={this.state.expanded || false}
                        onChange={(object, expanded) => {
                                if(expanded !== this.state.expanded && this._isMounted) {
                                    //console.log('change', expanded, this.props)
                                    this.setState({expanded: expanded, expandedOverwrite: true});
                                    if(this.props.expandedState !== undefined){
                                        this.props.expandedState(expanded); 
                                    }
                                }
                            }}
                        >
                        <AccordionSummary
                            expandIcon={<ArrowDropUpIcon fontSize='large'/>}
                            aria-controls="previousTurns"
                            id="previousTurns"
                            className={classes.expansionPanel}
                        >
                            {this.state.expanded !== true &&
                                <>
                                    {this.props.messages?.length > 1
                                        ?   this.turnlastSentence(this.props.messages.slice(untilIndex-1, untilIndex))
                                        :   '...'
                                    }
                                </>
                            }    
                        </AccordionSummary>
                        <AccordionDetails className={classes.expansionPanel}> 
                            <div style={{ width:"100%"}}>
                                {this.state.expanded && this.props.messages.map( (messages, index) => (
                                    index < untilIndex
                                    ?
                                        messages.isCoach 
                                        ? 
                                            <div key={"historyturn_agent_"+index} align={this.props.invertCoach!== true ? 'left' : 'right'}>
                                                <Card className={classes.cardBot} >
                                                    <CardContent className={classes.cardPadding}>
                                                        {this.props.showDetail && messages.username!==undefined && messages.createdAt!==undefined
                                                        &&
                                                            <>
                                                                <span style={{fontSize:'small', color:"green"}}>{messages.username} - {ConvertTimestampToDateString(messages.createdAt, true)}</span>
                                                                <br/>
                                                            </>
                                                        }
                                                        <span className={classes.bot}>{messages.text.join(' \n\n')}</span>
                                                    </CardContent>
                                                </Card>
                                            </div>
                                        :
                                            <div key={"historyturn_user_"+index}  align={this.props.invertCoach!== true ? 'right' : 'left'}>
                                                <Card className={classes.cardUser} >
                                                    <CardContent className={classes.cardPadding}>
                                                        {this.props.invertCoach=== true && this.props.showDetail && messages.username!==undefined && messages.createdAt!==undefined
                                                        &&
                                                            <>
                                                                <span style={{fontSize:'small', color:"lightblue"}}>{messages.username} - {ConvertTimestampToDateString(messages.createdAt, true)}</span>
                                                                <br/>
                                                            </>
                                                        }
                                                        <span className={classes.user}
                                                            onClick={() => {
                                                                index === (this.props.messages.length-2) && this.props.repeatHandler !== undefined && !ignoreTexts.includes(messages.text.join())
                                                                    ?   this.props.repeatHandler()
                                                                    :   void(0)
                                                            }}
                                                            >
                                                            {index === (this.props.messages.length-2) && this.props.repeatHandler !== undefined && !ignoreTexts.includes(messages.text.join()) &&
                                                                <ReplayIcon style={theme.inlineIconLeft} />
                                                            }
                                                            {messages.text.join(' ')}
                                                        </span>
                                                    </CardContent>
                                                </Card>
                                            </div>
                                    : ""
                                ))}
                            </div>
                        </AccordionDetails> 
                    </Accordion>
                }
            </div>
        );
    }

    turnlastSentence(messagesLog){
        const {classes} = this.props;
        //console.log('messagesLog', messagesLog)
        if(messagesLog?.[0]?.text?.length > 0 && this.props.invertCoach!== true){
            if(messagesLog?.[0]?.isCoach){
                return(
                    <div align={this.props.invertCoach!== true ? 'left' : 'right'} style={{marginBottom: -10}}>
                        <Card className={classes.cardBotSpecial}>
                            <CardContent className={classes.cardPadding}>
                                <span className={classes.bot}>{messagesLog[0].text[messagesLog[0].text.length - 1]}</span>
                            </CardContent>
                        </Card>
                    </div>
                )
            }
            else{
                return('...')
            }
        }
        else{
            return('...')
        }
    }

    turnSingleBlock(messagesLog, audio=false, shortenText=false) {
        if(this.props.variant === "chatEnd"){
            if(this.props.typingDoneHandler !== undefined && this.state.chatEndShowed !== true && this._isMounted){
                this.props.typingDoneHandler();
                //console.log('final message', messagesLog)
                this.setState({chatEndShowed : true});
            }
            if(audio && this.state.muteAudio === false){
                this.playAudioArrayFinal(messagesLog);
            }
        }
        const {classes, theme} = this.props;
        //console.log('log',messagesLog, this.props)
        return (
            <div>
                {messagesLog && messagesLog.length > 0 &&
                    <div>
                        {messagesLog.map( (messages, index) => {
                            //console.log('messages', messages)
                            if(messages?.text?.length > 0){
                                var text = messages.text.join(' ');
                                text = text.trim();
                                if(shortenText && this.state.flagNoShortText !== true && text.length > 60){
                                    text = text.substring(0,55)+'...';
                                }
                                return(
                                    messages.isCoach
                                    ?   text.length>=2
                                        ?
                                            <div key={"historyturn_agent_"+index}  align={this.props.invertCoach!== true ? 'left' : 'right'}>
                                                <Card className={classes.cardBot}>
                                                    <CardContent className={classes.cardPadding}
                                                        onClick={shortenText ? () => {this.setState({flagNoShortText: true}) } : undefined}
                                                    >
                                                        {this.props.showDetail && messages.username!==undefined && messages.createdAt!==undefined
                                                        &&
                                                            <>
                                                                <span style={{fontSize:'small', color:"green"}}>{messages.username} - {ConvertTimestampToDateString(messages.createdAt, true)}</span>
                                                                <br/>
                                                            </>
                                                        }
                                                        <span className={classes.bot}>{text}</span>
                                                    </CardContent>
                                                </Card>
                                            </div>
                                        :   ''
                                    :   (this.props.noAnimation || this.props.variant === "chatMain" || this.props.variant === 'chatLastUserMessage') && text.length>=1
                                        ?
                                            <div key={"historyturn_user_"+index}  align={this.props.invertCoach!== true ? 'right' : 'left'}>
                                                <Card className={classes.cardUser} >
                                                    <CardContent className={classes.cardPadding}>
                                                        {this.props.invertCoach === true && this.props.showDetail && messages.username!==undefined && messages.createdAt!==undefined
                                                        &&
                                                            <>
                                                                <span style={{fontSize:'small', color:"lightblue"}}>{messages.username} - {ConvertTimestampToDateString(messages.createdAt, true)}</span>
                                                                <br/>
                                                            </>
                                                        }
                                                        {(this.props.showDetail || this.state.clickShowFullSentence || text.length <=60)
                                                            ?   this.props.repeatHandler !== undefined && !ignoreTexts.includes(text)
                                                                ?   <span className={classes.user} 
                                                                        onClick={() => {
                                                                            this.props.repeatHandler !== undefined
                                                                                ?   this.props.repeatHandler()
                                                                                :   void(0)
                                                                        }}>
                                                                        {this.props.repeatHandler !== undefined &&
                                                                            <ReplayIcon style={theme.inlineIconLeft} />
                                                                        }
                                                                        {text}
                                                                    </span>
                                                                :   <span className={classes.user}>
                                                                        {text}
                                                                    </span>
                                                            :   <span className={classes.user} 
                                                                    onClick={() => this.setState({clickShowFullSentence: true, clickShowRepeat: true})}
                                                                    >
                                                                    {text.length > 60 ? text.substring(0,55)+'...' : text}
                                                                </span>
                                                        }                                                    
                                                    </CardContent>
                                                </Card>
                                            </div>
                                        :   ""
                                )
                            }
                            else{
                                return ''
                            }
                        } )}
                    </div>
                }
            </div>
        );
    }

    submitInspiration = (text) => {
        if(this.props.messageSubmit!== undefined){
            this.props.messageSubmit(text)
        }
    }

    createAudioContext(){
        try{
            this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
            this.audioContext = new AudioContext();
            /***
            */
            ///// safari has the audio closed and need tricking, 
            ///// https://www.mattmontag.com/web/unlock-web-audio-in-safari-for-ios-and-macos
            this.unlockAudioContext(this.audioContext);
        }
        catch (e) {
            console.log('Error: Adding Audio Context', e)
        }
    }
    unlockAudioContext = (newAudioContext) => {
        if (newAudioContext === undefined || newAudioContext.state !== 'suspended') {
            //console.log('audio context status', newAudioContext)
            return;
        }
        const b = document.body;
        const events = ['deviceready', 'touchstart', 'touchend', 'mousedown','keydown'];
        events.forEach(e => b.addEventListener(e, unlock, false));
        function unlock() { newAudioContext.resume().then(clean); }
        function clean() { events.forEach(e => b.removeEventListener(e, unlock)); }
    }

    stripEmojis = (str) => {
        str.replace(
            /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
            ''
            )
            .replace(/\s+/g, ' ')
            .trim();
    }

    textToSpeech = async (originalText, forceAudio = false) => {
        var audioContextAlive = true
        if(this.audioContext !== undefined && this.audioContext.state === 'suspended'){
            audioContextAlive = false;
        }
        var spokenTextSamples = this.state.spokenTextSamples || [];
        var text = "" + originalText;
        //console.log('request text to speech', text)
        if (!spokenTextSamples.includes(text) && this._isMounted) {
        //if(true){
            //console.log('sxtate', this.audioContext.state); //suspended
            if(audioContextAlive && this.AWS_S3 !== undefined && this.AWS_S3.config !== undefined && text?.length > 1 && text?.length < 400 && this.state.muteAudio !== true){
                try{
                    //console.log('prep message', text)
                    //var filteredMessage = text.replace(/\//g, " ");
                    //filteredMessage = filteredMessage.replace(/(\r\n|\n|\r)/gm, "");
                    //var filteredMessage = text.replace(/[^\w\s]/gi, '')
                    if(this.props.typingBotFinishTypingNow !== true){
                        var filteredMessage = text.replace(/[^.,!?a-zA-Z0-9 -]/g, '')
                        var id = filteredMessage.replace(/[^0-9a-z-A-Z]/g,'').toLowerCase();
                        if (id.length > 75) {
                            id = id.substr(-75);
                        }
                        var bucket = "polly.app.rocky.ai";
                        var paramsHead = {
                            Bucket: bucket,
                            Key: (this.pollyVoiceSet+id+'.mp3')
                        };
                        this.AWS_S3.headObject(paramsHead, ((err, metadata) => {  
                                //console.log('headobject error report', err)
                                if((this._typingBotFinishTypingNow !== true || forceAudio) && this._isMounted){
                                    if(text.length > 260){
                                        const skipStorage = true;
                                        //console.log('skip storage')
                                        this.pollySpeechStreamAndStore(bucket, filteredMessage, id, forceAudio, skipStorage);
                                        if (err && err.code === 'NotFound') {
                                            console.clear();
                                        }
                                        return true;
                                    }
                                    else if (err && err.code === 'NotFound') {  
                                        // Handle no object on cloud here  
                                        //console.log(err, err.stack)
                                        //console.log('CREATE NEW mp3 FILE')
                                        this.pollySpeechStreamAndStore(bucket, filteredMessage, id, forceAudio);
                                        console.clear();
                                        return true;
                                    } 
                                    else if(this.props.typingBotFinishTypingNow !== true) {  
                                        //console.log('LOAD EXCISTING mp3 FILE')
                                        this.pollySpeechLoadFile(bucket, filteredMessage, id, forceAudio);
                                        //console.log('file extis')
                                        return true;
                                    }
                                    else{
                                        //console.log('ignore mp3 FILE')
                                        return true;
                                    }
                                }
                                else{
                                    return true;
                                }
                            })
                        );
                    }
                    else{
                        console.log('ignore it')
                    }

                    /****

                    * 

                    try {
                        await this.AWS_S3.headObject(paramsHead);
                        this.pollySpeechLoadFile(bucket, filteredMessage, id);
                    } 
                    catch (err) {
                        console.log('headobject error report', err)
                        if (err && err.code === 'NotFound') {  
                            // Handle no object on cloud here  
                            console.log('CREATE NEW mp3 FILE')
                            this.pollySpeechStreamAndStore(bucket, filteredMessage, id);
                        }
                    } 
                    */
                ///// https://docs.aws.amazon.com/polly/latest/dg/API_StartSpeechSynthesisTask.html
                ///// is directly storing the mp3 file into bucket

                }
                catch (e) {
                    console.log('error', e)
                }
            }
        }
        this.addSpokenSentenceList(text);
    }

    pollySpeechLoadFile(bucket, text, id, forceAudio = false){
        if(text !== undefined && text.length >1 && this.state.muteAudio !== true && this._isMounted){
            var paramsHead = {
                Bucket: bucket,
                Key: (this.pollyVoiceSet+id+'.mp3')
            };
            //this.audioSource = this.audioContext.createBufferSource();
            //var signedMp3Url = this.AWS_S3.getSignedUrl('getObject', paramsHead);
            //console.log('signedmp3 file', signedMp3Url)
            this.AWS_S3.getObject(paramsHead).promise()
            .then( (resultAudio) => {
                //console.log('result loads urls', resultAudio);
                if (resultAudio && resultAudio.Body instanceof Uint8Array) {
                    //const bufferBase = new Buffer.from(resultAudio.Body.buffer).toString("base64");
                    //console.log('base', 'loadfile')
                    //this.OLDplayAudioStreamBuffer(resultAudio.Body.buffer);
                    if((this._typingBotFinishTypingNow !== true || forceAudio) && this._isMounted){
                        this.playAudioHowl(resultAudio.Body.buffer, forceAudio);
                    }
                }
                else {
                    console.log('err load URLSW')
                } 
            })
            //var audio = new Audio(signedMp3Url);
            //audio.autoplay = true;
            //audio.play();
            /***
            window.fetch(signedMp3Url)
            .then(response => response.arrayBuffer())
            .then(arrayBuffer => this.audioContext.decodeAudioData(arrayBuffer))
            .then((newBuffer) => {
                console.log('reload existing buffer and start audio', newBuffer);
                this.audioSource.buffer = newBuffer;
                this.audioSource.connect(this.audioContext.destination);
                this.audioSource.start();
            }) 
            */
        }
    }

    pollySpeechStreamAndStore(bucket, text, id, forceAudio = false, skipStorage = false){
        if(text!== undefined && text.length >1 && this.state.muteAudio !== true && this._isMounted){
            var s3Params = {
                Engine: "neural",
                OutputFormat: "mp3",
                Text: text,
                TextType: "text",
                VoiceId: this.pollyVoiceId,
                SampleRate: "22050"
            };
            try{
                this.AWS_Polly.synthesizeSpeech(s3Params)
                .promise()
                .then( (resultAudio) => {
                    if (resultAudio && resultAudio.AudioStream instanceof Buffer) {
                        //console.log('result', resultAudio);
                        if(skipStorage){
                            //console.log('skip write mp3')
                        }
                        else{
                            this.writeAudioStreamToS3(bucket, resultAudio.AudioStream, id+'.mp3');
                            //console.log('writerestulsw')
                        }
                        //this.OLDplayAudioStreamBuffer(resultAudio.AudioStream.buffer);
                        if((this._typingBotFinishTypingNow !== true || forceAudio) && this._isMounted){
                            this.playAudioHowl(resultAudio.AudioStream.buffer, forceAudio);
                        }
                    }
                    else {
                        console.log('err synthesizeSpeech')
                    } 
                })
            }
            catch (e) {
                console.log('pollySpeechStreamAndStore', e)
            }
        }
    }

    OLDpollySpeechStoreTask(bucket, text, id){
        if(text!== undefined && text.length >1 && this.state.muteAudio !== true){
            var s3Params = {
                OutputFormat: "mp3",
                OutputS3BucketName: bucket,
                OutputS3KeyPrefix: this.pollyVoiceSet,
                Text: text,
                TextType: "text",
                VoiceId: this.pollyVoiceId,
                SampleRate: "22050"
            };
            try{
                this.AWS_Polly.startSpeechSynthesisTask(s3Params) 
                .promise()
                .then( (resultAudio) => {
                    //console.log('result', resultAudio);
                    if (resultAudio && resultAudio.SynthesisTask !== undefined && resultAudio.SynthesisTask.OutputUri !== undefined && resultAudio.SynthesisTask.OutputUri.includes('mp3')) {
                        var fullUrl = resultAudio.SynthesisTask.OutputUri;
                        var keyUrl = fullUrl.split(bucket+'/')[1];

                        var resultsgen = this.AWS_Polly.getSpeechSynthesisTask(resultAudio.SynthesisTask.TaskId)
                        //console.log('resultsgen', resultsgen)

                        const presignedGETURL = this.AWS_S3.getSignedUrl('getObject', {
                            Bucket: bucket,
                            Key: keyUrl,
                            Expires: 100
                        });
                        //console.log('presignedGETURL', fullUrl,  presignedGETURL)
                        new Audio().play(fullUrl);
                    }
                    else {
                        console.log('err')
                    } 
                })
            }
            catch (e) {
                console.log('pollySpeechStoreTask', e)
            }
        }
    }
    OLDplayAudioStreamBuffer(audioStreamBuffer){
        if(audioStreamBuffer!== undefined && audioStreamBuffer instanceof ArrayBuffer && this.state.muteAudio !== true && this._isMounted){
            var audioChannelFree = true;
            if(this._audioAlreadyRunning === true){
                audioChannelFree = false;
            }
            if(audioChannelFree){
                this.audioSource = this.audioContext.createBufferSource();
                            /**
                             */
                this.audioContext.decodeAudioData(audioStreamBuffer)
                .then((newBuffer) => {
                    //console.log('buffer and start audio', newBuffer);
                    this.audioSource.buffer = newBuffer;

                    /***
                    if (this.audioSource === undefined) {
                        //console.log('status', this.audioSource)
                        var AudioContext = window.AudioContext // Default
                            || window.webkitAudioContext;// Safari and old versions of Chrome
                        this.audioContext = new AudioContext();
                    }
                    */
                    //this.audioContext.resume()
                    //this.audioContext.createGain();
                    //console.log('sxtate', this.audioContext); //suspended
                    this.audioSource.connect(this.audioContext.destination);
                    this._audioAlreadyRunning = true;
                    //this.setState({audioAlreadyRunning : true})
                    this.audioSource.start();
                    // this flag shows that the event is ended and ensure that we do not have double voice in parallel
                    this.audioSource.onended = this.playAudioEnded;
                }) 
            }
        }
    }
    playAudioHowl(audioStreamBuffer, forceAudio = false){
        if(this._audioAlreadyRunning !== true){
            if((this._typingBotFinishTypingNow !== true || forceAudio) && this._isMounted){
                this._audioAlreadyRunning = true;
                try{
                    const base64String = btoa(String.fromCharCode(...new Uint8Array(audioStreamBuffer)));
                    var howlSource = ["data:audio/mp3;base64,"+base64String];
                    if(this._howlIndex > 9){
                        this._howlIndex = 0;
                    }
                    //// needed to split into arrays else error message with memoety 
                    //// https://github.com/goldfire/howler.js/issues/825#issuecomment-497747716
                    if(false && this._howlers.length>this._howlIndex && this._howlers[this._howlIndex] !== undefined){
                        console.log('catching the old ones',this._howlIndex  )
                        this._howlers[this._howlIndex].unload(true);
                        this._howlers[this._howlIndex]._src = [howlSource];
                        this._howlers[this._howlIndex].load();
                    }
                    else{
                        //console.log('new sound',this._howlIndex  )
                        if(this._howlers[this._howlIndex] !== undefined){
                            //console.log('there is still shit',this._howlIndex  )
                            this._howlers[this._howlIndex].unload(true);
                            this._howlers[this._howlIndex].stop();
                            this._howlers[this._howlIndex].unload();
                            this._howlers[this._howlIndex].volume(0.0)
                            this._howlers[this._howlIndex]._src = [howlSource];
                        }
                        var sound = new Howl({
                            src: [howlSource],
                            html5: true,
                            onend: () => {this.playAudioEnded(this._howlIndex)},
                            onpause: () => {this.playAudioEnded(this._howlIndex)},
                            onstop: () => {this.playAudioEnded(this._howlIndex)},
                            onmute: () => {this.playAudioEnded(this._howlIndex)},
                            volume: 0.7
                        });
                        this._howlers[this._howlIndex] = sound;
                    }
                    
                    //sound.play();
                    this._howlers[this._howlIndex].play();
                    this._howlIndex +=1;
                }
                catch (e){
                    console.log('Audio creation error', e)
                }
            }
        }
    }

    releaseAudioHowl(){
        if(this._howlers.length>0){
            //console.log('all release', this._howlers)
            this._howlers.forEach(howler => {
                if(howler !== undefined){
                    howler.stop();
                    //howler.unload();
                    //howler.volume(0.0)
                    //console.log('unloaded', howler)
                    //this._howlers.splice(0, 1);
                }
            })
            //console.log('unloaded all', this._howlers)
            this._howlIndex = 0;
            this._howlers = [];
            //console.log('unloaded all', this._howlers)
        }
    }

    playAudioEnded = (howlIndex) => {
        //console.log('ended', howlIndex)
        if (this._howlers[howlIndex] !== undefined){
            this._howlers[howlIndex].unload(true);
        }
        this._audioAlreadyRunning = false;
        //this.setState({audioAlreadyRunning : false})
        //console.log('aidop sourtce ended', this.audioSource)
        if(this.props.user !== undefined && this.props.user.muteAudio === undefined ){
            //console.log('setting the flag for the user to use audio format in its application');
            if(this.props.handlerMuteAudio !== undefined && this.props.handlerMuteAudio !== false){
                this.props.handlerMuteAudio(false);
            }
            //this.props.firebase.user(this.props.user.uid).update({muteAudio: false});
        }

    }

    playAudioArrayFinal(messagesLog){
        if(this.props.variant === "chatEnd" && this.state.audioArrayPlayed !== true && this._isMounted){
            var textLengthOffset = 0;
            const finalMessage = messagesLog[messagesLog.length-1];
            //console.log('final message', finalMessage)
            if(finalMessage.text !== undefined && finalMessage.text.length>0){
                finalMessage.text.map( message => {
                    var spokenTextSamples = this.state.spokenTextSamples || [];
                    if (!spokenTextSamples.includes(message) && this._isMounted) {
                        setTimeout(() => {
                            //console.log('time out message', message)
                            if(this._isMounted && this.props.variant === "chatEnd"){
                                spokenTextSamples = this.state.spokenTextSamples || [];
                                if (!spokenTextSamples.includes(message) && this._isMounted) {
                                    this.setState({ audioArrayPlayed : true});
                                    //console.log('started 3', message);
                                    this.textToSpeech(message);
                                }
                            }
                        }, textLengthOffset);
                    }
                    textLengthOffset = textLengthOffset+message.length*60+1000 ;
                    return true;
                })
            }
        }
    }

    ///////// https://medium.com/@Feawel/aws-polly-api-in-node-js-stream-mp3-generation-s3-storage-5a01def217ac

    writeAudioStreamToS3 = ( bucket, audioStream, filename ) => {
        if(filename !== undefined && filename.length>5 && bucket!== undefined && bucket.length> 2 && audioStream instanceof Buffer){
            var aws_publicBucket = bucket;
            var folderName = this.pollyVoiceSet + filename;
            try{
                this.putObject(aws_publicBucket, folderName, audioStream, 'audio/mp3').then( res => {
                    //console.log('new file uplaoded', res);
                    return true;
                    /***
                    if(!res.ETag) throw res
                    else return {
                        msg: 'File successfully generated.',
                        ETag: res.ETag,
                        url: `https://s3-eu-central-1.amazonaws.com/${aws_publicBucket}/${folderName}`
                    }
                     */
                })
                .catch((error) => {
                    //console.log('error startus writer file', error);
                    return false;
                });
            }
            catch (e) {
                console.log('error putting', e);
                return false;
            }
        }
    }

    ////// https://aws.amazon.com/blogs/compute/uploading-to-amazon-s3-directly-from-a-web-or-mobile-application/
    putObject = (bucket, key, body, ContentType) => this.AWS_S3.putObject({
        Bucket: bucket,
        Key: key,
        Body: body,
        ContentType,
        ACL: 'public-read'
    }).promise()
    /****
    xxputObject = async (bucket, key, body, ContentType) => {
        var data = {
            Bucket: bucket,
            Key: key,
            Body: body,
            ContentType,
            //Expires: 10000,
            ACL: 'public-read'
        }
        const uploadURL = await this.AWS_S3.getSignedUrlPromise('putObject', data);
        return uploadURL
    }
    XXXXputObject = async (bucket, key, body, ContentType) => {
        var data = {
            Bucket: bucket,
            Key: key,
            Body: body,
            ContentType,
            //Expires: 10000,
            ACL: 'public-read'
        }
        const uploadURL = await this.AWS_S3.putObject(data);
        return uploadURL
    }
     */
}

export default compose(
    withStyles(styles, { withTheme: true})
)(ChatBlock);
