import React, { Component } from 'react';
import { compose } from 'recompose';
import { Link } from 'react-router-dom';
import {withRouter} from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';

//import SignOutButton from '../SignOut';
import DirectionsIcon from '@material-ui/icons/Directions';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';

import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Divider from '@material-ui/core/Divider';
//import Drawer from '@material-ui/core/Drawer';
import logo from "../../images/rocky-logo-square.png";
import RockyIcon from '../Modules/RockyIcon';
import ForumIcon from '@material-ui/icons/Forum';
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

//import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import DialogTutorialPage from '../Onboarding/DialogTutorialPage';

import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import AccountCircle from '@material-ui/icons/AccountCircle';
//import MailIcon from '@material-ui/icons/Mail';
//import NotificationsIcon from '@material-ui/icons/Notifications';
import HomeIcon from '@material-ui/icons/Home';
//import BookmarksIcon from '@material-ui/icons/Bookmarks';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
//import SchoolIcon from '@material-ui/icons/School';
import WidgetsIcon from '@material-ui/icons/Widgets';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
//import EqualizerIcon from '@material-ui/icons/Equalizer';
import DonutSmallIcon from '@material-ui/icons/DonutSmall';
//import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import FeedbackIcon from '@material-ui/icons/Feedback';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
//import GroupIcon from '@material-ui/icons/Group';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import BuildIcon from '@material-ui/icons/Build';

import StatsBar from '../Charts/StatsBar';

const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
    DrawerPaper: {
        backgroundColor: theme.palette.backPrimary,
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
});

class Navigation extends Component { 
    static contextType = AuthUserContext;

    constructor(props) {
        super(props);
        
        this.state = {
            open: false,
            logoProgram: logo,
            isBrowser: true,
        };
    }

    componentDidMount() {
        //console.log('props', this.props) 
        document.addEventListener("deviceready", () => this.handleBrowserState(), false);
        if (this.props.defaultSet?.programLogo?.length > 10) (
            this.setState({ logoProgram : this.props.defaultSet.programLogo})
        )
        else if (false && window.location?.hostname?.includes('coach.rocky.ai') ) {
            // ignore logo for users panel
        }
        else if (this.context?.programLogo?.length > 10) {
            this.setState({ logoProgram : this.context.programLogo})
        }
    }

    componentDidUpdate(prevProps){
        if (this.props.defaultSet?.programLogo?.length > 10 && prevProps.programLogo !== undefined && this.props.defaultSet.programLogo !== prevProps.programLogo) (
            this.setState({ logoProgram : this.props.defaultSet.programLogo})
        )
    }

    TopBarListItem = (name, icon, link, onClickFunction=undefined) => {
        const {pathname} = this.props.location;
        const {theme} = this.props;
        var badgeCounter = 0;
        if(this.context!== undefined && this.context.newMessages!== undefined && this.context.newMessages.length>0){
            if(name==="Support"){
                badgeCounter = this.context.newMessages.filter(q => q.type === "SUPPORT").length || 0
            }
            else if(name==="Discuss"){
                badgeCounter = this.context.newMessages.filter(q => q.type === "MESSAGES").length || 0
            }
        }
        return(
            <div key={name} style={theme.NaviItem} onClick={onClickFunction!== undefined ? onClickFunction : void(0)}>
                {link === pathname || (link==='/home' && pathname==='/')
                    ?
                        <Link to={pathname} onClick={() => window.location.reload(false)}>
                            <div>
                                <Avatar style={{...theme.AppBarIcons, backgroundColor: theme.palette.white}}  >
                                    {icon}
                                </Avatar>
                            </div>
                        </Link>
                    :   (name === 'Settings' && (this.context.roles?.includes('OBSERVE') || this.context.roles?.includes('COACHEE')) && this.context.groups!==undefined && this.context.groups.length>0)
                        ?
                            <Badge badgeContent={" "} 
                                color="secondary" variant="dot">
                                <Link to={link === undefined ? pathname : link} style={{color: theme.palette.white}}>
                                    <IconButton size="small" style={{color: theme.palette.white}}>
                                        {icon}
                                    </IconButton>
                                    <br/>{name}
                                </Link>
                            </Badge>
                        :   
                            <Badge badgeContent={badgeCounter} color="error">
                                <Link to={link === undefined ? pathname : link} style={{color: theme.palette.white}}>
                                    <IconButton size="small" style={{color: theme.palette.white}}>
                                        {icon}
                                    </IconButton>
                                    <br/>{name}
                                </Link>
                            </Badge>
                }
            </div>
        );
    }
    MenuListItem = (name, icon, link) => {
        const {pathname} = this.props.location;
        const {theme} = this.props;
        var badgeCounter = 0;
        if(this.context!== undefined && this.context.newMessages!== undefined && this.context.newMessages.length>0){
            if(name==="Support"){
                badgeCounter = this.context.newMessages.filter(q => q.type === "SUPPORT").length || 0
            }
            else if(name==="Discuss"){
                badgeCounter = this.context.newMessages.filter(q => q.type === "MESSAGES").length || 0
            }
        }
        return(
            <ListItem button key={name} style={theme.DrawerItem}>
                {link === pathname || (link==='/home' && pathname==='/')
                    ?
                        <Link to={link} style={{ textDecoration: 'none' }}>
                            <div>
                                <Avatar 
                                    style={{...theme.AppBarIcons}}
                                >
                                    {icon}
                                </Avatar>
                            </div>
                        </Link>
                    :
                        <Badge badgeContent={badgeCounter} 
                            color="error">
                            <Link to={link} style={{ textDecoration: 'none' }}>
                                <div>
                                    <IconButton aria-label="show more"
                                                aria-controls={"mobileMenuId"}
                                                aria-haspopup="true"
                                                onClick={this.handleOpen}
                                    >
                                        {icon}
                                    </IconButton>
                                </div>
                                <div style={theme.DrawerItem}>
                                    {name}
                                </div>
                            </Link>
                        </Badge>
                }
            </ListItem>
        );
    }
    MobileAppMenuItem = (name, icon, link) => {
        const {pathname} = this.props.location;
        const {theme} = this.props;
        //console.log('current path', pathname, link);
        if(link === pathname){
            return(
                <MenuItem onClick={() => {this.handleClose()}}>
                    <div>
                        <IconButton color={"inherit"} style={theme.AppBarIcons}>
                            {icon}
                        </IconButton>
                        &nbsp;<span>{name}</span>
                    </div>
                </MenuItem>
            )
        }
        else{
            return(
                <MenuItem onClick={() => {this.handleClose(); this.props.history.push(link)}}>
                    <IconButton color={"inherit"} style={theme.AppBarIcons}>
                        {icon}
                    </IconButton>
                    &nbsp;<span>{name}</span>
                </MenuItem>
            )
        }
    }

    handleBrowserState = () => {
        this.setState({ isBrowser : false});
        window.removeEventListener("deviceready", () => this.handleBrowserState());
    }
    handleOpen = () => {
        this.setState({ open: true });
    }
    handleClose = () => {
        this.setState({ open: false , openMenuAdmin: false, openMenuApp: false});
    }
    handleMenuApp  = (event) => {
        this.setState({openMenuApp:true, anchorEl: event.currentTarget})
    }
    handleAdminApp  = (event) => {
        this.setState({openMenuAdmin:true, anchorEl: event.currentTarget})
    }
    handleOpenTutorials = () => {
        this.setState({handleTutorialOpen: true})
    }
    
    render() {
        const { classes, theme } = this.props;
        if (this.context !== undefined && this.context!== null && this.context.uid !== undefined && this.context.uid !== "" )
            return (
                    <React.Fragment>
                        <AppBar 
                            style={
                                (this.props.location.pathname === ROUTES.COACH_CONTENT)
                                ?   theme.AppBarDesktopCoach 
                                :   theme.AppBarDesktop 
                            }
                        >
                            <Toolbar>
                                <div className={classes.grow} >
                                    <div style={{minHeight: 60, display: 'flex', alignItems: 'center'}}>
                                        <Link to={ROUTES.HOME}>
                                            <span>
                                                <img src={this.state.logoProgram} 
                                                    style={{
                                                        maxWidth:60,
                                                        backgroundColor: theme.palette.darkMode? theme.palette.themeLightGrey : 'transparent',
                                                        margin: 4,
                                                        maxHeight: 60, 
                                                        height: 'auto', 
                                                        verticalAlign: 'middle',
                                                        borderRadius: theme.borderRadius,
                                                    }} 
                                                alt='Growth plan' />
                                            </span>
                                        </Link>
                                        &nbsp;
                                        <strong>
                                            {window.location?.href?.includes('/coach')
                                                ?
                                                    window.location?.href?.includes('/content')
                                                    ?   "Creator Tool - "
                                                    :   "Coach Panel - "
                                                :   ""
                                            }
                                            {this.context.username} 
                                        </strong>
                                    </div>
                                </div>
                                <div className={classes.sectionDesktop}>
                                    {(this.context.roles === undefined || (this.context.roles !== undefined && !this.context.roles.includes(ROLES.COACHADMIN) && !this.context.roles.includes(ROLES.REMOTECOACH)))
                                        &&
                                        <>
                                            <div style={{margin:"auto"}}>
                                                <StatsBar 
                                                    darkmode={true}
                                                    animateEveryPoint = { false}
                                                    animatePlus1Trigger = { false}
                                                    type='realtime'  
                                                />
                                            </div>
                                            <Divider orientation="vertical" flexItem />
                                        </>
                                    }
                                    {this.TopBarListItem("Home", <HomeIcon />, ROUTES.HOME)}
                                    {(this.context.roles?.includes(ROLES.ADMIN) || this.context.roles?.includes(ROLES.TEAM))
                                        &&
                                        <>
                                            {(window.location?.hostname?.includes('coach.rocky.ai') || window.location?.hostname?.includes('localhost'))
                                                ?
                                                    <>
                                                        {this.renderMenuAdmin()}
                                                        {this.TopBarListItem("Admin", <LibraryBooksIcon />, undefined, this.handleAdminApp )}
                                                    </>
                                                :
                                                    <>
                                                        {this.TopBarListItem("Admin", <LibraryBooksIcon />, undefined, () => {window.open('https://coach.rocky.ai/#/admin/feedbacks', 'Rocky for Coaches'); window.name = 'My-AI Coach';} ) }
                                                    </>
                                            }
                                            <Divider orientation="vertical" flexItem />
                                        </>
                                    }
                                    {(this.context.roles?.includes(ROLES.COACHADMIN) || this.context.roles?.includes(ROLES.REMOTECOACH))
                                        &&
                                        <>
                                            {(true || window.location?.hostname?.includes('localhost'))
                                                ?   this.TopBarListItem("Users", <RecordVoiceOverIcon />, ROUTES.COACH) 
                                                :   this.TopBarListItem("Users", <RecordVoiceOverIcon />, undefined, () => {window.open('https://coach.rocky.ai/#/coach', 'Rocky for Coaches'); window.name = 'My-AI Coach';} ) 
                                            }
                                            {this.TopBarListItem("Support", <HeadsetMicIcon /> , ROUTES.SUPPORT)}
                                            <Divider orientation="vertical" flexItem />
                                        </>
                                    }
                                    {this.context?.roles?.includes(ROLES.COACHADMIN) && this.state.isBrowser && this.context.adminPrograms!==undefined && this.context.adminPrograms.length>0
                                        &&
                                        <>
                                            {(true || window.location?.hostname?.includes('coach.rocky.ai') || window.location?.hostname?.includes('localhost'))
                                                ?   this.TopBarListItem("Creator", <AddShoppingCartIcon />, ROUTES.COACH_CONTENT) 
                                                :   this.TopBarListItem("Creator", <AddShoppingCartIcon />, undefined, () => {window.open('https://coach.rocky.ai/#/coach/content', 'Rocky for Coaches'); window.name = 'My-AI Coach';} ) 
                                            }
                                            <Divider orientation="vertical" flexItem />
                                        </>
                                    }
                                    {!(this.context.roles?.includes(ROLES.COACHADMIN) || this.context.roles?.includes(ROLES.REMOTECOACH))
                                        ? 
                                            <>
                                                {this.props.defaultSet?.programAvatarName?.length > 2
                                                    ?   this.TopBarListItem("AI Coach", <ForumIcon  />, ROUTES.ROUTINE) 
                                                    :   this.TopBarListItem("AI Coach", <RockyIcon  />, ROUTES.ROUTINE) 
                                                }
                                                {this.TopBarListItem("Quests", <WidgetsIcon />, ROUTES.CONTENT)}
                                                {this.TopBarListItem("Journey", <DirectionsIcon  />,  ROUTES.JOURNAL) }
                                                {this.TopBarListItem("Insights", <DonutSmallIcon />, ROUTES.STATS)}
                                                {this.TopBarListItem("Discuss", <PeopleAltIcon />, ROUTES.MESSAGES)}
                                            </>
                                        :
                                            (window.location?.hostname?.includes('coach.rocky.ai'))
                                            ?
                                                <>

                                                    {
                                                        //this.renderMenuApp()
                                                    }
                                                    {
                                                        //this.TopBarListItem("App", <RockyIcon />, undefined, this.handleMenuApp ) 
                                                    }
                                                    {
                                                        //this.TopBarListItem("App", <RockyIcon />, undefined, () => {window.open('https://app.rocky.ai', 'Rocky.ai APP'); window.name = 'Rocky for Teams';} ) 
                                                    }
                                                </>
                                            :
                                                <>
                                                    {this.renderMenuApp()}
                                                    {this.TopBarListItem("App", <RockyIcon />, undefined, this.handleMenuApp ) }
                                                </>
                                    }
                                    <Divider orientation="vertical" flexItem />
                                    {this.TopBarListItem("Tutorial", <HelpOutlineIcon />, undefined , this.handleOpenTutorials )}
                                    {(this.context.roles === undefined || (this.context.roles !== undefined && !(this.context.roles.includes(ROLES.COACHADMIN) || this.context.roles.includes(ROLES.REMOTECOACH))))
                                        && 
                                        <>
                                            {this.TopBarListItem("Support",<HeadsetMicIcon  /> , ROUTES.SUPPORT)}
                                        </>
                                    }
                                    {this.context.roles?.includes("COACHADMIN")
                                        ?   this.TopBarListItem("Settings", <BuildIcon  />, ROUTES.COACH_ADMIN)
                                        :   this.TopBarListItem("Settings", <AccountCircle  />, ROUTES.PROFILE)
                                    }
                                    {
                                    //this.context !== undefined && this.context !== null &&  this.context.anonymousSignup !== true &&
                                        this.TopBarListItem("Sign-out", <ExitToAppIcon />, undefined, this.props.firebase.doSignOut )
                                    }
                                </div>
                                {this.context.roles?.length > 0 
                                &&
                                    <DialogTutorialPage 
                                        handleOpen={this.state.handleTutorialOpen || false}
                                        extraInfoo={'extraInfo'}
                                        handleBadgeFamily = {(this.context.roles?.includes("COACHADMIN") || this.context.roles?.includes("REMOTECOACH") ) ? 'teams' : 'total'}
                                        handleClose={() => {this.setState({handleTutorialOpen: false})}}
                                    />
                                }
                            </Toolbar>
                        </AppBar>
                    </React.Fragment>
            ) 
        else return (
            <div>
                <AppBar style={{ boxShadow: 'none' }} position="static">
                    <Toolbar className="nav-container">
                        <div style={styles.logoBackground}>
                            <Link to={ROUTES.HOME}>
                                <img src={logo} style={styles.logo} alt="logo in menu" />
                            </Link>
                        </div>
                        <Button style={styles.button} color="inherit" component={Link} to={ROUTES.SIGN_IN}>
                            Sign In
                        </Button>
                    </Toolbar>
                </AppBar>
            </div>
        )
    }

    renderMenuAdmin () {
        return(
            <Menu
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                getContentAnchorEl={null}
                anchorEl={this.state.anchorEl}
                keepMounted
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={this.state.openMenuAdmin || false}
                onClose={this.handleClose}
            >
                { this.MobileAppMenuItem("Support", 
                    <Badge badgeContent={this.context.newMessages!== undefined && this.context.newMessages.filter(q => q.type === "SUPPORT").length ? this.context.newMessages.filter(q => q.type === "SUPPORT").length : 0} 
                        color="error">
                        <HeadsetMicIcon/>
                    </Badge>
                    , ROUTES.SUPPORT) }
                { this.MobileAppMenuItem("Discuss", 
                    <Badge badgeContent={this.context.newMessages!== undefined && this.context.newMessages.filter(q => q.type === "MESSAGES").length ? this.context.newMessages.filter(q => q.type === "MESSAGES").length : 0} 
                        color="error">
                        <PeopleAltIcon/>
                    </Badge>
                    , ROUTES.MESSAGES) }

                {this.context.roles?.includes(ROLES.ADMIN)  && this.state.isBrowser
                    ? this.MobileAppMenuItem("Contents", <ImportContactsIcon  />, ROUTES.ADMIN_CONTENTS)
                    : ""
                }
                {this.context.roles?.includes(ROLES.ADMIN)  && this.state.isBrowser
                    ? this.MobileAppMenuItem("User Chats", <LibraryBooksIcon  />, ROUTES.ADMIN_DIALOGUES)
                    : ""
                }
                {this.context.roles?.includes(ROLES.ADMIN) && this.state.isBrowser
                    ? this.MobileAppMenuItem("User Feedback", <FeedbackIcon />, ROUTES.ADMIN_FEEDBACKS)
                    : ""
                }
                {this.context.roles?.includes(ROLES.ADMIN) && this.state.isBrowser
                    ? this.MobileAppMenuItem("User Tasks", <DoneAllIcon />, ROUTES.ADMIN_TASKS)
                    : ""
                }
            </Menu>
        )
    }
    renderMenuApp () {
        return(
            <Menu
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                getContentAnchorEl={null}
                anchorEl={this.state.anchorEl}
                keepMounted
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={this.state.openMenuApp || false}
                onClose={this.handleClose}
            >
                {(this.context.roles?.includes("COACHADMIN") || this.context.roles?.includes("REMOTECOACH") || this.context.roles?.includes("ADMIN") || this.context.roles?.includes("TEAM"))
                    &&  this.MobileAppMenuItem("App View", <AccountBalanceIcon  />, ROUTES.PROGRAM) 
                }
                {this.props.defaultSet?.programLogo?.includes('http')
                    ?   this.MobileAppMenuItem("AI Chat", <ForumIcon  />, ROUTES.ROUTINE) 
                    :   this.MobileAppMenuItem("AI Chat", <RockyIcon  />, ROUTES.ROUTINE) 
                }
                { this.MobileAppMenuItem("Quests", <WidgetsIcon />, ROUTES.CONTENT) }
                { this.MobileAppMenuItem("Journey", <AssignmentTurnedInIcon/>, ROUTES.JOURNAL) }
                { this.MobileAppMenuItem("Insights", <DonutSmallIcon/>, ROUTES.STATS) }
                { this.MobileAppMenuItem("Discuss", <PeopleAltIcon/>, ROUTES.MESSAGES) }
            </Menu>
        )
    }
}

Navigation.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default compose(
    withFirebase,
    withStyles(styles, { withTheme: true}),
    withRouter
)(Navigation);
