import React, { Component } from 'react';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as ROUTES from "../../constants/routes";
import { AccessCheckCreatorTrialPhase } from '../../constants/roles';

import BadgeCard from '../Modules/BadgeCard';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import StorefrontIcon from '@material-ui/icons/Storefront';
import DialogSettingPlan from '../Profile/DialogSettingPlan';
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import BuildIcon from '@material-ui/icons/Build';

import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';

import logo from "../../images/rocky-logo-square.png";

const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
});

class CoachFeaturesActive extends Component {
    static contextType = AuthUserContext;
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            error: ''
        };
    }

    componentDidMount() {
        this._isMounted = true;
        //// This is a test
        //this.setState({ purchaseSuccess: {owned: true} })
    }
    componentWillUnmount() {
        this._isMounted = false;
        if(this.unsubscribe !== undefined){
            this.unsubscribe();
        }
    }

    render() {
        const { theme, classes} = this.props;

        let purchasedProduct = undefined;
        if (this.context?.purchasedProduct){
            purchasedProduct = this.context.purchasedProduct;
        }
        //console.log('this.context',this.context, "product", purchasedProduct)
        //console.log(process.env.REACT_APP_DEVICE_PLATFORM)
        return (
            <div>
                <DialogSettingPlan 
                    defaultSet={this.props.defaultSet || undefined}
                    user={this.context}
                    coach={true}
                    handleOpen={this.state.DialogSettingPlanOpen}
                    premiumOnly={true}
                    purchaseSuccessFlag={() => {
                        this.setState({nonSubscriber: false, premiumSubscriber: true})
                    }}
                    purchaseSuccessHandler={() => {
                        this.setState({DialogSettingPlanOpen: false, nonSubscriber: false, premiumSubscriber: true})
                    }}
                    handleClose={() => this.setState({DialogSettingPlanOpen : false})}
                />
                {(this.props.chosenStepType === 'creator' || this.props.chosenStepType === undefined)
                && 
                    <div 
                        //style={{...theme.cardBorder, backgroundColor: theme.palette.themeAlertRed+'0F', borderColor: theme.palette.themeAlertRed}}
                    >
                        {(this.props.unlocked === true || (purchasedProduct?.owned && purchasedProduct?.creator) || AccessCheckCreatorTrialPhase(this.context))
                        ?
                            <BadgeCard
                                key={"creator"}
                                backgroundColor= {"white"}
                                image={undefined} 
                                avatarImage = {<AddShoppingCartIcon style={{fontSize: 80, color: theme.palette.themeAlertGreen}}/>}
                                //title = { badgeItem.target!== undefined ? "Next milestone:  (" + badgeItem.target + ' Points)' : 'Next milestone:' }
                                title = {"Open Creator Tool"}
                                subtitle = {!AccessCheckCreatorTrialPhase(this.context) ? undefined : "Manage coaching content"}
                                strongSubtitle = {false}
                                onClick={ () => this.props?.history?.push(ROUTES.COACH_CONTENT) }  
                                clickText= {'Open'}
                            />
                        :
                            <BadgeCard
                                key={"creator"}
                                backgroundColor= {"white"}
                                image={undefined} 
                                avatarImage = {<StorefrontIcon style={{fontSize: 50, color: theme.palette.themeAlertOrange}}/>}
                                title = {!AccessCheckCreatorTrialPhase(this.context) ? "Activate Creator Tool" : "Manage coaching content"}
                                subtitle = {"Subscribe to the business packages"}
                                strongSubtitle = {false}
                                onClick={ () => this.setState({DialogSettingPlanOpen: true})}  
                                clickText= {'Subscribe'}
                            />
                        }
                        <p style={theme.textSupportCenter}> 
                            Customize App &bull; Create Coaching Plan
                        </p>
                    </div>
                }
                {(this.props.chosenStepType === 'teams' || this.props.chosenStepType === undefined)
                && 
                    <div 
                        //style={{...theme.cardBorder, backgroundColor: theme.palette.themeAlertOrange+'0F', borderColor: theme.palette.themeAlertOrange}}
                    >
                        {(this.props.unlocked === true || (this.context?.seatsMonthlyToGive || 0) + (this.context?.seatsToGive || 0) + 0) > 0
                            ?
                                <BadgeCard
                                    key={"coach"}
                                    backgroundColor= {"white"}
                                    image={undefined} 
                                    avatarImage = {<RecordVoiceOverIcon style={{fontSize: 80, color: theme.palette.themeAlertGreen}}/>}
                                    //title = { badgeItem.target!== undefined ? "Next milestone:  (" + badgeItem.target + ' Points)' : 'Next milestone:' }
                                    title = {"Open Coach Panel"}
                                    subtitle = {""+((this.context?.seatsMonthlyToGive || 0) + (this.context?.seatsToGive || 0) + 0)+" seats available"}
                                    strongSubtitle = {false}
                                    onClick={ () => this.props?.history?.push(ROUTES.COACH) }  
                                    clickText= {'Open'}
                                />
                            :
                                <BadgeCard
                                    key={"coach"}
                                    backgroundColor= {"white"}
                                    image={undefined} 
                                    avatarImage = {<StorefrontIcon style={{fontSize: 50, color: theme.palette.themeAlertOrange}}/>}
                                    //title = { badgeItem.target!== undefined ? "Next milestone:  (" + badgeItem.target + ' Points)' : 'Next milestone:' }
                                    title = {"Add seats to your account"}
                                    subtitle = {"Purchase seats or add a subscription"}
                                    strongSubtitle = {false}
                                    onClick={ () => this.setState({DialogSettingPlanOpen: true})}  
                                    clickText= {'Purchase'}
                                />
                        }
                        <p style={theme.textSupportCenter}> 
                            Invite Users &bull; Groups & Teams
                        </p>
                    </div>
                }
                {(this.props.chosenStepType === 'brand')
                    && 
                        <div 
                            //style={{...theme.cardBorder, backgroundColor: theme.palette.secondary.light+'0F'}}
                        >
                            <BadgeCard
                                key={"plan"}
                                backgroundColor= {"white"}
                                image={undefined} 
                                avatarImage = {<BuildIcon style={{fontSize: 80, color: theme.palette.themeAlertOrange}}/>}
                                title = {"Add your Brand"}
                                subtitle = {"Change brand and app settings"}
                                strongSubtitle = {false}
                                onClick={ () => this.props?.history?.push(ROUTES.COACH_ADMIN) }  
                                clickText= {'Open'}
                            />
                            <p style={theme.textSupportCenter}> 
                                Add your Brand &bull; Modify white-label app
                            </p>
                        </div>
                    }
                {(this.props.chosenStepType === 'plan')
                && 
                    <div 
                        //style={{...theme.cardBorder, backgroundColor: theme.palette.secondary.light+'0F'}}
                    >
                        <BadgeCard
                            key={"plan"}
                            backgroundColor= {"white"}
                            image={undefined} 
                            avatarImage = {<StorefrontIcon style={{fontSize: 80, color: theme.palette.themeAlertOrange}}/>}
                            //title = { badgeItem.target!== undefined ? "Next milestone:  (" + badgeItem.target + ' Points)' : 'Next milestone:' }
                            title = {"Upgrade your account"}
                            subtitle = {"Purchase seats or add a subscription"}
                            strongSubtitle = {false}
                            onClick={ () => this.setState({DialogSettingPlanOpen: true})}  
                            clickText= {'Purchase'}
                        />
                        <p style={theme.textSupportCenter}> 
                            Test AI Coach &bull; 5-minute self-coaching
                        </p>
                    </div>
                }
                {(this.props.chosenStepType === 'support')
                && 
                    <div 
                        //style={{...theme.cardBorder, backgroundColor: theme.palette.secondary.light+'0F'}}
                    >
                        <BadgeCard
                            key={"support"}
                            backgroundColor= {"white"}
                            image={undefined} 
                            avatarImage = {<HeadsetMicIcon style={{fontSize: 80, color: theme.palette.themeAlertOrange}}/>}
                            //title = { badgeItem.target!== undefined ? "Next milestone:  (" + badgeItem.target + ' Points)' : 'Next milestone:' }
                            title = {"Support Chats"}
                            subtitle = {""}
                            strongSubtitle = {false}
                            onClick={ () => this.props?.history?.push(ROUTES.SUPPORT) }  
                            clickText= {'Open'}
                        />
                        <p style={theme.textSupportCenter}> 
                            Chat with Users &bull; Hotline
                        </p>
                    </div>
                }
                {(this.props.chosenStepType === 'app' || this.props.chosenStepType === undefined)
                && 
                    <div 
                        //style={{...theme.cardBorder, backgroundColor: theme.palette.secondary.light+'0F'}}
                    >
                        <BadgeCard
                            key={"coachingapp"}
                            backgroundColor= {"white"}
                            image={this.props.defaultSet?.programLogo?.includes('http') ? this.props.defaultSet.programLogo : logo } 
                            title = {"Start Enduser App"}
                            subtitle = {"Explore the endusers AI experience"}
                            strongSubtitle = {false}
                            onClick={ () => this.props?.history?.push(ROUTES.PROGRAM) }  
                            clickText= {'Open'}
                        />
                        <p style={theme.textSupportCenter}> 
                            Test AI Coach &bull; 5-minute self-coaching
                        </p>
                    </div>
                }
            </div>
        );
    }
  
}

CoachFeaturesActive.propTypes = {
    classes: PropTypes.object.isRequired
};

export default compose(
    withRouter,
    withStyles(styles, { withTheme: true }),
    withFirebase,
)(CoachFeaturesActive);
