import React, { Component } from 'react';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
//import {or, where} from 'firebase/firestore'
import ContentSuggestCarousel from '../Content/ContentSuggestCarousel';
import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';
import LoadingProgress from '../Modules/LoadingProgress';
//import CircularProgress from '@material-ui/core/CircularProgress';
import {OverlapQualityFilter, FindTextValueInDict} from '../Modules/LoadCategoriesAndQualities';
import VizSensor from 'react-visibility-sensor';
import pixel from "../../images/pixel.png";
import InfiniteScroll from "react-infinite-scroll-component";
import ContentItemCard, {ContentProgramOverwrite} from '../Content/ContentItemCard';
import DialogContentItem from '../Content/DialogContentItem';
import Masonry from 'react-masonry-css';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

import Card from '@material-ui/core/Card';

//import Button from '@material-ui/core/Button';

const styles = theme => ({
    masonry:{
        width: "auto",
        display: "-webkit-box",
        display: '-ms-flexbox',
        display: "flex",
        marginLeft: -10
    },
    masonryColumn :{
        paddingLeft: 10,
        backgroundClip: "padding-box",
    },
    grow: {
        flexGrow: 1,
    }
});


class InfiniteContent extends Component {
    static contextType = AuthUserContext;
    _isMounted = false;
    showCounter = 0;

    constructor(props) {
        super(props);
        this.dialogRef = React.createRef();
        //console.log('props deliverd via children', props)
        var startingTab = 'Content';
        var contentBookmarked = [];
        if (this.props.user?.contentBookmarked !== undefined && this.props.user?.contentBookmarked.length >= 2) {
            //startingTab = 'Bookmarks';
            contentBookmarked = this.props.user.contentBookmarked;
        }
        this.state = {
            items: [],
            itemsLoaded: [],
            intentsTagsLoaded: [],
            qualitiesLoaded: [],
            value: startingTab,
            initialMainQuality: '',
            loading: true,
            scrolled: false,
            sisterQualities: [],
            contentBookmarked: contentBookmarked,
            headerVisible: true,
            searchKeyIndex: 1,
            userRoleFilter: 'default'
        };
    }

    componentDidMount() {
        //console.log('Infinitencontent', this.props)
        this._isMounted = true;
        try{
            //document.body.scrollTop = document.documentElement.scrollTop = 0;
            //console.log('scroll into on start')
            if(this.props.scrollIntoViewDisabled !== true && this.dialogRef !== undefined ) this.dialogRef.current.scrollIntoView({behaviour: 'smooth'});
        }
        catch (err) {
            console.log('No scroll to top');
        }
        ////// add user role filter for content selection
        var userRoleFilter = 'default';
        if(this.context.onboardingResponses?.length > 0){
            /// 'executive', 'professional', 'seeker', 'student']}
            if(this.context.onboardingResponses?.includes('Student'))
                userRoleFilter = 'student';
            if(this.context.onboardingResponses?.includes('Teenager'))
                userRoleFilter = 'teenager';
            if(this.context.onboardingResponses?.includes('Available'))
                userRoleFilter = 'seeker';
            if(this.context.onboardingResponses?.includes('Professional'))
                userRoleFilter = 'professional';
            if(this.context.onboardingResponses?.includes('Executive'))
                userRoleFilter = 'executive';
        }
        //console.log('Content Container mounted', userRoleFilter);
        this.setState({
            initialMainQuality: this.props.user?.currentLeadershipQuality || 'focus',
            userRoleFilter
        })
        try {
            var sisterQualities = this.props.leadershipQualities?.find(c => c.value === this.props.user?.currentLeadershipQuality)?.sisterQualities || [];
            this.setState({ sisterQualities: sisterQualities });
        }
        catch (err) {
            console.log('issue with finding the sister modules');
        }
        var contentIds = this.props.resultOfContentSearch;
        this.loadMoreContent(contentIds);
    }
    componentWillUnmount() {
        this._isMounted = false;
        //this.setState({ loading: false });
    }
    componentDidUpdate() {
        if (this._isMounted && this.props.user?.currentLeadershipQuality !== this.state.initialMainQuality) {
            var startingTab = 'Main topic';
            //if (this.state.contentBookmarked !== undefined && this.state.contentBookmarked.length >= 1) startingTab = 'Bookmarks';
            this.setState({
                sisterQualities: [],
                initialMainQuality: this.props.user?.currentLeadershipQuality || 'focus',
                value: startingTab,
            })
            try {
                var sisterQualities = this.props.leadershipQualities?.find(c => c.value === this.props.user?.currentLeadershipQuality)?.sisterQualities || [];
                this.setState({ sisterQualities: sisterQualities });
            }
            catch (err) {
                console.log('issue with finding the sister modules');
            }
        }
    }
    shouldComponentUpdate(nextProps, nextState) {  
        if (nextProps.rerender === false) {  
            //console.log('no rerender')
            return false;  
        }  
        //console.log(' rerender')
        return true;  
    }

    scrollUpAgain = () => {
        try{
            //document.body.scrollTop = document.documentElement.scrollTop = 0;
            console.log('scroll into on upagain')
            if(this.props.dialogRefParent !== undefined ) this.props.dialogRefParent();
            else if(this.dialogRef !== undefined ) {
                this.dialogRef.scrollIntoView({ behavior: "smooth" });
                //this.dialogRef.current.scrollIntoView({behaviour: 'smooth'});
            }
        }
        catch (err) {
            console.log(err)
            console.log('No scroll to top');
        }
    }

    handleOpen(contentData, type="content")  {
        if(this.props.handleOpen !== undefined){
            this.props.handleOpen(contentData, type);
            if(this.props.messageSubmit !== undefined){
                //console.log('ontentData.subTitle', contentData.subtitle)
                this.props.messageSubmit(contentData.subtitle)
            }
        }
        else{
            //console.log('DialogContentPreviewOpen')
            this.setState({DialogContentPreviewOpen: true, contentData});
        }
    }

    fetchMoreData = () => {
        //console.log('Step fetchMoreData')
        if(this.props.visualLimit > 1 && this.props.visualLimit < this.state.itemsLoaded?.length){
            //console.log('Step fetchMoreData visual < ', this.props.visualLimit , this.state.itemsLoaded?.length)
            if(this._isMounted){
                this.setState({loadingMore: false, hasMore: false, loading: false});
            }
        }
        else if(this._isMounted){
            //console.log('Step fetchMoreData open ', this.props.visualLimit , this.state.itemsLoaded?.length)
            try{
                this.loadMoreContent();
            }
            catch(e){
                //console.log('error fetchMoreData', e)
                this.setState({loadingMore: false, hasMore: false, loading: false});
            }
        }
        /***
        // a fake async api call like which sends
        // 20 more records in 1.5 secs
        setTimeout(() => {
            this.setState({  items: this.state.items.concat(Array.from({ length: 20 }))  });
        }, 1500);
        */
    };

    loadMoreContent = (contentIds = undefined) => {
        console.log('loadMoreContent', this.props)
        const diagnosticFlag = true;
        ///// get another dournd
        this.setState({loadingMore: true})
        ///// random
        var chars = '789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijk';
        var randomChar = this.state.randomChar;
        var randomDirection = this.state.randomDirection;
        if(randomChar === undefined) {
            randomChar = chars[Math.floor(Math.random() * chars.length)];
        }
        randomChar = undefined
        if(randomDirection === undefined) randomDirection = ['<=', '=>'][[Math.floor(Math.random())]];
        else if(randomDirection === '<=') randomDirection = '>=';
        else if(randomDirection === '>=') randomDirection = '<=';
        /////
        var unlikedContent = [];
        if (this.props.contentToHide !== undefined){ 
            unlikedContent = unlikedContent.concat(this.props.contentToHide);
        }
        else if (this.props.user?.contentUnliked?.length > 0){ 
            unlikedContent = this.props.user.contentUnliked;
        }
        var items = this.state.items;
        var itemsLoaded = this.state.itemsLoaded;
        var intentsTagsLoaded = this.state.intentsTagsLoaded;
        var intentsInitialTags = (this.state.intentsInitialTags?.length>0 ? this.state.intentsInitialTags : []);
        var qualitiesLoaded = this.state.qualitiesLoaded;
        var lastVisible = this.state.lastLoadedContent;
        // defining what to load
        var phasesLoaded = this.state.phasesLoaded;
        if(diagnosticFlag)
            console.log('Step 0.0 loadMoreContent', contentIds, "lastVisible", lastVisible, "phasesLoaded", phasesLoaded)
        if (phasesLoaded === undefined || phasesLoaded === null){
            phasesLoaded = [];
            if(diagnosticFlag)
                console.log("Step 0.a  phasesLoaded start", phasesLoaded)
        }
        // the query build parameter
        var db = undefined;
        /////// build the default one
        var category = "clarity";
        if(this.context.currentCategory !== undefined && this.context.currentCategory !== ""){
            category = this.context.currentCategory;
        }
        if(this.props.filterCategory?.length > 1){
            category = this.props.filterCategory;
        }
        var quality = (FindTextValueInDict(this.props.firebase, this.props.leadershipQualities, this.context.currentLeadershipQuality, 'value') || "fritzfalseer");
        db = this.props.firebase.contents()
        .where('active', '==', true)
        .where('leadership_category', '==', category)
        if(diagnosticFlag)
            console.log('Step 0.b idss in there 1b', category)
        /////// add or replace with quality the default one
        var filterIntent = '';
        if(this.props.filterIntent?.length > 2 && !(this.state.intentsTagsLoaded?.length > 0)){
            var filterIntentList = [];
            ////
            if (typeof this.props.filterIntent === 'string'){
                filterIntentList = this.props.filterIntent.split('_');
            }
            else{
                filterIntentList = this.props.filterIntent;
            }
            filterIntentList.map(item => {
                if(!["inform", "question", "chat", "request", "assessment", "intention", "conclusions", "request", "intention", "accomplishment"].includes(item)){
                    intentsInitialTags.push(item)
                }
            })
            this.setState({intentsInitialTags});
            filterIntent = filterIntentList[filterIntentList.length-1];
            if (diagnosticFlag)
                console.log('Step 0.d Start filterIntent props', filterIntent, this.props.filterIntent, filterIntentList, intentsInitialTags)
            //// prepare list
            if(this.props.filterQuality?.length > 2){
                //db = db.where('intentSearchTags', 'array-contains', filterIntent )
            }
            else{
                //db = this.props.firebase.contents()
                //.where('active', '==', true)
                //db = db.where('intentSearchTags', 'array-contains', filterIntent )
                //console.log('filterIntent', filterIntent)
            }
            if(phasesLoaded?.length === 0){
                phasesLoaded = ["contentRecommendationsIds"];
                intentsTagsLoaded = [filterIntent];
                //console.log('this.props.filterIntent',intentsTagsLoaded )
            }
        }
        if (diagnosticFlag){
            console.log('Step 0.f start with more info 1x', quality, category, filterIntent)
        }
        if(this.props.quotes){ db = db.where('category', '==', 'quotes') }
        else if(this.props.noQuotes){ db = db.where('category', '==', 'tips') }
        /////// add or replace with quality the default one
        if(!phasesLoaded?.includes("contentFilterQuality") && this.props.filterQuality?.length > 2 && this.props.filterQuality !== 'default'){
            quality = this.props.filterQuality;
            if(diagnosticFlag)
                console.log('Step 0.c idss in there 1c', quality)
            if(this.props.filterTodayQuality?.length > 2){
                console.log('two quals', quality, this.props.filterTodayQuality)
                db = this.props.firebase.contents()
                .where('active', '==', true)
                db = db.where('leadership_quality', 'array-contains-any', [quality, this.props.filterTodayQuality]) 
            }
            else{
                db = db.where('leadership_quality', 'array-contains', quality)
            }
            phasesLoaded.push("contentFilterQuality")
            phasesLoaded.push("contentIntentsPrograms")
            phasesLoaded.push("contentGrowthPath")
            phasesLoaded.push("contentCategories")
            phasesLoaded.push("contentPlaceholder")
            ///// whenever we check based on quality we have to add the hide option
            if(this.context.hidePublicContent === true && this.context.programs?.length > 0){
                programsFilter = this.context.programs
                if (programsFilter.length > 10) {
                    programsFilter = programsFilter.slice(0,9)
                }
                db = db.where('program', 'in',  programsFilter)
            }
        }
        else if(!phasesLoaded?.includes("contentRecommendationsIds") && contentIds!== undefined && contentIds !== null && contentIds.length > 0){
            if(diagnosticFlag)
                console.log('Step idss in there 2a', contentIds)
            phasesLoaded.push("contentRecommendationsIds")
            if (contentIds.length > 10) {
                contentIds = contentIds.slice(0,9)
            }
            if(contentIds.length > 1){
                //console.log("search results", contentIds)
                db = this.props.firebase.contents()
                .where(this.props.firebase.fieldPath.documentId(), 'in', contentIds)
                .where('active', '==', true)
                ///// whenever we check based on quality we have to add the hide option
                if(this.context.hidePublicContent === true && this.context.programs?.length > 0){
                    programsFilter = this.context.programs
                    if (programsFilter.length > 10) {
                        programsFilter = programsFilter.slice(0,9)
                    }
                    db = db.where('program', 'in',  programsFilter)
                }
            }
        }
        else{
            if(diagnosticFlag)
                console.log('Step from 2 Start Search else s in there')
            if(this.state.lastLoadedContent !== undefined && this.state.lastLoadedContent !== null) {
                //console.log('start contentRecommendationsIds after', this.state.lastLoadedContent)
                db = db.startAfter(this.state.lastLoadedContent)
            }
            var contentRecommendationsIds = [];
            var intentsSearchIds = [];
            var programsFilter = [];
            //// first load the full course materials 
            if(this.props.filterFlagCourse && !phasesLoaded.includes("contentCourse")){
                phasesLoaded.push("contentCourse")
                if(this.props.filterTodayQuality?.length > 2){
                    db = this.props.firebase.contents()
                    .where('active', '==', true)
                    .where('leadership_quality', 'array-contains', quality)
                }
                else{
                    db = this.props.firebase.contents()
                    .where('active', '==', true)
                    //.where('leadership_quality', 'array-contains', quality)
                    .where('leadership_quality', 'array-contains-any', [quality, (this.props.filterTodayQuality || "XFALSE")]) 
                }
                if (false && this.props.filterFlagCourse ){
                    db = db.where('isCourse', '==', true)
                }
                if(lastVisible !== undefined && lastVisible!== null) {
                    //console.log('start after', this.state.lastLoadedContent)
                    db = db.startAfter(lastVisible)
                }
                ///// whenever we check based on quality we have to add the hide option
                if(this.context.hidePublicContent === true && this.context.programs?.length > 0){
                    programsFilter = this.context.programs
                    if (programsFilter.length > 10) {
                        programsFilter = programsFilter.slice(0,9)
                    }
                    db = db.where('program', 'in',  programsFilter)
                }
                if(diagnosticFlag)
                    console.log('Step 2.a content courses 2a by qaulity', quality, phasesLoaded)
            }
            else if(!phasesLoaded.includes("contentRecommendationsIds")){
                if(diagnosticFlag)
                    console.log('Step 3.a. first casr', phasesLoaded)
                phasesLoaded.push("contentRecommendationsIds")
                if (this.context.contentBookmarked !== undefined && this.context.contentBookmarked.length > 0 ){
                    contentRecommendationsIds = contentRecommendationsIds.concat(this.context.contentBookmarked);
                }
                if (this.context.contentLiked !== undefined && this.context.contentLiked.length > 0 ){
                    contentRecommendationsIds = contentRecommendationsIds.concat(this.context.contentLiked);
                }
                contentRecommendationsIds.sort( () => 0.5 - Math.random() );
                if (contentRecommendationsIds.length > 10) {
                    contentRecommendationsIds = contentRecommendationsIds.slice(0,9)
                }
                /// adds the recommended content to front
                if (this.context.contentRecommendations !== undefined && this.context.contentRecommendations.length > 0 ){
                    contentRecommendationsIds = this.context.contentRecommendations.concat(contentRecommendationsIds);
                }
                contentRecommendationsIds.sort( () => 0.5 - Math.random() );
                //console.log("contentRecommendationsIds", contentRecommendationsIds)
                ///// ADD last notification on top
                if(this.props.user?.contentLastNotification !== undefined && this.props.user?.contentLastNotification !== ''){
                    ///// add the recent notification id into the highlights
                    if(!contentRecommendationsIds.includes(this.props.user.contentLastNotification)){
                        contentRecommendationsIds.unshift(this.props.user.contentLastNotification);
                        //console.log('added bookmark on top', this.props.user.contentLastNotification)
                    }
                    else{
                        var index = contentRecommendationsIds.indexOf(this.props.user.contentLastNotification);
                        if (index !== -1) {
                            contentRecommendationsIds.splice(index, 1);
                            //console.log('removed bookmark on top', this.props.user.contentLastNotification)
                        }
                        contentRecommendationsIds.unshift(this.props.user.contentLastNotification);
                        //console.log('readded bookmark on top', this.props.user.contentLastNotification)
                    }
                    
                }
                /// do the query with recommendations
                if (contentRecommendationsIds.length > 10) {
                    contentRecommendationsIds = contentRecommendationsIds.slice(0,9)
                }
                //console.log('list', contentRecommendationsIds)
                if(contentRecommendationsIds?.length > 1){
                    db = this.props.firebase.contents()
                    .where(this.props.firebase.fieldPath.documentId(), 'in', contentRecommendationsIds)
                    .where('active', '==', true)
                    //IF CASES NEED TO BE SOLVED IN THE CREATION ARELADY
                    if(this.props.quotes){ db = db.where('category', '==', 'quotes') }
                    ///// whenever we check based on quality we have to add the hide option
                    if(this.context.hidePublicContent === true && this.context.programs?.length > 0){
                        programsFilter = this.context.programs
                        if (programsFilter.length > 10) {
                            programsFilter = programsFilter.slice(0,9)
                        }
                        db = db.where('program', 'in',  programsFilter);
                        //console.log("contentRecommendationsIds", programsFilter, contentRecommendationsIds)
                    }
                }
            }
            else if(!phasesLoaded.includes("contentIntentsQuality") && intentsTagsLoaded.length>0){
                phasesLoaded.push("contentIntentsQuality")
                intentsSearchIds = intentsTagsLoaded;
                if(diagnosticFlag)
                    console.log('Step 4.a. qual inn', quality, intentsSearchIds)
                intentsSearchIds.sort( () => .5 - Math.random() );
                if (intentsSearchIds.length > 5) {
                    intentsSearchIds = intentsInitialTags.concat(intentsSearchIds.slice(0,4))
                }
                else if(intentsSearchIds.length === 0){
                    intentsSearchIds = ["XFALSE"]
                }
                if(this.props.filterTodayQuality?.length > 2){
                    db = this.props.firebase.contents()
                    .where('active', '==', true)
                    .where('leadership_quality', 'array-contains', quality)
                }
                else{
                    db = this.props.firebase.contents()
                    .where('active', '==', true)
                    //.where('leadership_quality', 'array-contains', quality)
                    .where('leadership_quality', 'array-contains-any', [quality, (this.props.filterTodayQuality || "XFALSE")]) 
                }
                ///// whenever we check based on quality we have to add the hide option
                if(this.context.hidePublicContent === true && this.context.programs?.length > 0){
                    programsFilter = this.context.programs
                    if (programsFilter.length > 10) {
                        programsFilter = programsFilter.slice(0,9)
                    }
                    db = db.where('program', 'in',  programsFilter)
                }
                if(intentsSearchIds?.length === 1){
                    db = db.where('intentTags.'+intentsSearchIds[0], '==', true)
                }
                else{
                    db = db.where('intentSearchTags', 'array-contains-any', intentsSearchIds)
                }
                if(this.props.quotes){ db = db.where('category', '==', 'quotes') }
                if(lastVisible !== undefined && lastVisible!== null) {
                    //console.log('start after', this.state.lastLoadedContent)
                    db = db.startAfter(lastVisible)
                }
                else if(lastVisible === undefined && randomChar !== undefined) {
                    db = db.where(this.props.firebase.fieldPath.documentId(), randomDirection, randomChar)
                    //console.log('add random', randomChar)
                }
                intentsTagsLoaded = intentsInitialTags || intentsTagsLoaded;
                if (diagnosticFlag)
                    console.log('Step 4.b. overwrite intentsInitialTags', intentsTagsLoaded, intentsInitialTags, phasesLoaded)
            }
            else if (!phasesLoaded.includes("contentIntentsPrograms")){
                phasesLoaded.push("contentIntentsPrograms")
                intentsSearchIds = intentsTagsLoaded;
                if (intentsSearchIds.length > 10) {
                    intentsSearchIds = intentsSearchIds.slice(0,9)
                }
                else if(intentsSearchIds.length === 0){
                    intentsSearchIds = ["XFALSE"]
                }
                if(diagnosticFlag)
                   console.log('Step 5.a. contentIntentsPrograms', category, intentsSearchIds, this.context.hidePublicContent)
                db = this.props.firebase.contents()
                .where('active', '==', true)
                if(intentsSearchIds?.length === 1){
                    db = db.where('intentTags.'+intentsSearchIds[0], '==', true)
                }
                else{
                    db = db.where('intentSearchTags', 'array-contains-any', intentsSearchIds )
                }
                if(this.context.hidePublicContent === true && this.context.programs !== undefined && this.context.programs.length > 0){
                    ////// programs filter
                    programsFilter = this.context.programs
                    if (programsFilter.length > 10) {
                        programsFilter = programsFilter.slice(0,9)
                    }
                    db = db.where('program', 'in',  programsFilter)
                    //db = db.where('program', '!=', 'default')
                    if(diagnosticFlag)
                        console.log('Step 5.b. free plus', intentsSearchIds, programsFilter)
                }
                else if(!(this.context.programs?.includes('default')) || this.context.programs.length > 1){
                    ////// programs filter
                    programsFilter = this.context.programs
                    ///// needed to reduce to stay in 30 connections boundaries of FB  
                    if (programsFilter.length > 6) {
                        programsFilter = programsFilter.slice(0,5)
                    }
                    db = db.where('program', 'in',  programsFilter)
                    //db = db.where('program', '!=', 'default')
                    if(diagnosticFlag)
                        console.log('Step 5.c. dedicated plus', intentsSearchIds, programsFilter)
                }
                else{
                    db = db.where('public', '==', true)
                }
                if(this.props.quotes){ db = db.where('category', '==', 'quotes') }
                if(lastVisible !== undefined && lastVisible!== null) {
                    if(diagnosticFlag)
                        console.log('Step 5.d. start after', this.state.lastLoadedContent)
                    db = db.startAfter(lastVisible)
                }
                else if(false && lastVisible === undefined && randomChar !== undefined) {
                    db = db.where(this.props.firebase.fieldPath.documentId(), randomDirection, randomChar)
                    if(diagnosticFlag)
                        console.log('Step 5.e. add  x random', randomChar)
                }
                //console.log("searchIntents", intentsSearchIds)
            }
            else if(!phasesLoaded.includes("contentIntentsCategory") && intentsTagsLoaded.length>0){
                phasesLoaded.push("contentIntentsCategory")
                intentsSearchIds = intentsTagsLoaded;
                intentsSearchIds.sort( () => .5 - Math.random() );
                if (intentsSearchIds.length > 5) {
                    intentsSearchIds = intentsSearchIds.slice(0,4)
                }
                else if(intentsSearchIds.length === 0){
                    intentsSearchIds = ["XFALSE"]
                }
                if(diagnosticFlag)
                    console.log("Step 6.a. searchIntents", intentsSearchIds)
                //('program', category, intentsSearchIds)
                db = this.props.firebase.contents()
                .where('active', '==', true)
                .where('leadership_category', '==', category)
                if(intentsSearchIds?.length === 1){
                    db = db.where('intentTags.'+intentsSearchIds[0], '==', true)
                }
                else{
                    db = db.where('intentSearchTags', 'array-contains-any', intentsSearchIds)
                }
                if(this.props.quotes){ db = db.where('category', '==', 'quotes') }
                if(lastVisible !== undefined && lastVisible!== null) {
                    //console.log('start after', this.state.lastLoadedContent)
                    db = db.startAfter(lastVisible)
                }
                else if(lastVisible === undefined && randomChar !== undefined ) {
                    db = db.where(this.props.firebase.fieldPath.documentId(), randomDirection, randomChar)
                    //console.log('add random', randomChar)
                }
                if(diagnosticFlag)
                    console.log("Step 6.d. searchIntents", intentsSearchIds)
            }
            else if (!phasesLoaded.includes("contentGrowthPath")){
                phasesLoaded.push("contentGrowthPath")
                var leadershipQualityItems = [quality];
                if(diagnosticFlag)
                    console.log('Step 7.a. contentGrowthPath', this.props.mainQualitiesIds, this.props)
                var mainQualitiesIds = this.props.mainQualitiesIds;
                if (mainQualitiesIds?.length>0){
                    leadershipQualityItems = [quality].concat(mainQualitiesIds);
                    if (leadershipQualityItems.length > 10) {
                        leadershipQualityItems = leadershipQualityItems.slice(0,9)
                    }
                    else if(leadershipQualityItems.length === 0){
                        leadershipQualityItems = ["XFALSE"]
                    }
                }
                db = this.props.firebase.contents()
                .where('active', '==', true)
                .where('leadership_quality', 'array-contains-any', leadershipQualityItems)
                ///// whenever we check based on quality we have to add the hide option
                if(this.context.hidePublicContent === true && this.context.programs?.length > 0){
                    programsFilter = this.context.programs
                    if (programsFilter.length > 10) {
                        programsFilter = programsFilter.slice(0,9)
                    }
                    db = db.where('program', 'in',  programsFilter)
                }
                if(intentsTagsLoaded?.length >= 1){
                    db = db.where('intentTags.'+intentsTagsLoaded[0], '==', true)
                    if(diagnosticFlag)
                        console.log('Step 7.e. addd intenten in growth path', intentsTagsLoaded[0])
                }
                if(lastVisible === undefined && randomChar !== undefined ) {
                    db = db.where(this.props.firebase.fieldPath.documentId(), randomDirection, randomChar)
                    //db = db.where('title', '>=', randomChar)
                    //console.log('add random', randomChar)
                }
                else if(!(intentsTagsLoaded?.length >= 1)){
                    db = db.orderBy('contentVotes', 'desc')
                }
                if(this.props.quotes){ db = db.where('category', '==', 'quotes') }
                if(lastVisible !== undefined && lastVisible !== null) {
                    //console.log('start after', this.state.lastLoadedContent)
                    //console.log('start after', lastVisible)
                    db = db.startAfter(lastVisible)
                }
                if(diagnosticFlag)
                    console.log('Step 7.f. after sister 3ex', leadershipQualityItems, phasesLoaded)
            }
            else if(!phasesLoaded.includes("contentQualities")){
                phasesLoaded.push("contentQualities")
                if (qualitiesLoaded!== undefined && qualitiesLoaded.length > 10) {
                    qualitiesLoaded = qualitiesLoaded.slice(0,9)
                }
                else if(qualitiesLoaded === undefined || (qualitiesLoaded!== undefined && qualitiesLoaded.length === 0)){
                    //qualitiesLoaded = ['focus']
                    qualitiesLoaded = [quality]
                }
                if(diagnosticFlag)
                    console.log('Step 8.a. contentQualities', qualitiesLoaded)
                db = this.props.firebase.contents()
                .where('active', '==', true)
                .where('leadership_quality', 'array-contains-any', (qualitiesLoaded  || ["XFALSE"] ))
                ///// whenever we check based on quality we have to add the hide option
                if(this.context.hidePublicContent === true && this.context.programs?.length > 0){
                    programsFilter = this.context.programs
                    if (programsFilter.length > 10) {
                        programsFilter = programsFilter.slice(0,9)
                    }
                    db = db.where('program', 'in',  programsFilter)
                }
                if(lastVisible === undefined && randomChar !== undefined ) {
                    db = db.where(this.props.firebase.fieldPath.documentId(), randomDirection, randomChar)
                    //db = db.where('title', '>=', randomChar)
                    //console.log('add random', randomChar)
                }
                else{
                    db = db.orderBy('contentVotes', 'desc')
                }
                if(this.props.quotes){ db = db.where('category', '==', 'quotes') }
                if(lastVisible !== undefined && lastVisible !== null) {
                    //console.log('start after', this.state.lastLoadedContent)
                    db = db.startAfter(lastVisible)
                }
                if(diagnosticFlag)
                    console.log("Step 8.hf. qualities", qualitiesLoaded)
            }
            else if(!phasesLoaded.includes("contentCategories")){
                if(diagnosticFlag)
                    console.log('Step 9.a. contentCategories')
                phasesLoaded.push("contentCategories")
                db = this.props.firebase.contents()
                .where('active', '==', true)
                .where('leadership_category', 'array-contains-any', [category, 'clarity', 'priority', 'confidence'] )
                if(lastVisible === undefined && randomChar !== undefined) {
                    db = db.where(this.props.firebase.fieldPath.documentId(), randomDirection, randomChar)
                    //console.log('add random', randomChar)
                }
                else{
                    db = db.orderBy('createdAt', 'desc')
                }
                if(this.props.filterQuality !== undefined){ 
                    if(this.props.filterTodayQuality?.length > 2){
                        console.log('two quals 12', quality, this.props.filterTodayQuality)
                        db = this.props.firebase.contents()
                        .where('active', '==', true)
                        .where('leadership_quality', 'array-contains-any', [this.props.filterQuality, this.props.filterTodayQuality]) 
                    }
                    else{
                        db = this.props.firebase.contents()
                        .where('active', '==', true)
                        .where('leadership_quality', 'array-contains', this.props.filterQuality) 
                    }
                    if(lastVisible === undefined && randomChar !== undefined ) {
                        db = db.where(this.props.firebase.fieldPath.documentId(), randomDirection, randomChar)
                        //console.log('add random', randomChar)
                    }
                    else{
                        db = db.orderBy('createdAt', 'desc')
                    }
                }
                if(this.context.hidePublicContent === true && this.context.programs !== undefined && this.context.programs.length > 0){
                    if(this.props.filterQuality !== undefined){ 
                        if(this.props.filterTodayQuality?.length > 2){
                            console.log('two quals 13', quality, this.props.filterTodayQuality)
                            db = this.props.firebase.contents()
                            .where('active', '==', true)
                            .where('leadership_quality', 'array-contains-any', [this.props.filterQuality, this.props.filterTodayQuality]) 
                        }
                        else{
                            db = this.props.firebase.contents()
                            .where('active', '==', true)
                            .where('leadership_quality', 'array-contains', this.props.filterQuality) 
                        }
                        ///// whenever we check based on quality we have to add the hide option
                        if(this.context.hidePublicContent === true && this.context.programs?.length > 0){
                            programsFilter = this.context.programs
                            if (programsFilter.length > 10) {
                                programsFilter = programsFilter.slice(0,9)
                            }
                            db = db.where('program', 'in',  programsFilter)
                        }
                        if(lastVisible === undefined && randomChar !== undefined ) {
                            db = db.where(this.props.firebase.fieldPath.documentId(), randomDirection, randomChar)
                            //console.log('add random', randomChar)
                        }
                        else{
                            db = db.orderBy('createdAt', 'desc')
                        }
                    }
                    else{
                        programsFilter = this.context.programs
                        if (programsFilter.length > 10) {
                            programsFilter = programsFilter.slice(0,9)
                        }
                        db = this.props.firebase.contents()
                        .where('active', '==', true)
                        .where('program', 'in',  programsFilter)
                        if(lastVisible === undefined && randomChar !== undefined ) {
                            db = db.where(this.props.firebase.fieldPath.documentId(), randomDirection, randomChar)
                            //console.log('add random', randomChar)
                        }
                        else{
                            db = db.orderBy('createdAt', 'desc')
                        }
                    }
                }
                if(this.props.quotes){ db = db.where('category', '==', 'quotes') }
                if(lastVisible !== undefined && lastVisible !== null) {
                    //console.log('start after', lastVisible)
                    db = db.startAfter(lastVisible)
                }
                //console.log("category", category)
            }
            else if(!phasesLoaded.includes("contentAllQuality")){
                if(diagnosticFlag)
                    console.log('10.a. contentAllQuality')
                phasesLoaded.push("contentAllQuality")
                db = this.props.firebase.contents()
                .where('active', '==', true)
                .where('leadership_quality', 'array-contains', qualitiesLoaded) 
                if(lastVisible === undefined && randomChar !== undefined ) {
                    db = db.where(this.props.firebase.fieldPath.documentId(), randomDirection, randomChar)
                    //console.log('add random', randomChar)
                }
                else{
                    db = db.orderBy('createdAt', 'desc')
                }
                if(this.context.hidePublicContent === true && this.context.programs !== undefined && this.context.programs.length > 0){
                    if(this.props.filterQuality !== undefined){ 
                        if(this.props.filterTodayQuality?.length > 2){
                            console.log('two quals 14', quality, this.props.filterTodayQuality)
                            db = this.props.firebase.contents()
                            .where('active', '==', true)
                            .where('leadership_quality', 'array-contains-any', [this.props.filterQuality, this.props.filterTodayQuality]) 
                        }
                        else{
                            db = this.props.firebase.contents()
                            .where('active', '==', true)
                            .where('leadership_quality', 'array-contains', this.props.filterQuality) 
                        }
                        ///// whenever we check based on quality we have to add the hide option
                        if(this.context.hidePublicContent === true && this.context.programs?.length > 0){
                            programsFilter = this.context.programs
                            if (programsFilter.length > 10) {
                                programsFilter = programsFilter.slice(0,9)
                            }
                            db = db.where('program', 'in',  programsFilter)
                        }
                        if(lastVisible === undefined && randomChar !== undefined ) {
                            db = db.where(this.props.firebase.fieldPath.documentId(), randomDirection, randomChar)
                            //console.log('add random', randomChar)
                        }
                        else{
                            db = db.orderBy('createdAt', 'desc')
                        }
                    }
                    else{
                        programsFilter = this.context.programs
                        if (programsFilter.length > 10) {
                            programsFilter = programsFilter.slice(0,9)
                        }
                        db = this.props.firebase.contents()
                        .where('active', '==', true)
                        .where('program', 'in',  programsFilter)
                        if(lastVisible === undefined && randomChar !== undefined ) {
                            db = db.where(this.props.firebase.fieldPath.documentId(), randomDirection, randomChar)
                            //console.log('add random', randomChar)
                        }
                        else{
                            db = db.orderBy('createdAt', 'desc')
                        }
                    }
                }
                if(this.props.quotes){ db = db.where('category', '==', 'quotes') }
                if(lastVisible !== undefined && lastVisible !== null) {
                    //console.log('start after', lastVisible)
                    db = db.startAfter(lastVisible)
                }
                //console.log("category", category)
            }
            else{
                if (diagnosticFlag)
                    console.log("Step skipped No phasesLoaded", phasesLoaded)
            }
        }
        if(this.props.quotes){ db = db.where('category', '==', 'quotes') }
        else if(this.props.noQuotes){ db = db.where('category', '==', 'tips')}
        //console.log('loading', phasesLoaded, intentsSearchIds, intentsTagsLoaded)
        //var showCounter = 0;
        //////// LIMITER
        if(false && this.props.visualLimit > 0){
            db = db.limit(this.props.visualLimit*2)
            //console.log('limit', this.props.visualLimit)
        }
        else{
            db = db.limit(16)
        }
        //////// LIMITER
        if(diagnosticFlag)
            console.log('Step X.pre GET Documents doc number new:')
        if(this.unsubscribe !== undefined){
            //makes error    this.unsubscribe();
        }
        var loadCounter = 0;
        if(this.timeout !== undefined && this._isMounted){
            clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(() => {
            if(this._isMounted){
                //console.log("Step timeout action")
                this.loadMoreContent();
            }
        }, 5000);
        this.unsubscribe = db
            .get()
            .then((snapshot) => {
                if (diagnosticFlag)
                    console.log('Step X.pre 2 GET Documents doc number new:')
                if(this.timeout !== undefined && this._isMounted){
                    clearTimeout(this.timeout);
                }
                if(this._isMounted){
                    lastVisible = snapshot.docs[snapshot.docs.length-1];
                    loadCounter = snapshot.docs.length;
                    if(diagnosticFlag)
                        console.log('Step X.1 GET Documents doc number new:', loadCounter)
                    //shuffle(snapshot);
                    var snapshotCounter = 0;
                    snapshot.forEach(doc => {
                        snapshotCounter+=1;
                        if(diagnosticFlag)
                            console.log('doc', doc.id, doc.data().title)
                        if( !(this.state.itemsLoaded.includes(doc.id)) && 
                            (this.props.contentToShowOnly?.includes(doc.id) || (this.props.contentToShowOnly === undefined && !(unlikedContent.includes(doc.id))) )&& 
                            (this.props.visualLimit === undefined || this.state.itemsLoaded?.length < this.props.visualLimit)
                            ){
                            var content = {...doc.data(), id: doc.id};
                            content = ContentProgramOverwrite(content, this.props.user, (undefined), this.props.leadershipQualities, true);
                            //console.log('doc', content['leadership_quality'], content.intentSearchTags, content.public)
                            var programFilterValid = true;
                            var qualityFilterValid = OverlapQualityFilter(quality, content.leadership_quality, this.props.filterTodayQuality);
                            if(this.context.hidePublicContent === true && this.context.programs !== undefined && this.context.programs.length > 0){
                                programsFilter = this.context.programs;
                                if (programsFilter.includes(content.program) || qualityFilterValid){ 
                                    programFilterValid = true;
                                }
                                else{
                                    programFilterValid = false;
                                    if(diagnosticFlag)
                                        console.log('failed program', doc.data().title)
                                }
                            }
                            if(this.props.roleFiter !== false && content.requirements !== undefined && content.requirements?.length > 0 && !content.requirements?.includes('default')){
                                if(!content.requirements?.includes(this.state.userRoleFilter)){
                                    programFilterValid = false;
                                    //console.log('userRoleFilter missing', this.state.userRoleFilter, content.requirements)
                                }
                            }
                            //console.log('try doc in', filterIntent, content.title, content.leadership_quality, content.intentSearchTags)
                            if(programFilterValid && 
                                (qualityFilterValid
                                    || (this.props.filterCategory !== undefined && content.leadership_category === this.props.filterCategory))){
                                // PUSHING THE LAST REMINDER CONTENT TO TOP
                                if(diagnosticFlag)
                                    console.log('doc in', filterIntent, content.title, content.leadership_quality, content.intentSearchTags)
                                if(filterIntent === undefined || filterIntent === '' 
                                    || (filterIntent?.length> 1 && content.intentTags?.[filterIntent] === true) 
                                    || qualityFilterValid
                                    || (this.props.filterCategory?.length > 2 && content.leadership_category === this.props.filterCategory)
                                    ){
                                    content = ContentProgramOverwrite(content, this.props.user, (this.props.overWriteQualityValue || undefined))
                                    this.showCounter += 1;
                                    //console.log('APPROVED', content.title, filterIntent, content['leadership_quality'], filterCurrentLeadershipQuality)
                                    if(this.props.filterIntent?.length > 2 && this.props.visualLimit > 0){
                                        if((Math.random()>=0.7) || (snapshotCounter === loadCounter-1)){
                                            items.push(content);
                                            itemsLoaded.push(doc.id);
                                            //console.log('oush up', doc.id, snapshotCounter,loadCounter)
                                        }
                                        else{
                                            //items.unshift(content);
                                            //itemsLoaded.unshift(doc.id);
                                            //console.log('oush down', doc.id, snapshotCounter,loadCounter )
                                            this.showCounter -= 1;
                                        }
                                    }
                                    else if(this.props.user?.contentLastNotification !== undefined && this.props.user?.contentLastNotification === doc.id){
                                        //console.log('unshifting notificagtion to top')
                                        items.unshift(content);
                                        itemsLoaded.unshift(doc.id);
                                    }
                                    else if(filterIntent?.length> 1 && content.leadership_quality?.includes(quality)){
                                        items.unshift(content);
                                        itemsLoaded.unshift(doc.id);
                                    }
                                    else{
                                        items.push(content);
                                        itemsLoaded.push(doc.id);
                                    }
                                    if(content?.intentSearchTags?.length > 0){ //intentsTagsLoaded
                                        if(this.props.filterIntent?.length > 2){
                                            if(true){
                                                var moreIntentTags = (content.intentSearchTags?.filter((item) => 
                                                    ((filterIntent.includes(item) || (content.leadership_quality?.includes(quality) && content.intentTags?.[intentsTagsLoaded[0]] === true && content.intentTags?.[item] === true)) 
                                                        && intentsTagsLoaded.indexOf(item) < 0)
                                                    ));
                                                moreIntentTags = moreIntentTags.sort( () => .5 - Math.random() );
                                                intentsTagsLoaded = [intentsTagsLoaded[0]].concat(moreIntentTags);
                                            }
                                            if(diagnosticFlag)
                                                console.log('Add FilterIntents', content.intentSearchTags, filterIntent, intentsTagsLoaded)
                                        }
                                        else{
                                            intentsTagsLoaded = intentsTagsLoaded.concat(content.intentSearchTags?.filter((item) => (content.intentTags?.[item] === true && intentsTagsLoaded.indexOf(item) < 0)));
                                            if(diagnosticFlag)
                                                console.log('Add all Filterintents', content.intentSearchTags, filterIntent, intentsTagsLoaded)
                                        }
                                        //console.log('add intenttags', intentsTagsLoaded)
                                        let indexFilter = phasesLoaded.indexOf('contentIntentsCategory'); 
                                        if (indexFilter !== -1) { // if item is found in array
                                            phasesLoaded.splice(indexFilter, 1); 
                                        }
                                    }
                                    //if(content.utteranceClass !== undefined && content.utteranceClass.length > 0){
                                    //    intentsLoaded = intentsLoaded.concat(content.utteranceClass.filter((item)=> intentsLoaded.indexOf(item) < 0));
                                    //}
                                    if(content.leadership_quality !== undefined && content.leadership_quality.length > 0){
                                        qualitiesLoaded = qualitiesLoaded.concat(content.leadership_quality?.filter((item)=> qualitiesLoaded.indexOf(item) < 0));
                                    }
                                }
                                else{
                                    //console.log("failed filterIntent", filterIntent, content.intentSearchTags, intentsTagsLoaded)
                                }
                            }
                            else if(diagnosticFlag){
                                console.log("ignored q", content.leadership_quality, this.props.filterQuality)
                            }
                        }
                        else if(diagnosticFlag){
                            console.log("ignored", doc.id)
                        }
                    })
                    //console.log('loading x', this.showCounter, loadCounter, phasesLoaded)
                    if(this.props.visualLimit > 0 && this.showCounter >= this.props.visualLimit && this._isMounted) {
                        //console.log('reachrdf x', this.showCounter, loadCounter, phasesLoaded)
                        this.setState({loadingMore: false, loading: false});
                        if(this.props.resultCounter !== undefined){
                            this.props.resultCounter(this.showCounter);
                        }
                    }
                    var hasMore = true;
                    //var reached15Items = false;
                    
                    //if(showCounter >= 8 || loadCounter>= 16){
                    if (diagnosticFlag)
                        console.log('Step X Ultimaum loadCounter', loadCounter, phasesLoaded)
                    if(loadCounter >= 16){
                        if (diagnosticFlag)
                            console.log('Step X reached a loading', this.showCounter, loadCounter, phasesLoaded);
                        //this.showCounter = 0;
                        ////// REMOVE THE NEXT TO DO THE QUERY
                        if(phasesLoaded.at(-1) === "contentCategories" ){
                               //console.log('adding more categories', showCounter);
                               phasesLoaded = phasesLoaded.filter(e => e !== "contentCategories");
                               //reached15Items = true;
                           }
                        else if(phasesLoaded.at(-1) === "contentGrowthPath"){
                            //console.log('adding more sister search', showCounter);
                            phasesLoaded = phasesLoaded.filter(e => e !== "contentGrowthPath");
                            //reached15Items = true;
                        }
                        else if(phasesLoaded.at(-1) === "contentIntentsPrograms" ){
                            //console.log('adding more intents search', showCounter);
                            phasesLoaded = phasesLoaded.filter(e => e !== "contentIntentsPrograms");
                            //reached15Items = true;
                        }
                        else if(phasesLoaded.at(-1) === ("contentIntentsQuality") ){
                            phasesLoaded = phasesLoaded.filter(e => e !== "contentIntentsQuality");
                            //reached15Items = true;
                        }
                        else if(phasesLoaded.at(-1) === ("contentIntentsCategory") ){
                            //console.log('adding more intents search');
                            phasesLoaded = phasesLoaded.filter(e => e !== "contentIntentsCategory");
                            //reached15Items = true;
                        }
                        else if(phasesLoaded.at(-1) === ("contentRecommendationsIds") ){
                            //console.log('adding more recommend', showCounter);
                            phasesLoaded = phasesLoaded.filter(e => e !== "contentRecommendationsIds");
                            //reached15Items = true;
                        }
                        else if(phasesLoaded.at(-1) === ("contentCourse") ){
                            phasesLoaded = phasesLoaded.filter(e => e !== "contentCourse");
                            //reached15Items = true;
                        }
                        else if(phasesLoaded.at(-1) === ("contentAllQuality") ){
                            phasesLoaded = phasesLoaded.filter(e => e !== "contentAllQuality");
                            //reached15Items = true;
                        }
                        else{
                            lastVisible = undefined
                        }
                    }
                    else{
                        if(loadCounter < 16) { 
                            lastVisible = undefined;
                            if (diagnosticFlag)
                                console.log('Step no more to load', this.showCounter, loadCounter, phasesLoaded);
                        }
                        else{
                            if (diagnosticFlag)
                                console.log('Step unsatisfied', this.showCounter, loadCounter, phasesLoaded);
                        }
                    }
                    if(phasesLoaded.includes("contentAllQuality") && loadCounter < 16 ){
                        if (diagnosticFlag)
                            console.log('Step Final contentAllQuality', loadCounter, phasesLoaded, items)
                        hasMore = false;
                        lastVisible = undefined;
                        this.setState({
                            loadingMore: false,
                        })
                        if(this.props.resultCounter !== undefined){
                            this.props.resultCounter(this.showCounter);
                        }
                    }
                    //console.log('loaded', loadCounter, phasesLoaded, items)
                    if(this._isMounted){
                        if (diagnosticFlag)
                            console.log('Step Saving Ultimate', lastVisible, loadCounter, phasesLoaded, items)
                        this.setState({
                            lastLoadedContent: lastVisible,
                            loading: false,
                            items,
                            hasMore,
                            itemsLoaded,
                            intentsTagsLoaded,
                            phasesLoaded,
                            qualitiesLoaded,
                            randomChar,
                            randomDirection,
                        })
                        if(this.props.itemsSubmit !== undefined){
                            this.props.itemsSubmit(items);
                        }
                    }
                    var visualLimitReached = false;
                    if(this.props.visualLimit > 0 && this.showCounter >= this.props.visualLimit) {
                        visualLimitReached = true;
                    }
                    if (diagnosticFlag)
                        console.log("Step x.W visualLimit Check", visualLimitReached, this.state.visibleLoading, this.showCounter, loadCounter, phasesLoaded, this.props.visualLimit);
                    if((!visualLimitReached || this.state.visibleLoading || loadCounter===0) && 
                        (!phasesLoaded.includes("contentAllQuality") || 
                        (phasesLoaded.includes("contentAllQuality") && (loadCounter >= 16))) ){
                        ////// SKIP THE NEXT TO DO THE QUERY
                        if(this._isMounted && !visualLimitReached && this.state.visibleLoading){
                            if (diagnosticFlag)
                                console.log('Step x.Z loading more', "lastVisible", lastVisible, this.showCounter, loadCounter, phasesLoaded, this.props.visualLimit);
                            this.loadMoreContent();
                        }
                        if(this._isMounted && !visualLimitReached && this.state.visibleLoading===undefined){
                            if (diagnosticFlag)
                                console.log('Step x.Z loading undefined', "lastVisible", lastVisible, this.showCounter, loadCounter, phasesLoaded, this.props.visualLimit);
                            this.loadMoreContent();
                        }
                        else if(this._isMounted && visualLimitReached){
                            if (diagnosticFlag)
                                console.log('Step x.Z stopped visualLimitReached', this.showCounter, loadCounter, phasesLoaded, this.props.visualLimit);
                            this.setState({loadingMore: false, hasMore: false, loading: false});
                        }
                        else if (diagnosticFlag)
                            console.log('Step x.Z no conclusion', this.showCounter, loadCounter, phasesLoaded, this.props.visualLimit);
                        
                    }
                    else if(this._isMounted){
                        if(this.showCounter >= 8 && this.showCounter < 15){
                            this.showCounter = this.showCounter - 8; 
                        }
                        else if(this.showCounter >= 8){
                            //console.log('we have at least 4', this.showCounter, loadCounter, phasesLoaded);
                            this.showCounter = 4; 
                        }
                        //console.log('we have at least ?', this.showCounter, loadCounter, phasesLoaded);
                    }
                }
            })
            .catch((error) => {
                if (diagnosticFlag)
                    console.log("Step Error getting document:", error);
                this.setState({loading: false})
            });
        //this.setState({ phasesLoaded });
        //console.log('X.X phases ended', phasesLoaded)

    }

    scrollEvent(scrollEvent){
        if(scrollEvent!==undefined && scrollEvent.target!==undefined && scrollEvent.target.scrollTop !==undefined ){
            if(this.props.scrollEvent!== undefined){
                this.props.scrollEvent(scrollEvent)
            }
        }
    }


    render() {
        var { loading } = this.state;
        const { leadershipQualities, user , screenWidth } = this.props;
        const { theme, classes } = this.props;
        //console.log('bookmarks', this.props.resultOfContentSearch, this.props.filterIntent)
        //console.log('props now', this.props, this.state)
        //console.log(this.state.items.length, !(this.state.items.length>0))
        // with the move the data are asyn and cases need to be covered
        if (user !== null && leadershipQualities !== undefined && leadershipQualities?.length > 0) {
            if(loading && this.props.showMinimal && !(this.state.items.length>0)){
                return ""
                /***
                 * 
                 *  <div ref={(el) => { this.dialogRef = el; }} style={theme.textSupportCenter}>
                                    ...
                                </div>
                 */
            }
            else if(loading && !(this.state.items.length>0))
                return (
                    <div ref={(el) => { this.dialogRef = el; }} style={{height: '50vh', paddingTop: '0px'}}>
                        <LoadingProgress 
                            defaultSet={this.props.defaultSet}
                            type={'LoadingPageAnimated'} loadingText={"loading content"} />
                    </div>
                )
            else
                return(
                    <div style={{width:"100%", paddingTop: (this.props.paddingTop || 0), paddingBottom: (this.props.paddingBottom > 0 ? this.props.paddingBottom : this.props.visualLimit <= 6 ? 40 : '25vh')}} ref={(el) => { this.dialogRef = el; }}>
                        {this.props.handleOpen === undefined
                            &&
                            <DialogContentItem
                                user={this.context}
                                defaultSet={this.props.defaultSet || undefined}
                                open={this.state.DialogContentPreviewOpen || false}
                                handleClose={() => this.setState({DialogContentPreviewOpen : false})}
                                contentData={this.state.contentData || undefined}
                                overWriteQualityValue = {this.props.overWriteQualityValue || undefined}
                                contentId = {this.state.contentData?.id}
                                messageSubmit={this.props.messageSubmit || undefined}
                                messageSubmitFull={this.props.messageSubmitFull || undefined}
                                closeAfterActionAdded={this.props.closeAfterActionAdded || undefined}
                                leadershipCategories={this.props.leadershipCategories}
                                leadershipQualities={this.props.leadershipQualities}
                            />
                        }
                        {this._isMounted
                            &&
                            <InfiniteScroll
                                style={{overflow:'hidden'}}
                                dataLength={this.state.items.length}
                                next={this.fetchMoreData}
                                hasMore={this.state.hasMore || true}
                                onScroll={ (mouseEvent) => {this.scrollEvent(mouseEvent)} }
                                //height={"80vh"}
                                scrollableTarget="scrollableDiv"
                                loader={
                                    (this.state.loadingMore === false || this.state.hasMore === false)
                                    ?   
                                        (this.state.phasesLoaded === undefined || (false && this.state.phasesLoaded!== undefined && !this.state.phasesLoaded.includes("contentCategories")))
                                        ?
                                            <div style={{...theme.divCenter, paddingTop:100}}>
                                                <ExpandMoreIcon fontSize="large"/>
                                            </div>
                                        :
                                            this.state.items.length > 0 && this.props.showMinimal !== true
                                            ?
                                                <div style={{...theme.divCenter, paddingTop:30}}
                                                    onClick={this.scrollUpAgain}
                                                    >
                                                    {this.props.visualLimit > 0 && this.props.hideLimitPrompt !== true &&
                                                        <p style={theme.textSupportCenter}>Limited to {this.props.visualLimit} items</p>
                                                    }
                                                    <ArrowUpwardIcon fontSize="large"/>
                                                </div>
                                            :   ''
                                    :   loading
                                        ?   ''
                                        :   this.props.showCarousel 
                                            ?   ''
                                            :
                                                <VizSensor 
                                                    key={"utter_X_search"}
                                                    partialVisibility={true}
                                                    onChange={(isVisible) => { 
                                                        if(isVisible && this.state.visibleLoading !== true && this._isMounted) { 
                                                            this.setState({visibleLoading : true});
                                                            this.fetchMoreData()
                                                        }
                                                        else if(!isVisible && this.state.visibleLoading!==false && this._isMounted) { 
                                                            this.setState({visibleLoading : false});
                                                        }
                                                    }}
                                                >
                                                    {this.props.showMinimal 
                                                        ?
                                                            <div style={{margin: 1}}><img src={pixel} width={1} height={1}/></div>
                                                        :

                                                            <div style={{height: '50vh', paddingTop: '0px'}}>
                                                                <LoadingProgress 
                                                                    defaultSet={this.props.defaultSet}
                                                                    type={'LoadingPageAnimated'} loadingText={"searching more content"} />
                                                            </div>
                                                    }
                                                </VizSensor>
                                }
                                >
                                    {screenWidth!== undefined && screenWidth >= 600
                                        ?
                                            <Masonry
                                                breakpointCols={2}
                                                className={classes.masonry}
                                                columnClassName={classes.masonryColumn}
                                            >
                                                {this._isMounted && this.state.items.map((i, index) => (
                                                    <div key={index}>
                                                        <ContentItemCard
                                                            user={this.props.user}
                                                            defaultSet={this.props.defaultSet || undefined}
                                                            raised={this.props.raised || false}
                                                            listIndex={index}
                                                            previewLatestComment={true}
                                                            showActions={this.props.showActions || false}
                                                            showMinimal={this.props.showMinimal || false}
                                                            handlerListIndex={ (visualIndex) => this.setState({visualIndex})}
                                                            contentData={i}
                                                            handleOpen={ (contentId, type="content") => this.handleOpen(i, type)}
                                                            handleOpenRead={ (contentId, type="read") => this.handleOpen(i, type)}
                                                            handleOpenComment={ (contentId, type="comment") => this.handleOpen(i, type)}
                                                            messageSubmit={this.props.messageSubmit || undefined}
                                                            messageSubmitFull={this.props.messageSubmitFull || undefined}
                                                            closeAfterActionAdded={this.props.closeAfterActionAdded || undefined}
                                                            contentId = {i.id}
                                                            leadershipCategories={this.props.leadershipCategories}
                                                            leadershipQualities={this.props.leadershipQualities}
                                                        />
                                                    </div>
                                                ))}
                                            </Masonry>
                                        :   
                                            <div>
                                                {this._isMounted 
                                                && 
                                                <>
                                                    {this.props.showCarousel
                                                        ?
                                                            <ContentSuggestCarousel
                                                                user={this.props.user}
                                                                contentSelection={this.state.items || undefined}
                                                                leadershipCategories={this.props.leadershipCategories}
                                                                leadershipQualities={this.props.leadershipQualities}
                                                                messageSubmit={this.props.messageSubmit || undefined}
                                                                messageSubmitFull={this.props.messageSubmitFull || undefined}
                                                                closeAfterActionAdded={this.props.closeAfterActionAdded || undefined}
                                                                handleOpen={ (content, type="content") => this.handleOpen(content, type)}
                                                            />
                                                        :   this.state.items.map((i, index) => (
                                                                <div key={index}>
                                                                    <ContentItemCard
                                                                        user={this.props.user}
                                                                        defaultSet={this.props.defaultSet || undefined}
                                                                        raised={this.props.raised || false}
                                                                        listIndex={index}
                                                                        previewLatestComment={true}
                                                                        showActions={this.props.showActions || false}
                                                                        showMinimal={this.props.showMinimal || false}
                                                                        handlerListIndex={ (visualIndex) => this.setState({visualIndex})}
                                                                        contentData={i}
                                                                        handleOpen={ (contentId, type="content") => this.handleOpen(i, type)}
                                                                        handleOpenRead={ (contentId, type="read") => this.handleOpen(i, type)}
                                                                        handleOpenComment={ (contentId, type="comment") => this.handleOpen(i, type)}
                                                                        contentId = {i.id}
                                                                        messageSubmit={this.props.messageSubmit || undefined}
                                                                        messageSubmitFull={this.props.messageSubmitFull || undefined}
                                                                        closeAfterActionAdded={this.props.closeAfterActionAdded || undefined}
                                                                        leadershipCategories={this.props.leadershipCategories}
                                                                        leadershipQualities={this.props.leadershipQualities}
                                                                    />
                                                                </div>
                                                            ))
                                                    }
                                                </>
                                                }
                                            </div>
                                    }
                            </InfiniteScroll>
                        }
                    </div>
                ); // end return
        }
        else {
            return (
                <Card style={theme.CardContent} ref={(el) => { this.dialogRef = el; }}>
                    <p style={theme.CardContent.titleText}>Please wait a moment</p>
                </Card>
            )
        }

    }


}

export default compose(
    withFirebase,
    withStyles(styles, { withTheme: true })
)(InfiniteContent);