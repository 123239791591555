//import React from 'react';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import MetaTags from 'react-meta-tags';
//import { Provider } from 'react-redux'
import './index.css';
import * as serviceWorker from './serviceWorker.js';
import { CheckCoachCode } from './components/Modules/URLQueries.js';

import App, { AppAdminSet } from './components/App';
import theme, { themeDark, ReloadTheme } from './themes';
import Firebase, { FirebaseContext, withFirebase } from './components/Firebase';
import LoadingProgress from './components/Modules/LoadingProgress';
import {LoadAndSaveSignupDefaultCode} from './components/Modules/DefaultCode';

import queryString from 'query-string';
import {DigitalTwinsList} from './constants/codes.js';

//var output = []
function ExtraLog(mes1, mes2 = '', mes3 = ''){
    //console.log(mes1, mes2, mes3);
    //output.push(String(mes1))
    //window.sessionStorage.setItem('output', JSON.stringify(output));
}

class AppRouteChooser extends Component{

    constructor(props) {
        super(props);
        const isBrowser = (process?.env?.REACT_APP_DEVICE_PLATFORM==="BROWSER") ? true : false;
        var pageload = 'default';
        var program = 'default';
        ////// TESTING
        //program = '#POSITIVEPSYCHOLOGIST';
        var coachTitle = 'Coach Panel | My AI Coach'
        var defaultSet = undefined;
        try{
            var storedDefaultSet = JSON.parse(window.sessionStorage.getItem('defaultSet')) || undefined;
            if (storedDefaultSet === undefined){
                storedDefaultSet = JSON.parse(window.localStorage.getItem('defaultSet')) || undefined;
            }
            if(storedDefaultSet?.programCode?.includes("#")){
                //console.log("on load app deefaultset session", storedDefaultSet?.programCode, storedDefaultSet);
                defaultSet = storedDefaultSet;
                if(defaultSet?.programLogo?.length > 5 ){
                    var fi = document.getElementById("favicon");
                    //console.log('f12', fi, defaultSet.programLogo)
                    if(fi){
                        fi.setAttribute("href", defaultSet.programLogo);
                    }
                }
            }
        }
        catch (e){
            console.log('error sessionStorage spot', e)
        }
        //ExtraLog("location", window.location?.hostname)
        try{
            var digitaltwinKeys = Object.keys(DigitalTwinsList);
            if(false && isBrowser !== true){
                try{
                    [defaultSet, program] = this.dynamicLinkOnLoad(defaultSet, program, digitaltwinKeys);
                    //var dlOptions = window?.cordova?.plugins?.firebase?.dynamiclinks?.DynamicLinkOptions || undefined;
                    ExtraLog('initiate dynamic script')
                    //this.dynamicLinkOnClick(program, digitaltwinKeys)
                    ExtraLog('1 check', window?.cordova?.plugins?.firebase?.dynamiclinks)
                    ExtraLog('2 check', window?.cordova?.plugins?.firebase)
                    ExtraLog('3 check', window?.cordova?.plugins)
                }
                catch(error){
                    ExtraLog(error, 'initiater')
                }
            }
            if(isBrowser){
                const hostname = window.location?.hostname || "";
                var sessionCode = undefined;
                var urlCoachCode = CheckCoachCode();
                if(urlCoachCode?.length > 2){
                    //// Coachcode only for session
                    sessionCode = 'sessionStorage';
                }
                //// Get the subdomain in lowercase
                const parts = hostname.toLowerCase().split('.');
                var subdomain = parts.slice(0, -2).join('.') || "";
                if(hostname.includes("localhost")){
                    subdomain = parts.slice(0, -1).join('.') || "";
                }
                if(!(subdomain?.length > 1) && urlCoachCode?.length > 1){
                    subdomain = urlCoachCode.replace("#", "");
                }
                if(subdomain?.length > 0){
                    console.log('subdomain', subdomain)
                }
                ///// check the different domain variants
                if(window.location.hash !== undefined && (window.location.hash.includes('/admin') || window.location.hash.includes('/coach'))){
                    if (document.title !== coachTitle) {
                        document.title = coachTitle;
                    }
                    pageload = 'AppAdminSet';
                }
                if(hostname === ('app.rocky.ai') || hostname === ('app.rocky.coach')){
                    program = 'default';
                    pageload = 'default';
                    document.title = "Rocky.ai Coaching App"
                }
                else if(hostname === ('coach.rocky.ai') || hostname === ('admin.rocky.coach') || hostname === ("coach.localhost")){
                    if (document.title !== coachTitle) {
                        document.title = coachTitle;
                    }
                    pageload = 'AppAdminSet'
                }
                else if(['rocky'].includes(subdomain)){
                    program = '#ROCKY';
                    pageload = 'default';
                    defaultSet = this.loadProgramDefaultset(program);
                }
                else if(['app', 'coach', 'admin'].includes(subdomain)){
                    program = 'default';
                    pageload = 'default';
                }
                else if(subdomain !== "" && !digitaltwinKeys.includes(subdomain)){
                    program = "#" + subdomain.toUpperCase();
                    defaultSet = this.loadProgramDefaultset(program, undefined, undefined, sessionCode);
                }
                else{
                    var shouldSkip = false;
                    digitaltwinKeys.every( itemkey => {
                        //ExtraLog('itemkey check', itemkey)
                        // search for subdomain name eg. "lyric."
                        var checkKey = DigitalTwinsList[itemkey]?.key || itemkey;
                        if(!shouldSkip && hostname?.toLowerCase().includes(checkKey+'.')){
                            document.title = DigitalTwinsList[itemkey]?.title || 'AI Coach';
                            pageload = 'default';
                            program = DigitalTwinsList[itemkey]?.code;
                            shouldSkip = true;
                            ExtraLog("set the local program:", program);
                            defaultSet = this.loadProgramDefaultset(program, undefined, undefined, sessionCode);
                            return false
                        }
                        else{
                            return true;
                        }
                    })
                }
                if(pageload === 'default' && window.location && window.location.search !== undefined && window.location.search!==""){
                    //var urlSearch = window.location.search;
                    //var query = queryString.parse(window.location.search)
                    var urlSearch = window.location.href.slice(window.location.href.indexOf('?'));
                    var query = queryString.parse(urlSearch);
                    if(query.scope!==undefined && query.scope === "alexa::skills:account_linking"){
                        //ExtraLog("detail loc", query)
                        if(query.code !== undefined && query.state!==undefined){
                            window.location = "/#/profile"+urlSearch
                        }
                    }
                }
            }
            if(!isBrowser || program !== 'default'){
            //if(program !== 'default'){
                try{
                    if(defaultSet === undefined || (typeof defaultSet === 'object' && typeof defaultSet.then === 'function')){
                        var tempdefaultSet = JSON.parse(window.localStorage.getItem('defaultSet'));
                        ExtraLog('get stored defaultset',tempdefaultSet?.programCode, tempdefaultSet);
                        if(tempdefaultSet?.programCode?.includes('#')){
                            defaultSet = tempdefaultSet;
                            document.title = defaultSet.programTitle + ' - ' + (defaultSet.programSubTitle || "AI Coaching");
                            if(defaultSet?.programLogo?.length > 5 ){
                                var fi = document.getElementById("favicon");
                                //ExtraLog('f1', fi)
                                //console.log('f13', fi, defaultSet.programLogo)
                                if(fi){
                                    fi.setAttribute("href", defaultSet.programLogo);
                                }
                            }
                        }
                    }
                }
                catch(e){
                    ExtraLog('defaultset', e)
                }
                if(!isBrowser){
                    ExtraLog('dynamic link loaded once more');
                    [defaultSet, program] = this.dynamicLinkOnLoad(defaultSet, program, digitaltwinKeys);
                    //[defaultSet, program] = this.dynamicLinkOnClick(program, digitaltwinKeys);
                }
            }
            ///// extra searches for default pages
        }
        catch (e){
            ExtraLog('error location spot', e)
        }
        //console.log('state set first', program, defaultSet)
        this.state = {
            pageload,
            program,
            defaultSet,
            isBrowser : isBrowser
        };
        /***
        window?.addEventListener("storage", (event) => {
           console.log('storage listener event', event) 
        });
         */
    }

    refreshDefaultSet = async (defaultSet, refresh=false) => {
        //console.log('received defaultset', defaultSet);
        if(defaultSet?.programLogo?.length > 5 ){
            var fi = document.getElementById("favicon");
            //console.log('f14', fi, defaultSet.programLogo)
            if(fi){
                fi.setAttribute("href", defaultSet.programLogo);
            }
        }
        var storagedefaultSet = defaultSet;
        /// ONLY write into sessionStorage so that when the logout happens the browser would go back to the normal one
        Object.keys(storagedefaultSet).map((keyName) => {
            //ExtraLog(keyName)
            if(!keyName.includes('program')){
                delete storagedefaultSet[keyName];
            }
            return true
        })
        const stringified = JSON.stringify(storagedefaultSet);
        window?.sessionStorage?.setItem('defaultSet', stringified);
        this.setState({defaultSet: storagedefaultSet},
            () => {
                if(storagedefaultSet?.programColorBack || storagedefaultSet?.programColorButton || storagedefaultSet?.programColorFront || storagedefaultSet?.programColorInfo || storagedefaultSet?.programColorPrimary){
                    const newTheme = ReloadTheme(defaultSet);
                    this.setState({newTheme: newTheme});
                }
                return true
            }
        );
    }

    checkStatus(){
        //ExtraLog('1 heck', window?.cordova?.plugins?.firebase?.dynamiclinks)
        //ExtraLog('2 heck', window?.cordova?.plugins?.firebase)
        //ExtraLog('3 heck', window?.cordova?.plugins)
    }

    dynamicLinkPayload(payload, defaultSet, program, digitaltwinKeys, overwrite=false) {
        if (payload) {
            ExtraLog("Read dynamic link data on app start:", payload);
            Object.keys(payload).map((keyname) => {
                ExtraLog('paload key', keyname)
                return true
            })
            var deepLink = payload.deepLink || "empty";
            var code = payload.code || "";
            if(code === "" && payload.deepLink?.length>6){
                var urlSearch = payload.deepLink.slice(payload.deepLink.indexOf('?'));
                var query = queryString.parse(urlSearch);
                //console.log('urlParams read', query, deepLink)
                var coachcode = query.coachcode || "";
                console.log('coachcode read', coachcode)
                if(coachcode?.length > 4 && !coachcode?.includes('#')){
                    code = "#"+coachcode;
                }
                else if(coachcode?.length > 4 && coachcode?.startsWith('#')){
                    code = coachcode;
                }
            }
            var shouldSkip = false;
            if(code?.length > 2 && code?.includes('#')){
                program = code;
                ExtraLog("set the app program by code:", program);
                shouldSkip = true;
                defaultSet = this.loadProgramDefaultset(program, overwrite);
            }
            else if(payload?.utm_campaign?.length > 2 && payload?.utm_campaign?.includes('#')){
                program = payload.utm_campaign;
                ExtraLog("set the app program by UTM code:", program);
                shouldSkip = true;
                defaultSet = this.loadProgramDefaultset(program, overwrite);
            }
            else{
                digitaltwinKeys.forEach( itemkey => {
                    var checkKey = DigitalTwinsList[itemkey]?.key || itemkey;
                    ExtraLog('dynamic link check', checkKey)
                    if(!shouldSkip && deepLink?.includes(checkKey)){
                        document.title = DigitalTwinsList[itemkey]?.title || 'AI Coach';
                        program = DigitalTwinsList[itemkey]?.code || 'default';
                        ExtraLog("set the app program:", program);
                        shouldSkip = true;
                        defaultSet = this.loadProgramDefaultset(program, overwrite, false);
                    }
                })
            }
        } else {
            ExtraLog("No Payload Opened");
        }
        return [defaultSet, program]
    }

    dynamicLinkOnClick(defaultSet, program, digitaltwinKeys){
        try{
            window?.cordova?.plugins?.firebase?.dynamiclinks?.onDynamicLink((payload) => {
                ExtraLog("Dynamic link click with data:", payload);
                [defaultSet, program] = this.dynamicLinkPayload(payload, defaultSet, program, digitaltwinKeys, true);
            });
        }
        catch(error){
            ExtraLog('error onclick', error)
        }
        return [defaultSet, program]
    }

    dynamicLinkOnLoad(defaultSet, program, digitaltwinKeys){
        try{
            window.cordova.plugins?.firebase?.dynamiclinks?.getDynamicLink().then((payload) => {
                if (payload) {
                    ExtraLog("Started with Payload", payload);
                    [defaultSet, program] = this.dynamicLinkPayload(payload, defaultSet, program, digitaltwinKeys, true);
                } else {
                    ExtraLog("App wasn't started from a dynamic link");
                }
            });
        }
        catch(error){
            ExtraLog('error onload', error)
        }
        return [defaultSet, program]
    }

    loadProgramDefaultset = async (overwriteProgram = '', overwriteFlag = false, asyncFlag = true, storageType = undefined) => {
        console.log('"sotreagetype', storageType)
        ExtraLog('program loading', this.state)
        var defaultSet = this.state?.defaultSet || undefined;
        var programCode = (this.state?.program || '')+'';
        if(overwriteProgram !== ''){
            programCode = overwriteProgram;
        }
        //ExtraLog('programCode', programCode, overwriteProgram)
        if(programCode?.length >= 1 && programCode !== 'default'){
            if(overwriteProgram === this.state?.loadedLatestProgram){
                ExtraLog('set already loaded')
            }
            else{
                ExtraLog('props load', programCode);
                //console.log('index program loaded', programCode);
                LoadAndSaveSignupDefaultCode(this, defaultSet, programCode, storageType);
            }
        }
        else{
            defaultSet = "default";
        }
        //console.log('return first defaultSet', defaultSet)
        return defaultSet
    }

    componentDidMount() {
        if(process?.env !== undefined && process.env.REACT_APP_DEVICE_PLATFORM!==undefined && process.env.REACT_APP_DEVICE_PLATFORM==="BROWSER"){
            window.onhashchange = this.handlerOnHashChange
        }
        this.checkStatus()
        if(!this.state.isBrowser){
            var program = this.state.program;
            var defaultSet = this.state.defaultSet || undefined;
            ExtraLog('dynamic link load check from devirecread');
            var digitaltwinKeys = Object.keys(DigitalTwinsList);
            if(window?.cordova?.plugins?.firebase?.dynamiclinks !== undefined){
                ExtraLog('did mount for ready')
                this.dynamicLinkOnClick(defaultSet, program, digitaltwinKeys);
                this.dynamicLinkOnLoad(defaultSet, program, digitaltwinKeys);
            }
            else{
                document.addEventListener('deviceready', () => {
                    ExtraLog('did mount for deviceready')
                    this.dynamicLinkOnClick(defaultSet, program, digitaltwinKeys);
                    this.dynamicLinkOnLoad(defaultSet, program, digitaltwinKeys);
                }, {
                    once: true,
                    capture: false
                });
            }
        }
        ExtraLog('hash changed', window.location.hash, this.state, this.props, FirebaseContext )
        //this.loadProgramDefaultset();
        if(this.state.pageload!=='AppAdminSet' && window.location.hash !== undefined && 
            (window.location.hash.includes('/admin') || window.location.hash.includes('/coach')
                || (window.location.hash.includes('/home') && (this.context.roles?.includes("ADMIN") || this.context.roles?.includes("COACHADMIN") || this.context.roles?.includes("REMOTECOACH")))
            )){
            this.setState({ pageload : 'AppAdminSet'});
        }
        this.checkStatus()
    }

    handlerOnHashChange = (e) => {
        //ExtraLog('hash changed', window.location.hash, this.state, this.props )
        if(this.state.pageload!=='AppAdminSet' && window.location.hash !== undefined && 
            (window.location.hash.includes('/admin') || window.location.hash.includes('/coach'))){
            this.setState({ pageload : 'AppAdminSet'});
        }
    }

    render(){
        const {program, defaultSet} = this.state;
        //console.log("AppRouteChooser defaultSet", defaultSet)
        //ExtraLog(this.state)
        let colorTheme = theme;
        //// there is no context on this level
        if(this.context?.features?.darkmode === true){
            colorTheme = themeDark;
        }
        else if(this.state?.newTheme?.palette !== undefined){
            colorTheme = this.state.newTheme;
            //console.log('updatee newe theme', colorTheme);
        }
        try{
            //console.log('colorTheme.palette', colorTheme.palette, this.context, this.props)
            //document.body.style.backgroundColor = colorTheme.palette.backTertiary;
            document.body.style.backgroundImage = 'linear-gradient(180deg, '+colorTheme.palette.backTertiary+' 40px, '+colorTheme.palette.themeAltBlue+'52 50%, '+colorTheme.palette.backTertiary+' calc(100vh - 40px))';
            //document.body.style.backgroundImage = 'radial-gradient( circle farthest-corner at 48.4% 47.5%, '+colorTheme.palette.themeAltLightGrey+'52 0%, '+colorTheme.palette.backTertiary+' 80% )';
            //document.body.style.backgroundImage = 'linear-gradient(230deg, '+colorTheme.palette.backTertiary+' 8%, '+colorTheme.palette.themeAltLightGrey+'77 33%, '+colorTheme.palette.backTertiary+' 66%, '+colorTheme.palette.themeAltLightGrey+'77 100%)'
        }
        catch(e){
        }
        //console.log('theme', theme)
        if(this.state.pageload === 'AppAdminSet'){
            return(
                <>
                    {defaultSet?.programTitle?.length > 2
                    ? 
                        <MetaTags>
                            <title>{defaultSet?.programTitle}</title>
                            <meta name="description" content={defaultSet?.programSubTitle + " | The App for Coaching, Mentoring, Reflection, Personal-Development, Goal-Tracking, and Self-Improvement"} />
                            <meta property="og:title" content={defaultSet?.programTitle} />
                            <meta property="og:description" content={defaultSet?.programSubTitle + " | The App for Coaching, Mentoring, Reflection, Personal-Development, Goal-Tracking, and Self-Improvement"} />
                            <meta name="robots" content={"index"} />
                            <meta property="og:type" content="website"/>
                            <meta charSet="utf-8" />
                            {(defaultSet?.programImage?.length > 5 ) &&
                                <meta property="og:image" content={defaultSet?.programImage} />
                            }
                            {(defaultSet?.programLogo?.length > 5 ) 
                                ?
                                    <link rel="shortcut icon" id="favicon" href={defaultSet.programLogo} type="image/png"/>
                                :
                                    <link rel="shortcut icon" id="favicon" href="https://app.rocky.ai/rocky-favicon-512.png" type="image/png"/>
                            }
                        </MetaTags>
                    :
                        <MetaTags>
                            <link rel="shortcut icon" id="favicon" href="https://app.rocky.ai/rocky-favicon-512.png" type="image/png"/>
                        </MetaTags>
                    }
                    <AppAdminSet colorTheme={colorTheme} defaultSet={defaultSet || {}} refreshDefaultSet={this.refreshDefaultSet}/> 
                </>
            )
        }
        else if(program !== 'default' && (defaultSet === undefined || (typeof defaultSet === 'object' && typeof defaultSet.then === 'function'))){
            return (
                <div style={{height: '100vH', alignContent: "center"}}>
                    {/****
                     * Loading
                     * */}
                    <LoadingProgress
                        theme={colorTheme}
                        defaultSet={this.props.defaultSet}
                        type={'LoadingPageAnimated'} 
                        loadingText={
                            defaultSet?.programTitle?.length > 2
                            ?   defaultSet?.programTitle?.length
                            :   "AI coaching"
                        } 
                    />
                </div>
            )
        }
        else{
            return(
                <>
                    {defaultSet?.programTitle?.length > 2
                    ?  
                        <MetaTags>
                            <title>{defaultSet?.programTitle}</title>
                            <meta name="description" content={defaultSet?.programSubTitle + " | The App for Coaching, Mentoring, Reflection, Personal-Development, Goal-Tracking, and Self-Improvement"} />
                            <meta property="og:title" content={defaultSet?.programTitle} />
                            <meta property="og:description" content={defaultSet?.programSubTitle + " | The App for Coaching, Mentoring, Reflection, Personal-Development, Goal-Tracking, and Self-Improvement"} />
                            <meta name="robots" content={"index"} />
                            <meta property="og:type" content="website"/>
                            <meta charSet="utf-8" />
                            {/***
                            <meta name="apple-itunes-app" content="app-id=1492680249, app-argument=?coachcode=PITCHTOWIN" />
                             */}
                            {(defaultSet?.programImage?.length > 5 ) &&
                                <meta property="og:image" content={defaultSet?.programImage} />
                            }
                            {(defaultSet?.programLogo?.length > 5 ) 
                                ?
                                    <link rel="shortcut icon" id="favicon" href={defaultSet.programLogo} type="image/png"/>
                                :
                                    <link rel="shortcut icon" id="favicon" href="https://app.rocky.ai/rocky-favicon-512.png" type="image/png"/>
                            }
                        </MetaTags>
                    :
                        <MetaTags>
                            <meta name="apple-itunes-app" content="app-id=1492680249, app-argument=?coachcode=GROWTHMINDSET" />
                            <link rel="shortcut icon" id="favicon" href="https://app.rocky.ai/rocky-favicon-512.png" type="image/png"/>
                        </MetaTags>
                    }
                    <App colorTheme={colorTheme} defaultSet={defaultSet || {}} refreshDefaultSet={this.refreshDefaultSet}/> 
                </>
            )
        }
    }
}

var AppRouterProgram = withFirebase(AppRouteChooser);
/** 
        <AppRouteChooser />
*/
ReactDOM.render(
    <FirebaseContext.Provider value={new Firebase()}>
        <AppRouterProgram />
    </FirebaseContext.Provider>,
    document.getElementById('root')
);
/** 
ReactDOM.render(
    <FirebaseContext.Provider value={new Firebase()}>
        <App  />
    </FirebaseContext.Provider>,
    document.getElementById('root')
);

*/

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
if (process?.env?.REACT_APP_DEVICE_PLATFORM === "BROWSER" && serviceWorker?.messagingRegister !== undefined) {
    serviceWorker.messagingRegister()
}
else{
    serviceWorker?.unregister();
}