import React, { Component } from 'react';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';

import { AuthUserContext } from '../../Session';
import { withFirebase } from '../../Firebase';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import BorderColorTwoToneIcon from '@material-ui/icons/BorderColorTwoTone';
import TextField from '@material-ui/core/TextField';

import IconButton from '@material-ui/core/IconButton';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';



// style elements
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionActions';
import FormControlLabel from '@material-ui/core/FormControlLabel';
//import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoIcon from '@material-ui/icons/Info';

const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
    textFieldButtonStyle: {
        borderRadius: 20,
        backgroundColor: theme.palette.themeBlue,
        color: 'white',
        maxWidth: '27%',
        minWidth: 60,
        minHeight: 40,
        fontSize: 16
    },
    textStyle : { 
        textAlign: "center" , 
        color: "white",
        fontWeight: 20
    }
});


class SettingContentSpecialUtteranceText extends Component {
    static contextType = AuthUserContext;

    constructor(props) {
        super(props);
        this.state = {
            defaultValue: '',
            openSetting: false,
            text: '',
            buttons: undefined
        };
    }

    // Username Handling
    handleSettingOpen = () => {
        if(this.props.content !== undefined && this.props.content.template!== undefined){
            this.setState({text: this.props.content.template.text});
        }
        if(this.props.content !== undefined && this.props.content.template!== undefined && this.props.content.template.attachment!== undefined && this.props.content.template.attachment.explanation!== undefined){
            this.setState({explanation: this.props.content.template.attachment.explanation});
        }
        else{
            this.setState({explanation: ''});
        }
        if(this.props.content !== undefined && this.props.content.template!== undefined && this.props.content.template.buttons!== undefined){
            this.setState({buttons: this.props.content.template.buttons});
        }
        else{
            this.setState({buttons: undefined});
        }
        if(this.props.content !== undefined && this.props.content.template!== undefined && this.props.content.template.attachment!== undefined && this.props.content.template.attachment.event!== undefined){
            this.setState({event: this.props.content.template.attachment.event});
        }

        this.setState({
            openSetting: true,
        });
    }

    handleSettingChange = (param, event) => {
        this.setState({
            [param]: event.target.value
        });
    }

    handleSettingClose = () => {
        this.setState({
            openSetting: false,
            buttons: undefined
        });
        //console.log('closed', this.state)
    }

    handleSettingSubmit = () => {
        if(this.state['text'] !== null && this.state['text'] !== "" && this.state['text'] !== undefined  ){
            var template_set = {
                template: {
                    text: this.state.text.trim(),
                    attachment: {
                        utterance_class: this.props.utterance_class,
                    }
                }
            }

            if(this.state.explanation === null || this.state.explanation === "EXPLANATION OF QUESTION" || this.state.explanation?.length === 0){
                template_set['template']['attachment']['explanation'] = this.props.firebase.fieldValue.delete();
            }
            else if(this.state.explanation?.length > 0){
                template_set['template']['attachment']['explanation'] = this.state.explanation;
            }
            if(this.state.buttons === null || this.state.buttons?.length === 0){
                template_set['template']['buttons'] = this.props.firebase.fieldValue.delete()
                ////// with following approach erros as result
                //this.props.dbUpdateLink.update({
                //    template: {}
                //});
                /***
                this.props.dbUpdateLink.set({
                    template: {
                        text: this.state.text,
                        attachment: {
                            explanation: this.state.explanation,
                            utterance_class: this.props.utterance_class,
                        },
                        buttons: this.props.firebase.fieldValue.delete()
                    }
                }, {merge:true} );
                 */
                console.log('deleted buttons')
            }
            else if(this.state.buttons !== undefined){
                console.log('buttons to sacve', this.state.buttons, template_set)
                template_set['template']['buttons'] = this.state.buttons
            }
            if(this.state.attachment_event === null){
                template_set['template']['attachment']['event']  = this.props.firebase.fieldValue.delete()
            }
            else if(this.state.attachment_event !== undefined){
                template_set['template']['attachment']['event']  = this.state.attachment_event
            }
            /***
            else{
                this.props.dbUpdateLink.update({
                    template: {
                        text: this.state.text,
                        attachment: {
                            explanation: this.state.explanation,
                            utterance_class: this.props.utterance_class,
                        },
                        buttons: this.state.buttons
                    }
                });
            }
             */
            if(this.props.updateExtraChange !== undefined && typeof this.props.updateExtraChange === 'object'){
                Object.assign(template_set, this.props.updateExtraChange)
            }

            this.props.dbUpdateLink.set(template_set, {merge:true} );
        }      
        this.handleSettingClose();
    }

    render() {
        const { content, title } = this.props;
        const { theme, classes } = this.props;

        return (
            content
                ?
                    <div className={classes.grow}>
                        <Card style={{...theme.cardSettings, padding: 20}}>
                            <Grid container>
                                <Grid item xs={9} ><p style={{color:"darkgreen"}}>{title}</p>  </Grid>
                                <Grid item xs={1}>  </Grid>
                                <Grid item xs={2} style={{textAlign: 'right', marginTop: -10}}>
                                    {(this.props.access === undefined || this.props.access === true) && this.props.showButtons !== true
                                    &&
                                        <Tooltip title={'Edit/Change'}>
                                            <IconButton onClick={this.handleSettingOpen}> 
                                                <BorderColorTwoToneIcon fontSize="small" style={{color:"red"}}/>
                                            </IconButton>
                                        </Tooltip>
                                    }
                                </Grid>
                            </Grid>
                            <p style={theme.textSettings} key={"current_text"}>
                                {content !== undefined
                                    ?   <span style={{ float: 'right', fontWeight: 700, maxWidth:'75%' }}>
                                            {content.template !== undefined ? content.template.text : "n/a"}
                                        </span>
                                    :   <span style={{ float: 'right'}}>{this.props.emptyStage || "n/a"}</span>
                                }
                                {this.props.noLabel!==true &&
                                    <span>Text</span>
                                }
                            </p>
                            <p>&nbsp;</p>
                            <p style={theme.textSettings} key={"current_explanation"}>
                                {content !== undefined
                                    ?   <span style={{ float: 'right', fontWeight: 300, maxWidth:'75%' }}>
                                           {content.template !== undefined && content.template.attachment !== undefined ? content.template.attachment.explanation: "n/a"}
                                        </span>
                                    :   <span style={{ float: 'right'}}>{this.props.emptyStage || "n/a"}</span>
                                }
                                {this.props.noLabel!==true &&
                                    <span>Additional Tip for user</span>
                                }
                            </p>
                            {this.props.infoText?.[0]?.length > 2
                            &&
                                <p style={theme.textSupport} key={"guideline"}>
                                    Question design guideline: {this.props.infoText[0]}
                                </p>
                            }
                        </Card>
                        <Dialog
                            open={this.state.openSetting}
                            onClose={this.handleSettingClose}
                            fullWidth
                            maxWidth="sm">
                            <DialogTitle>Change Utterance</DialogTitle>
                            <form>
                                <DialogContent>
                                    
                                    <div style={{width: `100%`, paddingBottom: 50}}> 
                                        <TextField
                                            fullWidth
                                            style={ {fontSize: '16px'} }
                                            label={'Text or Question'}
                                            value={this.state.text}
                                            onChange={(e) => this.handleSettingChange('text', e)}
                                            //onChange={this.handleSettingChange(p)}
                                        />
                                    </div>
                                    <div style={{width: `100%`}}> 
                                        <TextField
                                            fullWidth
                                            style={ {fontSize: '16px'} }
                                            label={'Hint, background or inspiration for the user'}
                                            value={this.state.explanation}
                                            onChange={(e) => this.handleSettingChange('explanation', e)}
                                            //onChange={this.handleSettingChange(p)}
                                        />
                                    </div>
                                    {this.state.errorMessage !== '' && this.state.errorMessage !== undefined
                                        ?
                                            <p style={theme.textErrorCenterPadding}>
                                                {this.state.errorMessage}
                                            </p>
                                        : <p> </p>
                                    }
                                    {this.props.showAnswerFeature &&
                                        <Accordion style={{marginTop:30}}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-label="Answer-answers"
                                                aria-controls="About-answers"
                                                id="About-answers"
                                                >
                                                <FormControlLabel
                                                    aria-label="Answers"
                                                    control={<InfoIcon style={theme.cardSettings.iconDesign} />}
                                                    label="Buttons/Answers"
                                                />
                                                {this.state.buttons?.length > 0
                                                    &&
                                                    <div style={{width: '100%', textAlign: 'right'}}>
                                                        <Button>
                                                            <RadioButtonCheckedIcon fontSize="small" style={{ color: 'blue'}} /> 
                                                            Available
                                                        </Button>
                                                    </div>
                                                }
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                {this.props.content !== undefined && this.props.content.template !== undefined 
                                                    ?
                                                        <div style={{width: '100%'}}>
                                                            <span style={theme.textSupport}>
                                                                A short text in the button text and the same word in the payload. 
                                                            </span><br/>
                                                            <span style={theme.textSupport}>
                                                                Alternatively add a long text in the payload to influence the chat progress.
                                                            </span><br/>
                                                            <TextField
                                                                className={ classes.textFieldButtonStyle}
                                                                InputProps={{
                                                                    disableUnderline: true,
                                                                    inputProps: {
                                                                        className: classes.textStyle,
                                                                    }
                                                                }}
                                                                //label={'Button 1'}
                                                                value={this.state.buttons?.[0]?.title || ''}
                                                                onChange={(e) => this.handleButtonChange(e, 0, 'title')}
                                                            />
                                                            &nbsp;&nbsp;
                                                            <TextField
                                                                style={ {fontSize: '16px', width: '70%'} }
                                                                label={'Payload 1'}
                                                                value={this.state.buttons?.[0]?.payload || ''}
                                                                onChange={(e) => this.handleButtonChange(e, 0, 'payload')}
                                                            />
                                                            <br/>
                                                            <TextField
                                                                className={ classes.textFieldButtonStyle}
                                                                InputProps={{
                                                                    disableUnderline: true,
                                                                    inputProps: {
                                                                        style: { textAlign: "center" , color: "white"},
                                                                    }
                                                                }}
                                                                //label={'Answer 2'}
                                                                value={this.state.buttons?.[1]?.title || ''}
                                                                onChange={(e) => this.handleButtonChange(e, 1, 'title')}
                                                            />
                                                            &nbsp;&nbsp;
                                                            <TextField
                                                                style={ {fontSize: '16px', width: '70%'} }
                                                                label={'Payload 2'}
                                                                value={this.state.buttons?.[1]?.payload || ''}
                                                                onChange={(e) => this.handleButtonChange(e, 1, 'payload')}
                                                            />
                                                            <br/>
                                                            <TextField
                                                                className={ classes.textFieldButtonStyle}
                                                                InputProps={{
                                                                    disableUnderline: true,
                                                                    inputProps: {
                                                                        style: { textAlign: "center" , color: "white"},
                                                                    }
                                                                }}
                                                                //label={'Answer 3'}
                                                                value={this.state.buttons?.[2]?.title || ''}
                                                                onChange={(e) => this.handleButtonChange(e, 2, 'title')}
                                                            />
                                                            &nbsp;&nbsp;
                                                            <TextField
                                                                style={ {fontSize: '16px', width: '70%'} }
                                                                label={'Payload 3'}
                                                                value={this.state.buttons?.[2]?.payload || ''}
                                                                onChange={(e) => this.handleButtonChange(e, 2, 'payload')}
                                                            />
                                                            <br/>
                                                            <TextField
                                                                className={ classes.textFieldButtonStyle}
                                                                InputProps={{
                                                                    disableUnderline: true,
                                                                    inputProps: {
                                                                        style: { textAlign: "center" , color: "white"},
                                                                    }
                                                                }}
                                                                //label={'Answer 4'}
                                                                value={this.state.buttons?.[3]?.title || ''}
                                                                onChange={(e) => this.handleButtonChange(e, 3, 'title')}
                                                            />
                                                            &nbsp;&nbsp;
                                                            <TextField
                                                                style={ {fontSize: '16px', width: '70%'} }
                                                                label={'Payload 4'}
                                                                value={this.state.buttons?.[3]?.payload || ''}
                                                                onChange={(e) => this.handleButtonChange(e, 3, 'payload')}
                                                            />
                                                            <br/>
                                                            <TextField
                                                                className={ classes.textFieldButtonStyle}
                                                                InputProps={{
                                                                    disableUnderline: true,
                                                                    inputProps: {
                                                                        style: { textAlign: "center" , color: "white"},
                                                                    }
                                                                }}
                                                                //label={'Answer 5'}
                                                                value={this.state.buttons?.[4]?.title || ''}
                                                                onChange={(e) => this.handleButtonChange(e, 4, 'title')}
                                                            />
                                                            &nbsp;&nbsp;
                                                            <TextField
                                                                style={ {fontSize: '16px', width: '70%'} }
                                                                label={'Payload 5'}
                                                                value={this.state.buttons?.[4]?.payload || ''}
                                                                onChange={(e) => this.handleButtonChange(e, 4, 'payload')}
                                                            />
                                                        </div>
                                                    :
                                                        'Cannot add buttons'
                                                }
                                            </AccordionDetails>
                                        </Accordion>
                                    }
                                    {this.props.showButtonFeature &&
                                        <Accordion style={{marginTop:30}}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-label="About-Expand"
                                                aria-controls="About-content"
                                                id="About-header"
                                                >
                                                <FormControlLabel
                                                    aria-label="Advanced"
                                                    control={<InfoIcon style={theme.cardSettings.iconDesign} />}
                                                    label="Advanced Features"
                                                />
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                {this.props.content !== undefined && this.props.content.template !== undefined && this.props.content.template.buttons !== undefined
                                                    ?
                                                        <div>
                                                            Remove Buttons &nbsp;
                                                            <Button onClick={() => {this.addButtons('delete')}}>
                                                                Remove
                                                            </Button>
                                                        </div>
                                                    :
                                                        <div>
                                                            Add Buttons &nbsp;
                                                            {this.props.content.agent?.includes('reflection')
                                                                ?
                                                                    <Button onClick={() => {this.addButtons('satisfaction_slot')}}>
                                                                        Satisfaction Slot
                                                                    </Button>
                                                                :
                                                                    <Button onClick={() => {this.addButtons('energy_slot')}}>
                                                                        Energy Slot
                                                                    </Button>
                                                            }
                                                        </div>
                                                }
                                                {this.props.content !== undefined && this.props.content.template !== undefined && this.props.content.template.attachment !== undefined && this.props.content.template.attachment.event !== undefined
                                                    ?
                                                        <div>
                                                            Remove Event &nbsp;
                                                            <Button onClick={() => {this.changeEvent('delete')}}>
                                                                Remove "{this.props.content.template.attachment.event}"
                                                            </Button>
                                                        </div>
                                                    :
                                                        <div>
                                                            Add Event &nbsp;
                                                            <Button onClick={() => {this.changeEvent('dialogue_end')}}>
                                                                dialogue_end
                                                            </Button>
                                                        </div>
                                                }
                                            </AccordionDetails>
                                        </Accordion>
                                    }
                                </DialogContent>
                            </form>
                            <DialogActions>
                                <Button onClick={this.handleSettingClose}>
                                    Cancel
                                </Button>
                                <Button onClick={this.handleSettingSubmit}>
                                    Save
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                : 
                    <div> </div>       
        );
    }

    changeEvent = (type) => {
        if(type==="dialogue_end"){
            this.setState({
                attachment_event: "dialogue_end"
            });
        }
        else if(type==="delete"){
            this.setState({
                attachment_event: null
            });
        }
    }

    handleButtonChange(event, index=0, type='title'){
        var value = event.target.value;
        var currentButtons = this.state.buttons || [];
        if(currentButtons.length >= index+1){
            if(type==='title'){
                if(value === '' && currentButtons[index]['payload'] === ''){
                    currentButtons.splice(index, 1);
                }
                else{
                    currentButtons[index]['title'] = value;
                    if(value.length>18){
                        currentButtons[index]['title'] = value.substring(0,16) + "...";
                    }
                    if(currentButtons[index]['payload'] === undefined || currentButtons[index]['payload'] === '' || value.startsWith(currentButtons[index]['payload'])){
                        currentButtons[index]['payload'] = value;
                    }
                }
            }
            if(type==='payload'){
                if(value === '' && currentButtons[index]['title'] === ''){
                    currentButtons.splice(index, 1);
                }
                else{
                    currentButtons[index]['payload'] = value;
                    //// DO NOT OVERRIDE the button text anymore
                    if(currentButtons[index]['title'] === undefined || currentButtons[index]['title'] === '' || value.startsWith(currentButtons[index]['title'])){
                        //currentButtons[index]['title'] = value;
                    }
                }
            }
        }
        else{
            currentButtons.push({title: value, payload: value});
        }
        //console.log('new changed arr', currentButtons)
        this.setState({buttons: currentButtons});
    }

    addButtons = (type) => {
        if(type==="satisfaction_slot"){
            const buttonArray = [
                {payload: '/inform_measurement{"satisfaction_slot":1}', title: 1},
                {payload: '/inform_measurement{"satisfaction_slot":2}', title: 2},
                {payload: '/inform_measurement{"satisfaction_slot":3}', title: 3},
                {payload: '/inform_measurement{"satisfaction_slot":4}', title: 4},
                {payload: '/inform_measurement{"satisfaction_slot":5}', title: 5},
            ]
            this.setState({
                buttons: buttonArray
            });
        }
        else if(type==="energy_slot"){
            const buttonArray2 = [
                {payload: '/inform_measurement{"energy_slot":1}', title: 1},
                {payload: '/inform_measurement{"energy_slot":2}', title: 2},
                {payload: '/inform_measurement{"energy_slot":3}', title: 3},
                {payload: '/inform_measurement{"energy_slot":4}', title: 4},
                {payload: '/inform_measurement{"energy_slot":5}', title: 5},
            ]
            this.setState({
                buttons: buttonArray2
            });
        }
        else if(type==="delete"){
            this.setState({
                buttons: null
            });
        }
    }
}

SettingContentSpecialUtteranceText.propTypes = {
    classes: PropTypes.object.isRequired
};

export default compose(
    withStyles(styles, { withTheme: true }),
    withFirebase
)(SettingContentSpecialUtteranceText);
